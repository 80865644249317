import { LocalContentState } from '../interfaces/LocalContentState';

export const maxContentItemDroppableId = (strArray: number[]) => {
  return Math.max(...strArray) + '';
};

export const isAllRead = (array: LocalContentState[], uid: string) => {
  let flag = true;
  for (let i = 0; i < array.length - 1; i++) {
    if (flag && !array[i].chapterReadCheckUids?.includes(uid)) {
      flag = false;
    }
  }

  return flag;
};

export const getStudentContentCompletedPercentage = (array: LocalContentState[], uid: string) => {
  let counter = 1;
  for (let i = 0; i < array.length; i++) {
    if (array[i].chapterReadCheckUids?.includes(uid)) {
      counter++;
    }
  }

  if (array.length) {
    return (counter / array.length) * 100;
  } else {
    return 0;
  }
};

export const findLargestInteger = (array: number[]) => {
  let largest = array[0]; // Assume the first element is the largest

  for (let i = 1; i < array.length; i++) {
    if (array[i] > largest) {
      largest = array[i]; // Update largest if a larger element is found
    }
  }

  return largest;
};

export const getTheNextDroppableId = (cArray: LocalContentState[]) => {
  const dIds = cArray.map((val) => parseInt(val.droppableId));

  if (dIds.length) {
    return findLargestInteger(dIds) + 1 + '';
  } else {
    return '0';
  }
};
