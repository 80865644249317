import React, { FC } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import RoutingPaths from '../../routes/RoutingPaths';
import AddContentCreateScreen from './AddContentCreateScreen';
import ContentMainPreview from './ContentMainPreview';
import ChapterPreview from './ChapterPreview';
import ContentTopicListScreen from './ContentTopicListScreen';
import UpdateContentScreen from './UpdateContentScreen';
import AdminLessonExaminationsScreen from '../../../../screens/admin/AdminLessonExaminationsScreen';

interface Props {
  contentId: string;
  setContentIdHandler: (data: string) => void;
  setLoadingHandler?: (value: boolean) => void;
}

const ContentBody: FC<Props> = (props) => {
  const params = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
    id: string;
  };

  const PATHS = new RoutingPaths(params.subjectId, params.lessonId, params.unitId);

  return (
    <Switch>
      <Route
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Content/Preview/:id',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/Preview/:id',
        ]}
      >
        <ChapterPreview droppableId={params.id} />
      </Route>
      <Route
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Content/Update/:droppableId',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/Update/:droppableId',
        ]}
      >
        <UpdateContentScreen
          subjectId={params.subjectId}
          lessonId={params.lessonId}
          unitId={params.unitId}
          contentId={props.contentId}
          droppableId={params.id}
          setContentIdHandler={props.setContentIdHandler}
        />
      </Route>
      <Route
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/Create',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Content/Create',
        ]}
      >
        <AddContentCreateScreen
          subjectId={params.subjectId}
          lessonId={params.lessonId}
          unitId={params.unitId}
          contentId={props.contentId}
        />
      </Route>

      <Route
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Content/MainPreview',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/MainPreview',
        ]}
      >
        <ContentMainPreview />
      </Route>
      <Route path={`${PATHS.baseURL}/Examinations`}>
        {/* <UnderConstruction /> */}
        <AdminLessonExaminationsScreen
          subjectId={params.subjectId}
          lessonId={params.lessonId}
          noUnitsPresent={true}
          setLoadingHandler={props.setLoadingHandler}
        />
        {/* <AdminLessonModalPapersScreen /> */}
      </Route>
      <Route path={PATHS.baseURL}>
        <ContentTopicListScreen
          subjectId={params.subjectId}
          lessonId={params.lessonId}
          unitId={params.unitId}
          setContentIdHandler={props.setContentIdHandler}
          contentId={props.contentId}
          droppableId={params.id}
        />
      </Route>
    </Switch>
  );
};

export default ContentBody;
