import React, { FC, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { FaFilePdf, FaLink } from 'react-icons/fa';
import { useAppContent } from '../../context/ContentContext';
import { Item, Links, VideoItem } from '../../interfaces/LocalContentState';
import { CONTENT_MEDIA_TYPE } from '../../utils';
import renderHTML from 'react-render-html';
import './ContentMainPreview.scss';
import truncate from 'truncate';
import VideoPlayer from '../../../../components/VideoPlayer';

const ContentMainPreview: FC = () => {
  const appContent = useAppContent();

  const CONTENTS = appContent.contentData.find(
    (data) => data.droppableId === appContent.mainDroppableId
  )?.contentItems;

  // const check = appContent.contentData.find(
  //   (data) => data.droppableId === appContent.mainDroppableId
  // );

  const getMaterialNameFromUrl = (url: string) => {
    return truncate(url.substring(url.indexOf('mns') + 3, url.indexOf('mne')), 20);
  };

  // const idxOfCurrent = appContent.contentData
  //   .map((v) => v.droppableId)
  //   .indexOf(appContent.mainDroppableId);

  // const maxIndex = appContent.contentData.length - 1;

  useEffect(() => {
    // TODO set inPreviewChapterReadCheck
  }, []);

  useEffect(() => {
    const idxOfCurrent = appContent.contentData
      .map((v) => v.droppableId)
      .indexOf(appContent.mainDroppableId);

    const maxIndex = appContent.contentData.length - 1 + '';

    if (parseInt(maxIndex) < idxOfCurrent + 1) {
      appContent.setIsLastChapter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContent.mainDroppableId]);
  // console.log('max indc a', maxIndex);
  // console.log('idxOfCurrent ', idxOfCurrent);

  const isAtLastChapter = () => {
    const idxOfCurrent = appContent.contentData
      .map((v) => v.droppableId)
      .indexOf(appContent.mainDroppableId);
    const maxIndex = appContent.contentData.length - 1;
    return maxIndex === idxOfCurrent;
  };

  const previewIsCompleteButtonNotActive = () => {
    let counter = 0;
    for (let i = 0; i < appContent.contentData.length; i++) {
      if (appContent.contentData[i].inPreviewChapterReadCheck) {
        counter++;
      }
    }

    return !(appContent.contentData.length - 1 === counter) && isAtLastChapter();
  };

  const isAllChaptersRead = () => {
    let counter = 0;
    for (let i = 0; i < appContent.contentData.length; i++) {
      if (appContent.contentData[i].inPreviewChapterReadCheck) {
        counter++;
      }
    }

    return appContent.contentData.length + 1 === counter;
  };

  const getCurrentChapter = () => {
    return appContent.contentData.find((data) => data.droppableId === appContent.mainDroppableId);
  };

  const previewShowCompleteButton = () => {
    return getCurrentChapter()?.inPreviewChapterReadCheck && isAtLastChapter();
  };

  const getMaxIndexOfChapters = () => {
    return appContent.contentData.length - 1;
  };

  const getIndexOfCurrentCurrentChapter = () => {
    return appContent.contentData.map((v) => v.droppableId).indexOf(appContent.mainDroppableId);
  };

  const getNextDroppableId = () => {
    const idxOfCurrent = getIndexOfCurrentCurrentChapter();
    return appContent.contentData[idxOfCurrent + 1].droppableId;
  };

  const isNextChapterAvailable = () => {
    const maxIndex = getMaxIndexOfChapters();
    const idxOfCurrent = getIndexOfCurrentCurrentChapter();
    return maxIndex >= idxOfCurrent + 1;
  };

  const moveToNextChapter = () => {
    if (!isAllChaptersRead()) {
      appContent.inPreviewChapterReadCheckHandler(appContent.mainDroppableId);
      if (isNextChapterAvailable()) {
        appContent.setMainDroppableId(getNextDroppableId());
      }
    }
  };

  return (
    <Container className="content-main-preview" fluid>
      <section className="content-main-preview__main">
        {CONTENTS?.map((val, idx) => {
          if (val.mediaType === CONTENT_MEDIA_TYPE.LINKS) {
            const item = val as {
              links: Links;
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };

            return item.links.linkGroups.map((v, index) => {
              return (
                <a
                  className={`d-flex mb-1 content-main-preview__links ${
                    item.links.linkGroups.length - 1 === index ? 'mb-3' : ''
                  }`}
                  href={v.url.value}
                  target="_blank"
                  rel="noreferrer"
                  key={idx + '' + index}
                >
                  <FaLink style={{ color: '#979797' }} className="mr-2" /> {v.ref.value}
                </a>
              );
            });
          } else if (val.mediaType === CONTENT_MEDIA_TYPE.MATERIALS) {
            const item = val as {
              materialUrls: Item[];
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };

            const i = item.materialUrls.map((val, index) => {
              return (
                <div
                  className="d-flex mb-3 align-items-center"
                  style={{ marginRight: '2rem' }}
                  key={idx + '' + index}
                >
                  {/* <FaFileAlt className="mr-2 " size={40} /> */}
                  <FileIcon fileName={''} url={val.value} />
                  <div className="content-main-preview__filename">
                    {getMaterialNameFromUrl(decodeURI(val.value))}
                  </div>
                </div>
              );
            });

            return (
              <section className="pb-4 " style={{ marginTop: '4rem' }} key={idx}>
                <h4 className="content-main-preview-title__tertiary">Attachments:</h4>
                <div
                  className="d-flex"
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                  }}
                >
                  {i}
                </div>
              </section>
            );
          } else if (val.mediaType === CONTENT_MEDIA_TYPE.TEXT) {
            const item = val as {
              textContent: Item;
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };
            return (
              <section className="mb-3 content-text" key={idx}>
                {renderHTML(item.textContent.value || '')}
              </section>
            );
          } else if (val.mediaType === CONTENT_MEDIA_TYPE.VIDEO) {
            const item = val as {
              videoValue: VideoItem;
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };

            return (
              <div className="mb-3 content-main-preview__m-0" key={idx}>
                <VideoPlayer fileNameInUrl={item.videoValue.value} />
              </div>
            );
          } else if (val.mediaType === CONTENT_MEDIA_TYPE.QUIZ) {
            return <></>;
          } else {
            return <></>;
          }
        })}
      </section>

      <footer className="text-right">
        <button
          className={`content-main-preview__next-btn ${previewShowCompleteButton() && 'd-none'} ${
            previewIsCompleteButtonNotActive() && 'content-main-preview__next-btn--disabled'
          }`}
          onClick={() => {
            !previewIsCompleteButtonNotActive() && moveToNextChapter();
          }}
        >
          {isAtLastChapter() ? 'COMPLETE' : 'NEXT'}
        </button>
      </footer>
    </Container>
  );
};

export default ContentMainPreview;

const FileIcon = (props: { fileName: string; url: string }) => {
  return (
    <div
      className="fi-wrapper"
      onClick={() => {
        const link = document.createElement('a');
        link.href = props.url;
        link.setAttribute('download', props.fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      }}
    >
      <div className="file-icon">
        <div style={{ width: '2.9rem', height: '2.9rem' }}>
          <FaFilePdf style={{ fontSize: '1.5rem' }} className="micon" />
        </div>
      </div>
      <div className="fifn" style={{ wordBreak: 'break-all' }}>
        {props.fileName}
      </div>
    </div>
  );
};
