import React, { FC, useEffect, useState } from 'react';
import { FaWindowClose } from 'react-icons/fa';
import Modal from 'react-modal';
import { UserFirestore } from '../models/UserFirestore';
import './AddStudentsModal.scss';
import ButtonComp from './ButtonComp';
import { addStudentsInitialState } from '../interfaces';
import { useFunctions } from 'reactfire';
import Notification from './../components/Notification';
import LoadingIndicator from './LoadingIndicator';
import UsersDropDownMultiple from './UsersDropDownMultiple';
import { Col, Row } from 'react-bootstrap';
import PaginationNew from './PaginationNew';

interface Props {
  show: boolean;
  users: UserFirestore[];
  subjectId: string;
  addStudentsModalHandler?: (value: boolean) => void;
  onAddNewUser: (ids: string[]) => void;
}

const initialPagination = {
  total_pages: 0,
  page_number: 0,
};

const ITEM_COUNT_IN_PAGE: number = 8;

const AddStudentsModal: FC<Props> = (props) => {
  const [state, setState] = useState(addStudentsInitialState);
  const [users, setUsers] = useState<{ displayValue: string; id: string }[]>([]);
  const [pagination, setPagination] = useState(initialPagination);

  const enrollStudentsToSubjectManuallyRef = useFunctions().httpsCallable(
    'enrollStudentsToSubjectManually'
  );

  useEffect(() => {
    const filterUsers = props.users.map((user: UserFirestore) => {
      return { displayValue: user.username, id: user.uid };
    });

    setState((ps) => ({
      ...ps,
      ...addStudentsInitialState,

      user: { ...addStudentsInitialState.user, value: [] },
    }));

    setUsers(filterUsers);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.users]);

  const addStudentHandler = async () => {
    setState((pS) => ({
      ...pS,
      loading: true,
    }));
    const studentIds = state.user.value.map((v) => v.id);

    enrollStudentsToSubjectManuallyRef({
      subjectId: props.subjectId,
      studentIds: studentIds,
    })
      .then(() => {
        props.onAddNewUser && props.onAddNewUser(studentIds);
        Notification({
          isSuccess: true,
          message: `Assigned to the subject`,
          id: 'tid',
        });
      })
      .catch(() => {
        Notification({
          isSuccess: false,
          message: `Failed to Assigned  to the subject`,
          id: 'tid',
        });
      })
      .finally(() => {
        setState((pS) => ({
          ...pS,
          loading: false,
        }));

        setState(addStudentsInitialState);
        props.addStudentsModalHandler && props.addStudentsModalHandler(false);
      });
  };

  const paginate = (array: any[], page_number: number, page_size: number): any[] =>
    array.slice(page_number * page_size, (page_number + 1) * page_size);

  const paginationHandler = () => {
    if (state.user.value.length % ITEM_COUNT_IN_PAGE === 0) {
      const totalPages = state.user.value.length / ITEM_COUNT_IN_PAGE;
      setPagination((ps) => ({ ...ps, total_pages: totalPages }));
    } else {
      const totalPages = Math.floor(state.user.value.length / ITEM_COUNT_IN_PAGE) + 1;
      setPagination((ps) => ({ ...ps, total_pages: totalPages }));
    }
  };

  useEffect(() => {
    paginationHandler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user.value]);

  return (
    <>
      <Modal
        isOpen={props.show}
        contentLabel="Example Modal"
        className={'add-students-modal'}
        overlayClassName="r-overlay"
        ariaHideApp={false}
        closeTimeoutMS={500}
      >
        <div className="add-new-students-modal-header">
          Add Students
          <FaWindowClose
            color="#FF4C6C"
            size={20}
            style={{
              cursor: 'pointer',
            }}
            onClick={() => {
              setState(addStudentsInitialState);
              props.addStudentsModalHandler && props.addStudentsModalHandler(false);
            }}
          />
        </div>

        {state.loading && <LoadingIndicator />}
        <div>
          <UsersDropDownMultiple
            name={'Select Students'}
            noValueText={'Select Students'}
            stateName="user"
            stateValue={state.user.value}
            state={state}
            optionsArray={users}
            setState={setState}
            error={state.user.error}
            users={props.users}
          />
        </div>

        <Row>
          <Col className="mt-4 mb-2">Selected Students</Col>
        </Row>

        <div className="custom-selected-students-list">
          {state.user.value.length > 0 &&
            paginate(state.user.value, pagination.page_number, ITEM_COUNT_IN_PAGE).map((user) => {
              const selectedStudent = props.users.find((u) => u.uid === user.id);
              return (
                <Row className="mt-2">
                  <Col>
                    <div className="selected-students-card py-2 px-3">
                      <Row>
                        <Col className="selected-student-name">
                          {selectedStudent?.firstName} {selectedStudent?.lastName}
                        </Col>
                        <Col className="selected-student-email">{selectedStudent?.email}</Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              );
            })}
        </div>
        <div className="my-1">
          <PaginationNew
            pages={pagination.total_pages}
            setCurrentPage={(pageNumber) => {
              setPagination({ ...pagination, page_number: pageNumber - 1 });
            }}
            clear={pagination.total_pages}
          />
        </div>

        <div className="add-new-students-modal-confirm-button mt-4">
          <ButtonComp
            className={`${state.loading ? `add-new-students-modal-button-disabled` : ''}`}
            type={'one'}
            text={'Confirm'}
            onClick={() => {
              if (!state.loading) {
                addStudentHandler();
              }
            }}
            disabled={state.user.value.length === 0 || state.loading}
          />

          <ButtonComp
            className={`${state.loading ? `add-new-students-modal-button-disabled` : ''}`}
            type={'one'}
            text={'Cancel'}
            onClick={() => {
              if (!state.loading) {
                setState(addStudentsInitialState);

                props.addStudentsModalHandler && props.addStudentsModalHandler(false);
              }
            }}
            style={{ background: 'transparent', gap: '20px' }}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddStudentsModal;
