import React from 'react';
import PrimaryNav from '../../../../components/PrimaryNav';
import { SCREEN } from '../../../../constants';
import LayoutNew from '../../../../layouts/LayoutNew';
import StudentContentBody from '../../components/StudentContentBody';
import StudentContentHeader from '../../components/StudentContentHeader';
import StudentContentSecondaryNav from '../../components/StudentContentSecondaryNav';
import { ContentProvider } from '../../context/ContentContext';

const StudentContentScreen = () => {
  const primaryNav = <PrimaryNav />;
  const secondaryNav = <StudentContentSecondaryNav />;
  const header = <StudentContentHeader />;
  const body = <StudentContentBody />;

  function handleContextMenu(event: any) {
    event.preventDefault();
  }

  return (
    <ContentProvider>
      <div onContextMenu={handleContextMenu}>
        <LayoutNew
          primaryNav={primaryNav}
          secondaryNav={secondaryNav}
          header={header}
          body={body}
          screen={SCREEN.CONTENT_PREVIEW}
        />
      </div>
    </ContentProvider>
  );
};

export default StudentContentScreen;
