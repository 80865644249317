import React, { FC, useCallback } from 'react';
import { FaBookOpen, FaCog, FaFileContract, FaUserAlt } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router';

import BookIcon from './../assets/hat.png';

import './PrimaryNav.scss';
import { AiFillNotification } from 'react-icons/ai';
import { useAnnouncements } from '../context/AnnouncementContext';
import { useAppUser } from '../context/UserContext';
import SideNavItem from './PrimaryNavItem';

interface Props {
  className?: string;
  restrictions?: { value: boolean; onClick: Function };
}

const PrimaryNav: FC<Props> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const a = useAnnouncements();
  const account = <FaUserAlt size={16} color="#fff" />;
  const resources = <FaFileContract size={16} color="#fff" />;
  const subjects = <FaBookOpen size={16} color="#fff" />;
  const announcement = <AiFillNotification size={16} color="#fff" />;
  // const notices = <FaBullhorn size={16} color="#fff" />;
  const settings = <FaCog size={16} color="#fff" />;
  // const forum = <FaCommentAlt size={16} color="#fff" />;

  const appUser = useAppUser();

  let navItemsArray = [
    { icon: account, name: 'Account', path: '/account' },
    {
      icon: subjects,
      name: 'Subjects',
      path: '/my-subjects',
    },
    {
      icon: announcement,
      name: 'Announcements',
      path: '/announcements',
      label: a.announcements.filter((a) => !a.read && a.isActive).length + '',
    },
    // {icon: forum, name: 'Forum', path: '/forum'},
  ];

  if (appUser.firestoreUser?.userRole?.isAdmin) {
    navItemsArray = [
      { icon: account, name: 'Account', path: '/account' },
      {
        icon: subjects,
        name: 'Subjects',
        path: '/my-subjects',
      },
      { icon: resources, name: 'Resources', path: '/resource' },

      // {icon: notices, name: 'Notices', path: '/announcements'},
      { icon: settings, name: 'Settings', path: '/settings' },
      { icon: announcement, name: 'Announcements', path: '/announcements' },
      // {icon: forum, name: 'Forum', path: '/forum'},
    ];
  }

  if (appUser.firestoreUser?.userRole?.isInstructor) {
    navItemsArray = [
      { icon: account, name: 'Account', path: '/account' },
      {
        icon: subjects,
        name: 'Subjects',
        path: '/my-subjects',
      },
      { icon: resources, name: 'Resources', path: '/resource' },
      { icon: announcement, name: 'Announcements', path: '/announcements' },

      // {icon: notices, name: 'Notices', path: '/announcements'},
      // {icon: forum, name: 'Forum', path: '/forum'},
    ];
  }

  const isActive = useCallback(
    (item: any) => {
      if (location.pathname.split('/')[1] === 'announcements') {
        if (location.pathname.split('/').length < 5) {
          return item.path === '/' + location.pathname.split('/')[1];
        }
        return false;
      } else {
        return item.path === '/' + location.pathname.split('/')[1];
      }
    },
    [location.pathname]
  );
  return (
    <div className="primary-nav d-none d-md-flex justify-content-between">
      <img
        src={BookIcon}
        width="48px"
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
        alt="sb-icon"
      />
      {navItemsArray.map((item, idx) => {
        return (
          <SideNavItem
            icon={item.icon}
            name={item.name}
            label={item.label}
            active={isActive(item)}
            navigationHandler={() => {
              if (!props.restrictions || !props.restrictions.value) {
                history.push(item.path);
              } else {
                props.restrictions.onClick(item.path);
              }
            }}
            key={idx}
            className={
              appUser.firestoreUser?.userRole?.isStudent ||
              appUser.firestoreUser?.userRole?.isTeachingAssistant
                ? 'mt-5'
                : ''
            }
          />
        );
      })}
    </div>
  );
};

export default PrimaryNav;
