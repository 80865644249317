import React, { FC, useState } from 'react';
import { useFunctions } from 'reactfire';
import { CONTENT_MEDIA_TYPE } from '../utils';
import { BiMove } from 'react-icons/bi';
import { RiDeleteBinFill } from 'react-icons/ri';
import './VideoContent.scss';
import { useAppContent } from '../context/ContentContext';
import { VideoItem } from '../interfaces/LocalContentState';
import truncate from 'truncate';
import { FaUpload } from 'react-icons/fa';
import LoadingIndicator from '../../../components/LoadingIndicator';
import Notification from '../../../components/Notification';
import CustomProgressBar from '../../../components/CustomProgressBar';

interface Props {
  contentItemDroppableId: string;
  droppableId: string;
}

const VideoContent: FC<Props> = (props) => {
  const appContent = useAppContent();

  const contentItems = appContent.contentData.find(
    (val) => val.droppableId === props.droppableId
  )?.contentItems;

  const videoContent = contentItems?.find(
    (val) => val.contentItemDroppableId === props.contentItemDroppableId
  ) as {
    videoValue: VideoItem;
    mediaType: CONTENT_MEDIA_TYPE;
    contentItemDroppableId: string;
    component: any;
  };

  const onUpdateItem = (awsSavedVideoName: string, originalVideoName: string) => {
    videoContent.videoValue.value = awsSavedVideoName;
    videoContent.videoValue.videoName = originalVideoName;

    const idx = contentItems
      ?.map((v) => v.contentItemDroppableId)
      ?.indexOf(videoContent.contentItemDroppableId);

    const stateCopy = [...contentItems!];

    if (idx) {
      stateCopy[idx] = videoContent;
    }

    appContent.setState && appContent.setState((ps: any) => ({ ...ps, contentItems: stateCopy }));
  };

  const isValid = true;

  const [uploadElement, setUploadElement] = useState(null as any);
  const [fileName, setFilename] = useState('');
  const [selectedFile, setSelectedFile] = useState<File>();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const getPresignedUrlRef = useFunctions().httpsCallable('getPresignedUrl');

  const uploadFileToPresignedURLWithXMLHttpRequest = async () => {
    if (selectedFile) {
      appContent.videoContentIsUploadingHandler(true);
      setIsFileUploading(true);

      const generatedVideoName = +new Date() + selectedFile.name.split(' ').join('');
      getPresignedUrlRef(generatedVideoName)
        .then(async (res) => {
          const presignedUrl = res.data;

          // Upload the file using the presigned URL
          const xhr = new XMLHttpRequest();

          xhr.open('PUT', presignedUrl, true);
          xhr.setRequestHeader('Content-Type', 'multipart/form-data');
          xhr.upload.onprogress = (event) => {
            appContent.videoContentIsUploadingHandler(true);
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            setUploadProgress(percentCompleted);
          };
          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 403) {
              console.log('Upload permission denied');
            }
          };
          xhr.onerror = () => {
            console.log('Upload failed');
            appContent.videoContentIsUploadingHandler(false);
          };
          xhr.onabort = () => {
            console.log('Upload aborted');
            appContent.videoContentIsUploadingHandler(false);
          };
          xhr.ontimeout = () => {
            console.log('Upload timed out');
            appContent.videoContentIsUploadingHandler(false);
          };
          xhr.onload = () => {
            console.log('Upload complete');
            onUpdateItem(generatedVideoName, selectedFile.name);
            appContent.videoContentIsUploadingHandler(false);
            setUploadProgress(0);
            Notification({
              isSuccess: true,
              message: 'File Successfully Saved!',
            });
          };
          xhr.send(selectedFile);
        })
        .catch(() => {
          appContent.videoContentIsUploadingHandler(false);
        })
        .finally(() => {
          setIsFileUploading(false);
          setUploadProgress(0);
          setSelectedFile(undefined);
        });
    }
  };

  return (
    <div className="content-card-container mt-4">
      <div className="c-icon">
        <BiMove style={{ cursor: 'move' }} />
      </div>
      <div className="c-right">
        <div className="c-title-contaner">
          <div className="c-title">Upload Video</div>
          <div
            className="c-delete"
            onClick={() => {
              if (uploadProgress <= 0) {
                appContent.removeNewVideoSlotHandler(
                  props.droppableId,
                  props.contentItemDroppableId
                );
              }
            }}
            style={uploadProgress <= 0 ? {} : { cursor: 'not-allowed' }}
          >
            <RiDeleteBinFill />
          </div>
        </div>
        <div className="c-video" style={{ width: '90%', marginTop: '-1rem' }}>
          <div className="cv-top">
            <div className="cv-filename" style={{ marginTop: '1rem' }}>
              {truncate(fileName || videoContent.videoValue?.videoName || 'Open a video file', 30)}
            </div>
            <div
              className="cv-button"
              style={{
                display: 'flex',
              }}
            >
              <button
                style={
                  uploadProgress <= 0
                    ? {
                        marginRight: '10px',
                        marginLeft: '10px',
                        width: '50px',
                        padding: '0.5rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                    : {
                        marginRight: '10px',
                        marginLeft: '10px',
                        cursor: 'not-allowed',
                        width: '50px',
                        padding: '0.5rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }
                }
                onClick={() => {
                  if (uploadProgress <= 0) {
                    uploadElement.click();
                  }
                }}
                onFocus={() => {
                  appContent.resetVideoContentErrorHandler(
                    props.droppableId,
                    props.contentItemDroppableId
                  );
                }}
              >
                <FaUpload />
              </button>
              {selectedFile && (
                <button
                  onClick={() => {
                    if (uploadProgress <= 0) {
                      // uploadFile();
                      // uploadFileToPresignedURL();
                      // uploadFileToPresignedURLandShowUploadedPercentage();
                      uploadFileToPresignedURLWithXMLHttpRequest();
                      appContent.resetVideoContentErrorHandler(
                        props.droppableId,
                        props.contentItemDroppableId
                      );
                    }
                  }}
                  style={uploadProgress <= 0 ? {} : { cursor: 'not-allowed' }}
                >
                  Upload Video
                </button>
              )}

              <input
                style={{ display: 'none' }}
                type="file"
                ref={(input) => setUploadElement(input)}
                accept=".webm, .rm, .qt, .mxf, .mpeg, .mp4, .mov, .flv, .f4v, .asf, .wmv, .mkv"
                className="d-none"
                onChange={(e) => {
                  const file: any = e.target.files ? e.target.files[0] : null;
                  const fileType = file?.name.split('.').pop().toLowerCase();
                  const exList = 'webm,rm,qt,mxf,mpeg,mp4,mov,flv,f4v,asf,wmv,mkv'.split(',');
                  let b1 = file && exList.filter((ext) => ext === fileType).length > 0;
                  let b2 = file && file.size <= 105255200 * 20;
                  // let errorMessage = 'No file was selected';
                  // if (!b1) errorMessage = 'Invalid Video format';
                  // if (!b2) errorMessage = ' Invalid Video size';
                  // if (!b1 && !b2) errorMessage = 'Invalid Video format and size';

                  if (b1 && b2) {
                    if (file) {
                      setFilename(file?.name);
                      // onUpdateItem('','');
                      setSelectedFile(file);

                      //  props.onFileSelected && props.onFileSelected(file);
                      //  setResourceFileName(props.resource, file?.name);
                      //  props.updateCurrentAction(true, false, false);
                    }
                  } else {
                    //  UserNotification({
                    //    isSuccess: false,
                    //    message: errorMessage,
                    //  });
                  }
                }}
              />
            </div>
          </div>
          <div className="cv-progress">
            {!isValid && <label className="error-message">invalid data</label>}

            {uploadProgress > 0 && (
              <div
                className="d-flex mt-2"
                style={{
                  display: 'flex',

                  alignItems: 'center',
                }}
              >
                <div className="mr-2" style={{ color: 'rgb(32, 209, 103)' }}>
                  {uploadProgress}%
                </div>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <CustomProgressBar progress={uploadProgress} width={100} color="green" />
                </div>
              </div>
            )}

            {isFileUploading ? <LoadingIndicator /> : <></>}

            <div className="text-right pr-2 mt-1" style={{ fontSize: 11, color: '#dc3545' }}>
              {videoContent.videoValue.error}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoContent;
