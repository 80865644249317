import React, { FC } from 'react';
import truncate from 'truncate';
import './UserDropDownCard.scss';
import { UserFirestore } from '../models/UserFirestore';

interface Props {
  className: string;
  inputChangeHandler: (
    state: any,
    name: string,
    value: {
      displayValue: string;
      id: string;
    }
  ) => void;
  setState: (value: React.SetStateAction<boolean>) => void;
  id: string;
  state: any;
  stateName: string;
  noValueText: string;
  truncateNumber?: number;
  selected: boolean;
  user?: UserFirestore;
}

const UserDropDownCard: FC<Props> = (props) => {
  return (
    <div
      className={`students-dropdown-card ${props.className}`}
      onClick={(e) => {
        props.inputChangeHandler(props.state, props.stateName, {
          displayValue: props.user?.email || '',
          id: props.user?.uid || '',
        });

        props.setState(true);
        e.stopPropagation();
      }}
      id={props.id}
    >
      <div className="students-dropdown-card__right">
        <div className="students-dropdown-card__right-title">
          {props.user && props.user?.firstName} {props.user && props.user?.lastName}
          <div className="ml-1 student-email">
            {' | '}
            {props.user && truncate(props.user?.email || '', props.truncateNumber || 40)}
          </div>
        </div>
      </div>

      <div className="pretty p-default p-curve p-fill  ml-auto">
        <input
          type="checkbox"
          onChange={() => {
            // props.changeCorrectAnswerHandler && props.changeCorrectAnswerHandler(idx);
          }}
          checked={props.selected}
        />
        <div className="state p-primary correct-answer" style={{ marginTop: 4 }}>
          <label style={{ fontSize: 16 }}></label>
        </div>
      </div>
    </div>
  );
};

export default UserDropDownCard;
