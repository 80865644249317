import { ExamToDoDTO } from '../dtos/ExamToDoDTO';
import { PaperQuestionDTO } from '../dtos/PaperQuestionDTO';
import { EXAM_TYPE_TABS, PACKAGE_TYPE_TABS } from '../enums';
import { ExamType } from '../enums/ExamType';
import { PAPER_CREATION_MODE } from '../enums/PaperCreationMode';
import { PaperStatus } from '../enums/PaperStatus';
import { PaperTypes } from '../enums/paperTypes';
import { Exam } from '../models/Exam';
import { MCQPaperQuestion, TFPaperQuestion } from '../models/Question';
import {
  isContainOnlyNumbers,
  validateDescriptionStringSpaces,
  validateMaxLength,
  validateMinLength,
  validateOnlyUnderscoreAndHypenAllowed,
  validateString,
  validateStringSpaces,
  validateSubjectNameAndLesson,
  validateUnitDescriptionWordCount,
} from '../validation/Validation';

export interface EXAM_CREATION_STATE {
  id: string;
  createdAt: Date;
  createdBy: string;

  isLoading: {
    value: boolean;
  };

  grade: {
    value: DropDown;
    error: string;
    validations: Function[];
    label: 'Grade';
    type: 'dropdown';
  };
  subject: {
    value: DropDown;
    error: string;
    validations: any[];
    label: string;
    type: string;
  };
  lesson: {
    value: { displayValue: string; id: string };
    error: string;
    validations: any[];
    label: string;
    type: string;
  };
  lessons: {
    value: { displayValue: string; id: string }[];
    error: string;
    validations: any[];
    label: string;
    type: string;
  };
  unit: {
    value: { displayValue: string; id: string };
    error: string;
    validations: any[];
    label: string;
    type: string;
  };
  units: {
    value: { displayValue: string; id: string }[];
    error: string;
    validations: any[];
    label: string;
    type: string;
  };
  examTitle: {
    value: string;
    error: string;
    validations: any[];
    max?: number;
    min?: number;
    label: string;
  };
  description: {
    value: string;
    error: string;
    validations: any[];
    label: string;
  };

  papers: {
    value: any[];
    error: string;
    validations: any[];
    label: string;
    type: string;
  };

  gradingType: {
    value: { displayValue: string; id: string };
    error: string;
    validations: any[];
    label: string;
    type: string;
  };

  price: {
    value: string;
    error: string;
    validations: any[];
    label: string;
  };

  startTime: {
    value: { hours: string | null; minutes: string | null };
    error: string;
    validations: any[];
    label: '';
  };
  startDate: {
    value: Date;
    error: string;
    validations: any[];
    label: string;
  };

  endTime: {
    value: { hours: string | null; minutes: string | null };
    error: string;
    validations: any[];
    label: string;
  };
  endDate: {
    value: Date;
    error: string;
    validations: any[];
    label: string;
  };
  beginTime: Date;
  finishTime: Date;
  examDuration: {
    value: string;
    error: string;
    validations: any[];
    label: string;
  };
  overallMark: {
    value: string;
    error: string;
    validations: any[];
    label: string;
  };
  examType: {
    value: ExamType | null | undefined;
    error: string;
    validations: any[];
    label: string;
  };
}

export const examCreationStateInitialValue: EXAM_CREATION_STATE = {
  id: '',
  createdAt: new Date(),
  createdBy: '',
  isLoading: {
    value: false,
  },
  grade: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [validateString],
    label: 'Grade',
    type: 'dropdown',
  },
  subject: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [validateString],
    label: 'Subject',
    type: 'dropdown',
  },
  lesson: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [],
    label: 'Lesson',
    type: 'dropdown',
  },
  lessons: {
    value: [],
    error: '',
    validations: [],
    label: 'Lesson',
    type: 'dropdown',
  },
  unit: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [],
    label: 'Lesson',
    type: 'dropdown',
  },
  units: {
    value: [],
    error: '',
    validations: [],
    label: 'Units',
    type: 'dropdown',
  },
  examTitle: {
    value: '',
    error: '',
    validations: [
      validateString,
      validateMaxLength,
      validateMinLength,
      validateOnlyUnderscoreAndHypenAllowed,
    ],
    max: 100,
    min: 3,
    label: 'Exam Title',
  },
  description: {
    value: '',
    error: '',
    validations: [validateDescriptionStringSpaces],
    label: 'Description',
  },

  papers: {
    value: [],
    error: '',
    validations: [],
    label: 'Lesson',
    type: 'dropdown',
  },

  price: {
    value: '',
    error: '',
    validations: [],
    label: '',
  },

  gradingType: {
    value: { displayValue: 'Percentage', id: 'Percentage' },
    error: '',
    validations: [],
    label: 'Role',
    type: 'dropdown',
  },
  startDate: {
    value: new Date(),
    error: '',
    validations: [validateString],
    label: 'start date',
  },
  startTime: {
    value: {
      hours: null,
      minutes: null,
    },
    error: '',
    validations: [validateString],
    label: '',
  },
  endDate: {
    value: new Date(),
    error: '',
    validations: [validateString],
    label: 'start date',
  },
  endTime: {
    value: {
      hours: null,
      minutes: null,
    },
    error: '',
    validations: [validateString],
    label: '',
  },

  beginTime: new Date(),
  finishTime: new Date(),

  examDuration: {
    value: '',
    error: '',
    validations: [],
    label: '',
  },
  overallMark: {
    value: '',
    error: '',
    validations: [],
    label: '',
  },
  examType: {
    value: null,
    error: '',
    validations: [],
    label: '',
  },
};

export interface SUBJECT_DIRECTIVE_STATE_FILTER_OPTIONS {
  lesson: DropDown;
  unit: DropDown;
  searchText: string;
  isHideGradedExams: boolean;
}

export interface LESSON_DIRECTIVE_STATE_FILTER_OPTIONS {
  lesson: DropDown;
  unit: DropDown;
  searchText: string;
  isHideGradedExams: boolean;
}

export interface UNIT_DIRECTIVE_STATE_FILTER_OPTIONS {
  lesson: DropDown;
  unit: DropDown;
  searchText: string;
  isHideGradedExams: boolean;
}

export interface FilterBucket {
  lessonId: string;
  unitId: string;
}

const filterBucketInitState = {
  searchString: '',
  lessonId: '',
  unitId: '',
};
export interface SUBJECT_DIRECTIVE_STATE {
  tab: EXAM_TYPE_TABS;
  publishedList: Exam[];
  draftList: Exam[];
  isLoading: boolean;
  filterOptions: SUBJECT_DIRECTIVE_STATE_FILTER_OPTIONS;
  filterBucket: FilterBucket;
  isOpenFilterBox: boolean;
  isApplyFiltersOn: boolean;
  isOpenLessonModel: boolean;
}

export interface LESSON_DIRECTIVE_STATE {
  tab: EXAM_TYPE_TABS;
  publishedList: Exam[];
  draftList: Exam[];
  isLoading: boolean;
  filterOptions: LESSON_DIRECTIVE_STATE_FILTER_OPTIONS;
  filterBucket: FilterBucket;
  isOpenFilterBox: boolean;
  isApplyFiltersOn: boolean;
  isOpenLessonModel: boolean;
}

export interface UNIT_DIRECTIVE_STATE {
  tab: EXAM_TYPE_TABS;
  publishedList: Exam[];
  draftList: Exam[];
  isLoading: boolean;
  filterOptions: UNIT_DIRECTIVE_STATE_FILTER_OPTIONS;
  filterBucket: FilterBucket;
  isOpenFilterBox: boolean;
  isApplyFiltersOn: boolean;
  isOpenLessonModel: boolean;
}

export const subjectDirectiveInitialState: SUBJECT_DIRECTIVE_STATE = {
  tab: EXAM_TYPE_TABS.LIVE,
  publishedList: [],
  draftList: [],
  isLoading: false,
  filterOptions: {
    lesson: { id: '', displayValue: '' },
    unit: { id: '', displayValue: '' },
    searchText: '',
    isHideGradedExams: false,
  },
  filterBucket: filterBucketInitState,
  isOpenFilterBox: false,
  isApplyFiltersOn: false,
  isOpenLessonModel: false,
};

export const lessonDirectiveInitialState: LESSON_DIRECTIVE_STATE = {
  tab: EXAM_TYPE_TABS.LIVE,
  publishedList: [],
  draftList: [],
  isLoading: false,
  filterOptions: {
    lesson: { id: '', displayValue: '' },
    unit: { id: '', displayValue: '' },
    searchText: '',
    isHideGradedExams: false,
  },
  filterBucket: filterBucketInitState,
  isOpenFilterBox: false,
  isApplyFiltersOn: false,
  isOpenLessonModel: false,
};

export const unitDirectiveInitialState: UNIT_DIRECTIVE_STATE = {
  tab: EXAM_TYPE_TABS.LIVE,
  publishedList: [],
  draftList: [],
  isLoading: false,
  filterOptions: {
    lesson: { id: '', displayValue: '' },
    unit: { id: '', displayValue: '' },
    searchText: '',
    isHideGradedExams: false,
  },
  filterBucket: filterBucketInitState,
  isOpenFilterBox: false,
  isApplyFiltersOn: false,
  isOpenLessonModel: false,
};

export interface CREATE_LESSON_STATE {
  loading: boolean;
  subject: {
    value: { displayValue: string; id: string };
    error: string;
    validations: Function[];
    label: string;
    type: string;
  };
  lessonName: {
    value: string;
    error: string;
    validations: Function[];
    label: string;
    max: number;
  };
  isLessonContainsUnits: {
    value: boolean;
    error: string;
    validations: Function[];
    label: string;
    type: string;
  };
  coverImage: {
    file: null | File;
    value: string;
    error: string;
    validations: Function[];
    label: string;
    loading: boolean;
  };
}

export const createLessonInitialState: CREATE_LESSON_STATE = {
  loading: false,
  subject: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [validateString],
    label: 'Subject name',
    type: 'dropdown',
  },
  lessonName: {
    value: '',
    error: '',
    validations: [
      validateString,
      validateSubjectNameAndLesson,
      validateOnlyUnderscoreAndHypenAllowed,
      validateStringSpaces,
    ],
    label: 'Lesson name',
    max: 20,
  },
  isLessonContainsUnits: {
    value: true,
    error: '',
    validations: [],
    label: 'Lesson Contains Units',
    type: 'dropdown',
  },
  coverImage: {
    file: null,
    value: '',
    error: '',
    validations: [validateString, validateStringSpaces],
    label: 'Cover image',
    loading: false,
  },
};

export interface CREATE_LIVE_EXAM_LOCAL_STATE {
  startTime: {
    value: { hours: string | null; minutes: string | null };
    error: string;
    validations: any[];
    label: '';
  };

  endTime: {
    value: { hours: string | null; minutes: string | null };
    error: string;
    validations: any[];
    label: string;
  };
}

export const initialCreateLiveExamState: CREATE_LIVE_EXAM_LOCAL_STATE = {
  startTime: {
    value: {
      hours: null,
      minutes: null,
    },
    error: '',
    validations: [validateString],
    label: '',
  },

  endTime: {
    value: {
      hours: null,
      minutes: null,
    },
    error: '',
    validations: [validateString],
    label: '',
  },
};

export interface PaperInfoData {
  paperTitle: string;
  uid: string;
  examId: string;
  paperId: string;
  index: number;
  paperType: PaperTypes | undefined;
  assigned?: boolean;
}

export interface ResourceQuestionsState {
  loading: boolean;
  grade: {
    value: DropDown;
    error: string;
    label: string;
    validations: Function[];
    type: 'dropdown';
  };
  subject: {
    value: DropDown;
    error: string;
    validations: Function[];
    label: string;
    type: 'dropdown';
  };
  lesson: {
    value: DropDown;
    error: string;
    validations: Function[];
    label: string;
    type: 'dropdown';
  };
  unit: {
    value: DropDown;
    error: string;
    validations: Function[];
    label: string;
    type: 'dropdown';
  };

  labels: {
    value: DropDown[];
    error: string;
    label: string;
    type: 'dropdown';
  };

  question: { value: string; error: string; validations: Function[]; label: string };
  answers: {
    value: { isCorrect: boolean; answerText: string; error: string }[];
    error: string;
    validations: Function[];
    label: string;
    type: 'answers';
  };
  trueFalseAnswer: { value: boolean; label: string };
  sourceOfQuestion: {
    value: DropDown;
    error: '';
    validations: Function[];
    label: 'Source of the Question';
    type: 'dropdown';
  };
  complexity: {
    value: 50;
  };
  source: {
    value: string;
    error: string;
    validations: Function[];
    label: 'Source';
  };
  resolveGuide: {
    value: string;
    error: string;
    label: 'resolveGuide';
  };
  createdBy: {
    value: string;
  };
}

export interface ResourcePaperState {
  loading: { value: boolean };

  paperTitle: {
    value: string;
    error: string;
    validations: Function[];
    label: string;
    min: number;
    max: number;
  };
  description: { value: string; error: string; validations: Function[]; label: string };
  grade: {
    value: DropDown;
    error: string;
    label: string;
    validations: Function[];
    type: 'dropdown';
  };
  subject: {
    value: DropDown;
    error: string;
    validations: Function[];
    label: string;
    type: 'dropdown';
  };
  lesson: {
    value: DropDown;
    error: string;
    label: string;
    type: 'dropdown';
  };
  lessons: {
    value: DropDown[];
    error: string;
    validations: Function[];
    label: string;
  };
  unit: {
    value: DropDown;
    error: string;
    label: string;
    type: 'dropdown';
  };
  units: {
    value: DropDown[];
    error: string;
    label: string;
  };
  labels: {
    value: DropDown[];
    error: string;
    label: string;
    type: 'dropdown';
  };
  paperType: {
    value: PaperTypes;
  };
  totalNoOfQuestions: { value: ''; error: ''; validations: Function[]; label: string };

  allocatedTime: {
    value: { hours: string; minutes: string };
    error: string;
    validations: Function[];
    label: string;
    min: number;
  };

  questions: {
    value: MCQPaperQuestion[] | TFPaperQuestion[];
  };

  status: {
    value: PaperStatus;
  };

  paperCreationMode: {
    value: { displayValue: PAPER_CREATION_MODE; id: PAPER_CREATION_MODE };
    type: 'dropdown';
  };

  createdBy: {
    value: string;
  };
  active: {
    value: boolean;
  };
  paper: {
    file: null | File;
    value: string;
    error: string;
    validations: Function[];
    fileUploadedProgress: number;
    label: string;
    paperName: string;
    loading: boolean;
  };

  markingScheme: {
    file: null | File;
    value: string;
    error: string;
    validations: Function[];
    fileUploadedProgress: number;
    label: string;
    markingSchemeName: string;
    loading: boolean;
  };

  marks: {
    value: string;
    error: string;
    validations: Function[];
    label: string;
  };
}

export interface DropDown {
  displayValue: string;
  id: string;
}

export interface QuestionData {
  questionId: string;
  subjectId: string;
  lessonId: string;
  unitId: string;
  labelIds: string[];
  grade: string;
  createdBy: string;
}

export interface PaperData {
  paperId: string;
  subjectId: string;
  lessonId: string;
  unitId: string;
  lessonIds: string[];
  unitIds: string[];
  grade: string;
  createdBy: string;
  labels?: string[];
}

export interface ExaminationNavStateAndCheckpointState {
  loading: boolean;
  exam: ExamToDoDTO | null;
  papers: PaperState[];
  paperNo: number;
  examContinue: boolean;
  examId?: string;
  userId?: string;
}

export interface PaperState {
  paperId: string | undefined;
  started: boolean;
  allQuestions: PaperQuestionDTO[];
  currentQuestionIdx: number;
  totalNoOfQuestions: number;
  //change of paper
  paperName: string | undefined;
  paperUrl: string | undefined;

  // -----------------------------

  file: null | File;
  answerSheetName: string;
  answerSheetUrl: string;
  error: string;
  validations: Function[];
  loadingInProgress: boolean;
  fileUploadedProgress: number;
  label: string;

  //common
  paperType: PaperTypes;
  totalNumberOfQuestion: number;
  hours: number;
  minutes: number;
}

export interface PaperStats {
  approved: number;
  rejected: number;
  pending: number;
  notAssigned: number;
  notMarked: number;
}

export interface CREATE_UNIT_STATE {
  loading: boolean;
  subject: {
    value: string;
    error: string;
    validations: Function[];
    label: string;
  };
  lesson: {
    value: string;
    error: string;
    validations: Function[];
    label: string;
  };
  name: {
    value: string;
    error: string;
    validations: Function[];
    label: string;
    max: number;
  };

  description: {
    value: string;
    error: string;
    validations: Function[];
    label: string;
  };

  coverImage: {
    file: null | File;
    value: string;
    error: string;
    validations: Function[];
    label: string;
    loading: boolean;
  };
}

export interface PaperPayment {
  paperId?: string;
  payment: { amount?: number; isFree?: boolean };
}

export const createUnitInitialState: CREATE_UNIT_STATE = {
  loading: false,
  subject: {
    value: '',
    error: '',
    validations: [validateString],
    label: 'Subject id',
  },
  lesson: {
    value: '',
    error: '',
    validations: [validateString],
    label: 'Lesson id',
  },
  name: {
    value: '',
    error: '',
    validations: [validateString, validateSubjectNameAndLesson, validateStringSpaces],
    label: 'Unit name',
    max: 20,
  },
  description: {
    value: '',
    error: '',
    validations: [validateString, validateUnitDescriptionWordCount, isContainOnlyNumbers],
    label: 'Description',
  },
  coverImage: {
    file: null,
    value: '',
    error: '',
    validations: [validateString, validateStringSpaces],
    label: 'Cover image',
    loading: false,
  },
};

export interface PACKAGE_DIRECTIVE_STATE {
  tab: PACKAGE_TYPE_TABS;
}

export const packageDirectiveInitialState: PACKAGE_DIRECTIVE_STATE = {
  tab: PACKAGE_TYPE_TABS.CURRENT_PACKAGES,
};

export const StudentPackageDirectiveInitialState: PACKAGE_DIRECTIVE_STATE = {
  tab: PACKAGE_TYPE_TABS.MY_PACKAGES,
};

export interface ADD_USER_STATE {
  loading: boolean;
  userType: {
    value: { displayValue: string; id: string };
    error: '';
    validations: Function[];
    label: string;
    type: string;
  };
  user: {
    value: { displayValue: string; id: string };
    error: '';
    validations: Function[];
    label: string;
    type: string;
  };
}

export const addUserInitialState: ADD_USER_STATE = {
  loading: false,
  userType: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [validateString],
    label: 'User Type',
    type: 'dropdown',
  },
  user: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [validateString],
    label: 'User',
    type: 'dropdown',
  },
};

export interface CREATE_BULK_ACCESS_CODE_STATE {
  loading: boolean;

  activatedDate: { value: Date; error: string; validations: Function[]; label: string };
  expireDate: { value: Date; error: string; validations: Function[]; label: string };
  discountedAmount: { value: string; error: string; validations: Function[]; label: string };
  discountType: {
    value: { displayValue: string; id: string };
    error: '';
    validations: Function[];
    type: string;
    label: string;
  };
  noOfAccessCodes: { value: string; error: ''; validations: Function[]; label: string };
  grade: {
    value: { displayValue: string; id: string };
    error: '';
    validations: Function[];
    type: string;
    label: string;
  };
  year: {
    value: string;
    error: '';
    validations: Function[];
    type: string;
    label: string;
  };
  subject: {
    value: { displayValue: string; id: string };
    error: '';
    validations: Function[];
    type: string;
    label: string;
  };
  package: {
    value: { displayValue: string; id: string };
    error: '';
    validations: Function[];
    type: string;
    label: string;
  };
}

export const createBulkAccessCodeInitialState: CREATE_BULK_ACCESS_CODE_STATE = {
  loading: false,
  discountedAmount: {
    value: '',
    error: '',
    validations: [validateString],
    label: 'Discount amount',
  },
  discountType: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [validateString],
    label: 'Discount type',
    type: 'dropdown',
  },
  noOfAccessCodes: {
    value: '',
    error: '',
    validations: [validateString],
    label: 'Number of access codes',
  },
  grade: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [validateString],
    label: 'Grade',
    type: 'string',
  },
  year: {
    value: '',
    error: '',
    validations: [validateString],
    label: 'Year',
    type: 'string',
  },
  subject: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [validateString],
    label: 'Subject',
    type: 'string',
  },
  package: {
    value: { displayValue: '', id: '' },
    error: '',
    validations: [validateString],
    label: 'Package',
    type: 'string',
  },
  activatedDate: {
    value: new Date(),
    error: '',
    validations: [validateString],
    label: 'Activated Date',
  },
  expireDate: {
    value: new Date(),
    error: '',
    validations: [validateString],
    label: 'Expire Date',
  },
};

export interface APPLY_ACCESS_CODE_STATE {
  accessCode: {
    value: string;
    error: string;
    validations: Function[];
    type: string;
    label: string;
  };
  appliedAccessCode: string;
  show: boolean;
  discountType: string;
  discountAmount: number;
  calculatedDiscount: number;
}

export const applyAccessCodeInitialState: APPLY_ACCESS_CODE_STATE = {
  accessCode: {
    value: '',
    error: '',
    validations: [validateString],
    type: 'string',
    label: 'Access code',
  },
  appliedAccessCode: '',
  show: false,
  discountType: '',
  discountAmount: 0,
  calculatedDiscount: 0,
};

export interface ADD_STUDENTS_STATE {
  loading: boolean;
   
  user: {
    value: { displayValue: string; id: string }[];
    error: '';
    validations: Function[];
    label: string;
    type: string;
  };
}

export const addStudentsInitialState: ADD_STUDENTS_STATE = {
  loading: false,
  
  user: {
    value: [],
    error: '',
    validations: [validateString],
    label: 'User',
    type: 'dropdown',
  },
};