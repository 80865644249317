import React, { FC } from 'react';
import { FaEye, FaFileAlt, FaFire, FaTextHeight, FaVideo } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { useAppContent } from '../../context/ContentContext';
import RoutingPaths from '../../routes/RoutingPaths';
import './AddContentHeader.scss';
import Notification from './../../../../components/Notification';

interface Props {
  subjectId: string;
  lessonId: string;
  unitId?: string;
}

const AddContentHeader: FC<Props> = (props) => {
  const appContent = useAppContent();
  const history = useHistory();

  return (
    <>
      <header className="add-content-header">
        <div className="add-content-header__title">
          <h5 className="add-content-header__title-txt">Add Content</h5>
          <div
            className="add-content-header__eye"
            onClick={() => {
              appContent.setPreviousPathToPreview(history.location.pathname);

              const dIdOfLastElement =
                appContent.contentData[appContent.contentData.length - 1].droppableId;

              appContent.setMainDroppableId(dIdOfLastElement);

              history.push(
                new RoutingPaths(
                  props.subjectId,
                  props.lessonId,
                  props.unitId,
                  dIdOfLastElement
                ).previewChapterContent()
              );
            }}
          >
            <FaEye />
          </div>
        </div>
        <div
          className="add-content-header__actions"
          style={{
            marginRight: '1.5rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <button
            className="add-content-header__btn"
            onClick={() => {
              const dId = appContent.contentData[appContent.contentData.length - 1].droppableId;

              appContent.addNewTextContentSlotHandler(dId);

              Notification({
                isSuccess: true,
                message: 'Added Text Content',
                autoClose: 400,
              });
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              {<FaTextHeight className="mr-2" size={15} />} Add Text
            </span>
          </button>

          <button
            style={{ marginLeft: '1rem' }}
            className="add-content-header__btn"
            onClick={() => {
              const dId = appContent.contentData[appContent.contentData.length - 1].droppableId;

              appContent.addNewLinkSlotHandler(dId);

              Notification({
                isSuccess: true,
                message: 'Added Link Content',
                autoClose: 400,
              });
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              {<FaFire className="mr-2" size={15} />}Add Links
            </span>
          </button>

          <button
            style={{
              marginLeft: '1rem',
            }}
            className="add-content-header__btn"
            onClick={() => {
              const dId = appContent.contentData[appContent.contentData.length - 1].droppableId;

              appContent.addNewVideoSlotHandler(dId);

              Notification({
                isSuccess: true,
                message: 'Added Video Content',
                autoClose: 400,
              });
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              {<FaVideo className="mr-2" size={15} />}
              Video/Audio
            </span>
          </button>

          <button
            style={{ marginLeft: '1rem' }}
            className="add-content-header__btn"
            onClick={() => {
              const dId = appContent.contentData[appContent.contentData.length - 1].droppableId;

              appContent.addNewExtraMaterialsSlotHandler(dId);

              Notification({
                isSuccess: true,
                message: 'Added Material Content',
                autoClose: 400,
              });
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              {<FaFileAlt className="mr-2" size={15} />}
              Add Material
            </span>
          </button>
        </div>
      </header>
      {appContent.loading ? <LoadingIndicator /> : <></>}
    </>
  );
};

export default AddContentHeader;
