import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { STUDENT_SUBJECT_MENU_ITEMS } from '../constants';
import { useMobileNav } from '../context/MobNavContext';
import { getMenuItems } from '../utils';
import Backarrow from './Backarrow';
import Tab from './Tab';
import './SubjectSecondaryNav.scss';

interface Props {
  onCurrentTabChange: (tab: string) => void;
  onCurrentSubTabChange?: (subTab: string) => void;
  currentTab: string;
  currentSubTab?: string;
  screen: string;
}

const StudentSecondaryNavWithLegacyCode: FC<Props> = (props) => {
  const { subjectId } = useParams() as { subjectId: string };
  const history = useHistory();

  const appMobileNav = useMobileNav();
  const localBaseUrl = `my-subjects/subject/${subjectId}`;

  return (
    <>
      <div className="d-flex ml-3">
        <Backarrow
          onClick={() => {
            history.push('/my-subjects');
            appMobileNav.setOpenMobileNav(!appMobileNav.openMobileNav);
          }}
        />
        <div style={{ color: '#fff', fontSize: 20, marginBottom: 50 }} className="ml-2">
          {props.currentTab}
        </div>
      </div>

      {getMenuItems(
        STUDENT_SUBJECT_MENU_ITEMS,
        props.currentTab,
        props.onCurrentTabChange,
        props.screen,
        props.onCurrentSubTabChange,
        props.currentSubTab
      ).map((item) => (
        <Tab
          text={item.text}
          onClick={props.onCurrentTabChange}
          onSubTabClick={props.onCurrentSubTabChange}
          key={item.text}
          active={item.active}
          subMenuItems={item.subMenuItems}
        />
      ))}

      <div className="subject-snav__btn-parent-wrapper">
        <div className="subject-snav__btn-parent">
          <button
            className="subject-snav__student-pckg-button"
            onClick={() => {
              history.push(`/${localBaseUrl}/Packages`);
            }}
          >
            Packages
          </button>
        </div>
      </div>
    </>
  );
};

export default StudentSecondaryNavWithLegacyCode;
