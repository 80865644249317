import React, { FC, useEffect, useState } from 'react';
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useAuth, useFunctions } from 'reactfire';
import { GRADES } from '../constants';
import { useAdminsAndInstructors } from '../context/AdminsAndInstructors';
import { useAppRestrictions } from '../context/RestrictionsContext';
import { useAppUser } from '../context/UserContext';
import { validationResult } from '../utils';
import {
  validateDobNotToday,
  validateEmail,
  validateMaxLength,
  validatePhoneNumber,
  validateProfileNames,
  validateString,
  validateStringWithNumbersAndSpecialChars,
} from '../validation/Validation';
import Notification from './../components/Notification';
import { toast } from 'react-toastify';
import LoadingIndicator from './LoadingIndicator';
import AlertPopup from './AlertPopup';
import TextInput from './TextInput';
import DropDown from './DropDown';
import PhoneNumberInput from './PhoneNumberInput';
import TextFieldInput from './TextFieldInput';
import VerifyPhoneModal from './VerifyPhoneModal';
import './AccountBody.scss';
import BoxIcon from './BoxIcon';
import { FaCheckCircle, FaEdit } from 'react-icons/fa';
import DatePickerCompWithValidation from './DatePickerCompWithValidation';
// import PaymentCard from '../comp/PaymentCard';
// import {PaymentConfig} from '../../../configs/paymentConfig';

const AccountBody: FC = () => {
  const history = useHistory();
  const [isShowEmailConfirmation, setIsShowEmailConfirmation] = useState(false);
  const [isShowPhoneVerification, setIsShowPhoneVerification] = useState(false);
  const [sameEmail, setSameEmail] = useState(false);
  const [editState, setEditState] = useState(false);
  const [editEmailState, setEditEmailState] = useState(false);
  const [editPhoneState, setEditPhoneState] = useState(false);
  const user = useAppUser();
  const appUsers = useAdminsAndInstructors();

  const [state, setState] = useState({
    firstName: {
      value: '',
      error: '',
      validations: [validateProfileNames],
      label: 'First name',
    },
    lastName: {
      value: '',
      error: '',
      validations: [validateProfileNames],
      label: 'Last name',
    },

    username: {
      value: '',
      error: '',
      validations: [validateString, validateMaxLength],
      label: 'Username',
      min: -1,
      max: 50,
    },
    address: {
      value: '',
      error: '',
      validations: !user.firestoreUser?.userRole?.isAdmin
        ? [validateStringWithNumbersAndSpecialChars]
        : [],
      label: 'Address',
    },

    dob: {
      value: new Date(),
      error: '',
      validations: [validateDobNotToday],
      label: 'DOB',
    },
    school: {
      value: '',
      error: '',
      validations: !user.firestoreUser?.userRole?.isAdmin
        ? [validateStringWithNumbersAndSpecialChars]
        : [],
      label: 'School',
    },

    whatsAppNumber: { value: '', error: '', label: 'WhatsApp', validations: [validatePhoneNumber] },

    grade: {
      value: { displayValue: '', id: '' },
      error: '',
      validations: user.firestoreUser?.userRole?.isStudent ? [validateString] : [],
      label: 'Grade',
      type: 'dropdown',
    },
    loading: false,
    bio: {
      value: '',
      error: '',
      validations: [validateStringWithNumbersAndSpecialChars],
      label: 'Bio',
    },
  });

  const [emailState, setEmailState] = useState({
    email: { value: '', error: '', validations: [validateEmail], label: 'Email' },
  });

  const [phoneState, setPhoneState] = useState({
    phone: { value: '', error: '', validations: [validatePhoneNumber], label: 'Phone' },
  });

  const [isShowInstructorAlert, setIsShowInstructorAlert] = useState(true);

  const appUser = useAppUser();

  const auth = useAuth();

  const isEmailVerified = auth.currentUser?.emailVerified;
  const isPhoneVerified = user.firestoreUser?.isPhoneVerified;

  // useEffect(() => {
  //   let isMounted = true;

  //   if (user.fireUser?.uid && isMounted) {

  //     fire
  //       .collection('users')
  //       .where('uid', '==', user.fireUser.uid)
  //       .get()
  //       .then((data) => {
  //         const userData = data.docs[0]?.data() as UserFirestore;
  //         // tried to get user as the userFirestore but there is type issue in state

  //         const gradeDisplayValue =
  //           GRADES.filter((item) => item.id === userData.grade)[0]?.displayValue || '';
  //         props.setUserName && props.setUserName(userData.username || '');
  //         setState((pS) => ({
  //           ...pS,
  //           loading: false,
  //           firstName: {...state.firstName, value: userData.firstName || ''},
  //           lastName: {...state.lastName, value: userData.lastName || ''},
  //           username: {...state.username, value: userData.username || ''},
  //           address: {...state.address, value: userData.address || ''},
  //           dob: {
  //             ...state.dob,
  //             value: new Date(userData.dob || new Date()),
  //           },
  //           school: {...state.school, value: userData.school || ''},
  //           whatsAppNumber: {...state.whatsAppNumber, value: userData.whatsAppNumber || ''},
  //           grade: {
  //             ...state.grade,
  //             value: {displayValue: gradeDisplayValue, id: gradeDisplayValue},
  //           },
  //         }));
  //         setBioState((pS) => ({...pS, bio: {...pS.bio, value: userData.bio || ''}}));
  //         setEmailState((pS) => ({...pS, email: {...pS.email, value: userData.email || ''}}));
  //         setPhoneState((pS) => ({...pS, phone: {...pS.phone, value: userData.phoneNumber || ''}}));
  //       })
  //       .catch(() => {
  //         setState((pS) => ({
  //           ...pS,
  //           loading: false,
  //         }));
  //       });

  //   }
  //   setState((pS) => ({
  //     ...pS,
  //     loading: true,
  //   }));

  //   return () => {
  //     isMounted = false;
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [appUser.firestoreUser?.phoneNumber]);

  const initialState = {
    loading: false,
    firstName: { ...state.firstName, value: appUser.firestoreUser?.firstName || '', error: '' },
    lastName: { ...state.lastName, value: appUser.firestoreUser?.lastName || '', error: '' },
    username: { ...state.username, value: appUser.firestoreUser?.username || '', error: '' },
    address: { ...state.address, value: appUser.firestoreUser?.address || '', error: '' },
    dob: {
      ...state.dob,
      value: new Date(appUser.firestoreUser?.dob || ''),
      error: '',
    },
    school: { ...state.school, value: appUser.firestoreUser?.school || '', error: '' },
    whatsAppNumber: {
      ...state.whatsAppNumber,
      value: appUser.firestoreUser?.whatsAppNumber || '',
      error: '',
    },
    grade: {
      ...state.grade,
      value: {
        displayValue:
          GRADES.filter((item) => item.id === appUser.firestoreUser?.grade)[0]?.displayValue || '',
        id:
          GRADES.filter((item) => item.id === appUser.firestoreUser?.grade)[0]?.displayValue || '',
      },
      error: '',
    },
    bio: {
      ...state.bio,
      value: appUser.firestoreUser?.bio || '',
      error: '',
    },
  };

  useEffect(() => {
    const gradeDisplayValue =
      GRADES.filter((item) => item.id === appUser.firestoreUser?.grade)[0]?.displayValue || '';

    setState((pS) => ({
      ...pS,
      loading: false,
      firstName: { ...state.firstName, value: appUser.firestoreUser?.firstName || '' },
      lastName: { ...state.lastName, value: appUser.firestoreUser?.lastName || '' },
      username: { ...state.username, value: appUser.firestoreUser?.username || '' },
      address: { ...state.address, value: appUser.firestoreUser?.address || '' },
      dob: {
        ...state.dob,
        value: new Date(appUser.firestoreUser?.dob || ''),
      },
      school: { ...state.school, value: appUser.firestoreUser?.school || '' },
      whatsAppNumber: {
        ...state.whatsAppNumber,
        value: appUser.firestoreUser?.whatsAppNumber || '',
      },
      grade: {
        ...state.grade,
        value: { displayValue: gradeDisplayValue, id: gradeDisplayValue },
      },
      bio: {
        ...state.bio,
        value: appUser.firestoreUser?.bio || '',
      },
    }));
    setEmailState((pS) => ({
      ...pS,
      email: { ...pS.email, value: appUser.firestoreUser?.email || '' },
    }));
    setPhoneState((pS) => ({
      ...pS,
      phone: { ...pS.phone, value: appUser.firestoreUser?.phoneNumber || '' },
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPhoneState((pS) => ({
      ...pS,
      phone: { ...pS.phone, value: appUser.firestoreUser?.phoneNumber || '' },
    }));
  }, [appUser.firestoreUser?.phoneNumber]);

  const restrictions = useAppRestrictions();

  const updateProfileRef = useFunctions().httpsCallable('updateUserDetails');
  const changeUserEmailRef = useFunctions().httpsCallable('changeUserEmail');
  const updateUserPhoneRef = useFunctions().httpsCallable('updateUserPhone');
  const sendOTPRef = useFunctions().httpsCallable('sendOTP');

  useEffect(() => {
    if (!appUser.firestoreUser?.phoneNumber) {
      restrictions.setIsPhoneNumberPresent(true);
    } else {
      restrictions.setIsPhoneNumberPresent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser.firestoreUser?.phoneNumber]);

  useEffect(() => {
    if (!appUser.firestoreUser?.grade) {
      restrictions.setIsGradePresent(true);
    } else {
      restrictions.setIsGradePresent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appUser.firestoreUser?.grade]);

  const updateEmailHandler = () => {
    if (toast.isActive('tid')) {
      return;
    }

    setState((pS) => ({
      ...pS,
      loading: true,
    }));

    changeUserEmailRef({
      uid: user.fireUser?.uid,
      email: emailState.email.value,
    })
      .then((data) => {
        if (data.data.status === 201) {
          setState((pS) => ({
            ...pS,
            loading: false,
          }));
        }

        history.push({
          pathname: '/email-verification',
          state: { ...data.data.data, action: 'signIn' },
        });

        user.logOut();
      })
      .catch((err) => {
        console.log(err);

        Notification({
          isSuccess: false,
          errorHeader: 'Changing email failed',
          message: err.message,
          id: 'tid',
        });

        setIsShowEmailConfirmation(false);
        setState((pS) => ({
          ...pS,
          loading: false,
        }));
      });
  };

  const updateUserPhoneHandler = async () => {
    if (toast.isActive('tid')) {
      return;
    }

    setState((ps) => ({
      ...ps,
      loading: true,
    }));

    const validationOutput = validationResult(phoneState);

    setPhoneState(validationOutput.state);

    if (validationOutput.formValidity.trim().length > 0) {
      setState((ps) => ({
        ...ps,
        loading: false,
      }));
      return;
    }

    try {
      await updateUserPhoneRef({
        userId: user.fireUser?.uid,
        phone: phoneState.phone.value,
      });

      Notification({
        isSuccess: true,
        message: 'Phone number updated successfully',
        id: 'tid',
      });
      setEditPhoneState(false);
    } catch (e) {
      console.log(e);
      Notification({
        isSuccess: false,
        message: 'Failed to update phone number',
        id: 'tid',
      });
    } finally {
      setPhoneState((ps) => ({
        ...ps,
        phone: { ...ps.phone, error: '' },
      }));
      setState((ps) => ({
        ...ps,
        loading: false,
      }));
    }
  };

  const sendOTPHandler = async () => {
    setState((ps) => ({
      ...ps,
      loading: true,
    }));

    if (user.fireUser?.uid) {
      await sendOTPRef({ userID: user.fireUser?.uid })
        .then((res) => {
          setIsShowPhoneVerification(true);
          setState((ps) => ({
            ...ps,
            loading: false,
          }));
          console.log('res', res);
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  };

  const updateProfileHandler = async () => {
    if (toast.isActive('tid')) {
      return;
    }

    const ignoreFields = appUser.firestoreUser?.userRole?.isAdmin ? ['dob'] : undefined;

    const validationOutput = validationResult({ ...state, loading: true }, ignoreFields);

    setState(validationOutput.state);

    if (validationOutput.formValidity.trim().length > 0) {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      return;
    }

    const { firstName, lastName, username, address, dob, school, whatsAppNumber, grade, bio } =
      state;

    try {
      await updateProfileRef({
        uid: user.fireUser?.uid,
        lastName: lastName.value,
        displayName: firstName.value,
        firstName: firstName.value,
        username: username.value,
        address: address.value,
        dob: dob.value.toDateString(),
        school: school.value,
        whatsAppNumber: whatsAppNumber.value,
        grade: grade.value.id,
        bio: bio.value,
      }).then((data) => {
        if (data.data.status === 400) {
          Notification({
            isSuccess: false,
            message: data.data.data,
            id: 'tid',
          });

          setState((pS) => {
            return {
              ...pS,
              loading: false,
            };
          });
          return;
        }

        setState((pS) => {
          return {
            ...pS,
            loading: false,
          };
        });

        setEditState(false);

        Notification({
          isSuccess: true,
          message: 'Profile updated successfully',
          id: 'tid',
        });

        appUsers.fetchAdminsAndInstructors();
      });
    } catch (e) {
      console.log(e);

      Notification({
        isSuccess: false,
        message: 'Failed to update profile.Try again later',
        id: 'tid',
      });
    } finally {
      setState((pS) => ({
        ...pS,
        firstName: { ...pS.firstName, error: '' },
        lastName: { ...pS.lastName, error: '' },
        username: { ...pS.username, error: '' },
        address: { ...pS.address, error: '' },
        school: { ...pS.school, error: '' },
        whatsAppNumber: { ...pS.whatsAppNumber, error: '' },
        grade: { ...pS.grade, error: '' },
        dob: { ...pS.dob, error: '' },
        bio: { ...pS.bio, error: '' },
        loading: false,
      }));
    }
  };

  const resetValidations = () => {
    setState(initialState);
  };

  return (
    <Container fluid className="px-0">
      {state.loading && (
        <div className="acc-loading-indicator">
          <LoadingIndicator />
        </div>
      )}
      {(user.firestoreUser?.userRole?.isInstructor ||
        user.firestoreUser?.userRole?.isTeachingAssistant) &&
        !user.firestoreUser.isApproved && (
          <AlertPopup
            header="Not Validated"
            message={`Your account hasn't been validated by an admin. You can access ${
              user.firestoreUser?.userRole?.isInstructor ? 'instructor' : 'teaching assistant'
            } features after being validated. Please contact an admin for more details.`}
            isShow={isShowInstructorAlert}
            type={'NO_BUTTON'}
            onClose={() => setIsShowInstructorAlert(false)}
          />
        )}
      <div>
        <AlertPopup
          message={
            'Changing the email will log you out of the system and you will have to verify the provided email before you can login again. Are you sure you want to change the email?'
          }
          header="Attention"
          isShow={isShowEmailConfirmation}
          primaryButtonText="Log Out"
          onOk={updateEmailHandler}
          onClose={() => {
            setIsShowEmailConfirmation(false);
          }}
        />
        <AlertPopup
          message="The email cannot be changed to the existing email."
          header="Attention"
          isShow={sameEmail}
          // onOk={() => {
          //   setSameEmail(false);
          // }}
          onClose={() => {
            setSameEmail(false);
          }}
          type="NO_BUTTON"
        />
        <VerifyPhoneModal
          isOpen={isShowPhoneVerification}
          onClose={() => {
            setIsShowPhoneVerification(false);
          }}
          userId={user.fireUser?.uid}
        />
      </div>
      <Row>
        <Col className="ab-l-col" style={{ marginTop: state.loading ? 18 : 68 }}>
          <div className="account-border">
            <div className="account-l-scrollView">
              {editState ? (
                <>
                  <TextInput
                    className="fn-margin"
                    stateName="firstName"
                    stateValue={state.firstName.value}
                    state={state}
                    setState={setState}
                    error={state.firstName.error}
                    placeHolder="First Name"
                  />

                  <TextInput
                    className="account-margin"
                    stateName="lastName"
                    stateValue={state.lastName.value}
                    state={state}
                    setState={setState}
                    error={state.lastName.error}
                    placeHolder="Last name"
                  />

                  <TextInput
                    className="account-margin"
                    stateName="username"
                    stateValue={state.username.value}
                    state={state}
                    setState={setState}
                    error={state.username.error}
                    placeHolder="Username"
                  />

                  {user.firestoreUser?.userRole?.isStudent ? (
                    <>
                      <DropDown
                        className="grade-margin"
                        name="Grade"
                        noValueText="Select grade"
                        stateName="grade"
                        stateValue={state.grade.value}
                        state={state}
                        setState={setState}
                        optionsArray={GRADES}
                        error={state.grade.error}
                      />
                    </>
                  ) : null}

                  {user.firestoreUser?.userRole?.isAdmin ? null : (
                    <>
                      <TextInput
                        className="account-margin"
                        stateName="address"
                        stateValue={state.address.value}
                        state={state}
                        setState={setState}
                        error={state.address.error}
                        placeHolder="Address"
                      />
                    </>
                  )}
                  <PhoneNumberInput
                    className="grade-margin"
                    stateName="whatsAppNumber"
                    stateValue={state.whatsAppNumber.value}
                    state={state}
                    setState={setState}
                    error={state.whatsAppNumber.error}
                    placeHolder="WhatsApp Number"
                    maxLength={10}
                  />

                  {user.firestoreUser?.userRole?.isAdmin ? null : (
                    <>
                      <DatePickerCompWithValidation
                        name="Date of Birth"
                        className="grade-margin"
                        stateName="dob"
                        stateValue={state.dob.value}
                        state={state}
                        setState={setState}
                        error={state.dob.error}
                      />

                      <TextInput
                        className="account-margin-school"
                        stateName="school"
                        stateValue={state.school.value}
                        state={state}
                        setState={setState}
                        error={state.school.error}
                        placeHolder="School"
                      />
                    </>
                  )}

                  <TextFieldInput
                    name="Bio"
                    className="bio-margin"
                    stateName="bio"
                    stateValue={state.bio.value}
                    state={state}
                    setState={setState}
                    limit={250}
                    error={state.bio.error}
                    isUpdate={true}
                  />
                </>
              ) : (
                <>
                  <span className="account-detail-titles"> First Name </span>
                  <h5 className="account-detail-data">
                    {' '}
                    {state.firstName.value ? state.firstName.value : '-'}{' '}
                  </h5>

                  <span className="account-detail-titles"> Last Name </span>
                  <h5 className="account-detail-data">
                    {' '}
                    {state.lastName.value ? state.lastName.value : '-'}{' '}
                  </h5>

                  <span className="account-detail-titles"> Username </span>
                  <h5 className="account-detail-data">
                    {' '}
                    {state.username.value ? state.username.value : '-'}{' '}
                  </h5>

                  {user.firestoreUser?.userRole?.isStudent ? (
                    <>
                      <span className="account-detail-titles"> Grade </span>
                      <h5 className="account-detail-data">
                        {' '}
                        {state.grade.value.displayValue ? state.grade.value.displayValue : '-'}{' '}
                      </h5>
                    </>
                  ) : null}

                  {user.firestoreUser?.userRole?.isAdmin ? null : (
                    <>
                      <span className="account-detail-titles"> Address </span>
                      <h5 className="account-detail-data">
                        {' '}
                        {state.address.value ? state.address.value : '-'}{' '}
                      </h5>
                    </>
                  )}
                  <span className="account-detail-titles"> WhatsApp Number </span>
                  <h5 className="account-detail-data">
                    {' '}
                    {state.whatsAppNumber.value ? state.whatsAppNumber.value : '-'}{' '}
                  </h5>

                  {user.firestoreUser?.userRole?.isAdmin ? null : (
                    <>
                      <span className="account-detail-titles"> Date of Birth </span>
                      <h5 className="account-detail-data">
                        {state.dob.value.toLocaleDateString() === 'Invalid Date'
                          ? '-'
                          : state.dob.value.toLocaleDateString()}
                      </h5>

                      <span className="account-detail-titles"> School </span>
                      <h5 className="account-detail-data">
                        {' '}
                        {state.school.value ? state.school.value : '-'}{' '}
                      </h5>
                    </>
                  )}

                  <span className="account-detail-titles"> Bio </span>
                  <div>
                    <span className="account-detail-bio">
                      {state.bio.value ? state.bio.value : '-'}
                    </span>
                  </div>
                </>
              )}
            </div>

            <div className="account-detail-edit">
              {editState ? (
                <>
                  <span
                    className={`save-profile-span ${state.loading && 'save-profile-span-disable'}`}
                    onClick={() => {
                      if (!state.loading) {
                        updateProfileHandler();
                      }
                    }}
                  >
                    Save
                  </span>
                  <span
                    className={`cancel-profile-span ${
                      state.loading && 'cancel-profile-span-disable'
                    }`}
                    onClick={() => {
                      setState((pS) => ({
                        ...pS,
                        loading: false,
                        dob: {
                          ...state.dob,
                          value: new Date(''),
                        },
                      }));
                      setEditState(false);
                      resetValidations();
                    }}
                  >
                    Cancel
                  </span>
                </>
              ) : (
                <span
                  className={`edit-profile-span ${state.loading && 'edit-profile-span-disable'}`}
                  onClick={() => {
                    setState((pS) => ({
                      ...pS,
                      loading: false,
                      dob: {
                        ...state.dob,
                        value: appUser.firestoreUser?.dob
                          ? new Date(appUser.firestoreUser?.dob || '')
                          : new Date(),
                      },
                    }));

                    setEditState(true);
                  }}
                >
                  Edit Profile
                </span>
              )}
            </div>
          </div>
        </Col>

        <Col className="ab-r-col" style={{ marginTop: state.loading ? 8 : 68 }}>
          <div className={`account-rSide-box ${!editEmailState ? 'd-flex' : ''}`}>
            {!editEmailState ? (
              <>
                <div style={{ flex: 3 }}>
                  <span className="account-rSide-box__title"> Email </span>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="account-rSide-box__content">{emailState.email.value}</div>
                    {isEmailVerified ? (
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="verifiedId">Verified</Tooltip>}
                      >
                        <FaCheckCircle color="#246BFD" size={13} className="eye" />
                      </OverlayTrigger>
                    ) : null}
                  </span>
                </div>
                <div style={{ flex: 0.4 }} className="account-rSide-box__edit">
                  <BoxIcon
                    icon={FaEdit}
                    color="#fff"
                    size={18}
                    style={{ width: 30, height: 30, backgroundColor: '' }}
                    onClick={() => {
                      setEditEmailState(true);
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <TextInput
                  stateName="email"
                  stateValue={emailState.email.value}
                  state={emailState}
                  setState={setEmailState}
                  error={emailState.email.error}
                  placeHolder="Email"
                  verified={isEmailVerified}
                />

                <div>
                  <span
                    className={`change-email-span ${state.loading && 'change-email-span-disable'}`}
                    onClick={() => {
                      if (appUser.firestoreUser?.email === emailState.email.value) {
                        setSameEmail(true);

                        return;
                      }

                      const validationOutput = validationResult({ ...emailState });

                      setEmailState(validationOutput.state);

                      if (validationOutput.formValidity.trim().length > 0 || state.loading) {
                        return;
                      } else {
                        setIsShowEmailConfirmation(true);
                      }
                      setEditEmailState(false);
                    }}
                  >
                    Save
                  </span>
                  <span
                    className={`cancel-email-span ${state.loading && 'cancel-email-span-disable'}`}
                    onClick={() => {
                      if (!state.loading) {
                        setEditEmailState(false);
                        setEmailState((pS) => ({
                          ...pS,
                          email: {
                            ...pS.email,
                            value: appUser.firestoreUser?.email || '',
                          },
                        }));
                      }
                    }}
                  >
                    Cancel
                  </span>
                </div>
              </>
            )}
          </div>
          <div className={`account-rSide-box ${!editPhoneState ? 'd-flex' : ''} `}>
            {!editPhoneState ? (
              <>
                <div style={{ flex: 3 }}>
                  <span className="account-rSide-box__title"> Phone </span>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="account-rSide-box__content">
                      {phoneState.phone.value ? phoneState.phone.value : '-'}
                    </div>
                    {isPhoneVerified ? (
                      <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="verifiedId">Verified</Tooltip>}
                      >
                        <FaCheckCircle color="#246BFD" size={13} className="eye" />
                      </OverlayTrigger>
                    ) : (
                      <>
                        {appUser.firestoreUser?.phoneNumber === undefined ? null : (
                          <>
                            <OverlayTrigger
                              placement="right"
                              overlay={<Tooltip id="verifiedId">Not Verified</Tooltip>}
                            >
                              <FaCheckCircle color="#73847F" size={13} className="eye" />
                            </OverlayTrigger>
                            <span
                              className={`change-phone-span ${
                                state.loading && 'change-phone-span-disable'
                              }`}
                              onClick={() => {
                                if (!state.loading) {
                                  sendOTPHandler();
                                }
                              }}
                            >
                              Verify
                            </span>
                          </>
                        )}
                      </>
                    )}
                  </span>
                </div>
                <div style={{ flex: 0.4 }} className="account-rSide-box__edit">
                  <BoxIcon
                    icon={FaEdit}
                    color="#fff"
                    size={18}
                    style={{ width: 30, height: 30, backgroundColor: '' }}
                    onClick={() => {
                      setEditPhoneState(true);
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <PhoneNumberInput
                  className="account-margin"
                  stateName="phone"
                  stateValue={phoneState.phone.value}
                  state={phoneState}
                  setState={setPhoneState}
                  error={phoneState.phone.error}
                  placeHolder="Phone"
                  maxLength={12}
                  verified={isPhoneVerified}
                />

                <div style={{ marginTop: '5px' }}>
                  <span
                    className={`change-phone-span ${
                      state.loading || appUser.firestoreUser?.phoneNumber === phoneState.phone.value
                        ? 'change-phone-span-disable'
                        : ''
                    }`}
                    onClick={() => {
                      if (
                        state.loading ||
                        appUser.firestoreUser?.phoneNumber === phoneState.phone.value
                      ) {
                      } else {
                        updateUserPhoneHandler();
                      }
                    }}
                  >
                    {appUser.firestoreUser?.phoneNumber === '-' ||
                    appUser.firestoreUser?.phoneNumber === undefined
                      ? 'Add Number'
                      : 'Change Number'}
                  </span>
                  <span
                    className={`cancel-phone-span ${state.loading && 'cancel-phone-span-disable'}`}
                    onClick={() => {
                      if (!state.loading) {
                        setEditPhoneState(false);
                        setPhoneState((pS) => ({
                          ...pS,
                          phone: {
                            ...pS.phone,
                            value: appUser.firestoreUser?.phoneNumber || '',
                            error: '',
                          },
                        }));
                      }
                    }}
                  >
                    Cancel
                  </span>
                </div>
              </>
            )}
          </div>

          {/* <div className="card-section">
            {appUser.firestoreUser?.paymentPreApprove &&
            (appUser.firestoreUser.paymentPreApprove.status_code === '0' ||
              appUser.firestoreUser.paymentPreApprove.status_code === '2') ? (
              <PaymentCard
                card={appUser.firestoreUser.paymentPreApprove}
                setState={setState}
                state={state}
              />
            ) : (
              <>
                <span>Add Bank Card</span>
                <form
                  className="form-card"
                  method="post"
                  action={PaymentConfig.PreApproveActionURL}
                >
                  <input type="hidden" name="merchant_id" value={PaymentConfig.MerchantID} />
                  <input type="hidden" name="return_url" value={PaymentConfig.PaymentReturnURL} />
                  <input type="hidden" name="cancel_url" value={PaymentConfig.CancelURL} />
                  <input type="hidden" name="notify_url" value={PaymentConfig.NotifyURL} />
                  <input type="hidden" name="custom_1" value={appUser.firestoreUser?.uid} />
                  <input type="hidden" name="order_id" value={appUser.firestoreUser?.uid} />
                  <input type="hidden" name="items" value="Ozone LMS" />
                  <input type="hidden" name="currency" value="LKR" />
                  <input type="hidden" name="first_name" value={appUser.firestoreUser?.firstName} />
                  <input type="hidden" name="last_name" value={appUser.firestoreUser?.lastName} />
                  <input type="hidden" name="email" value={appUser.firestoreUser?.email} />
                  <input type="hidden" name="phone" value={appUser.firestoreUser?.phone} />
                  <input type="hidden" name="address" value={appUser.firestoreUser?.address} />
                  <input type="hidden" name="city" value={appUser.firestoreUser?.address} />
                  <input type="hidden" name="country" value="Sri Lanka" />
                  <button type="submit" className="btn-add-card d-flex" disabled={state.loading}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      width="100px"
                      height="100px"
                    >
                      <linearGradient
                        id="Et0R~umK8VADpitl6wcz1a"
                        x1="20.375"
                        x2="28.748"
                        y1="1365.061"
                        y2="1394.946"
                        gradientTransform="translate(0 -1354)"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stop-color="#00b3ee" />
                        <stop offset="1" stop-color="#0082d8" />
                      </linearGradient>
                      <path
                        fill="url(#Et0R~umK8VADpitl6wcz1a)"
                        d="M43.125,9H4.875C3.287,9,2,10.287,2,11.875v24.25C2,37.713,3.287,39,4.875,39h38.25	C44.713,39,46,37.713,46,36.125v-24.25C46,10.287,44.713,9,43.125,9z"
                      />
                      <path
                        d="M25.733,31c-1.376-0.014-2.847-0.291-3.752-0.708l-0.658-0.303l-0.178,0.83h-5.048l1.844-8.621	l-3.612,8.621H9.717l-2.59-10.013c-0.061-0.239-0.09-0.304-0.103-0.32c-0.006-0.006-0.034-0.024-0.119-0.071	c-0.574-0.312-1.607-0.652-2.698-0.89l-0.971-0.211l0.444-2.103h5.92c1.18,0,2.131,0.8,2.365,1.989l0.626,3.326l2.104-5.315h9.36	L23.674,19c1.055-1.245,2.845-2,5.037-2c1.072,0,2.073,0.183,3.151,0.576l0.818,0.299l-0.971,4.53l-1.135-0.521	c-0.82-0.378-1.555-0.457-2.026-0.457c-0.165,0-0.297,0.011-0.386,0.022c-0.065,0.008-0.123,0.019-0.173,0.031	c0.228,0.14,0.531,0.301,0.713,0.396c1.074,0.563,2.598,1.362,3.098,2.989l2.592-6.188c0.379-0.903,1.222-1.465,2.2-1.465h3.376	l2.842,13.607h-4.82l-0.363-1.733h-2.338l-0.627,1.733h-5.356l0.322-0.769C28.598,30.659,27.271,31,25.738,31H25.733z M23.366,25.935c0.704,0.33,1.367,0.641,2.543,0.641l0.104-0.001c0.312-0.005,0.594-0.077,0.738-0.147	c-0.117-0.094-0.373-0.265-0.924-0.533c-1.614-0.785-2.621-1.758-3.009-2.902l-0.522,2.444L23.366,25.935z"
                        opacity=".05"
                      />
                      <path
                        d="M25.733,30.5c-1.306-0.013-2.696-0.272-3.543-0.662l-0.363-0.167l0.759-3.549l0.569,0.266	c0.717,0.336,1.467,0.688,2.754,0.688l0.109-0.001c0.521-0.008,1.3-0.186,1.306-0.642c0.002-0.188-0.152-0.44-1.277-0.988	c-0.727-0.354-2.937-1.432-2.908-3.615c0.019-2.548,2.311-4.329,5.572-4.329c1.013,0,1.96,0.174,2.98,0.546l0.409,0.149	l-0.749,3.495l-0.567-0.261c-0.903-0.416-1.714-0.503-2.235-0.503c-0.193,0-0.348,0.013-0.451,0.026	c-0.609,0.079-0.836,0.335-0.84,0.487c-0.005,0.238,0.672,0.594,1.216,0.88c1.267,0.664,3.001,1.575,2.992,3.711	c-0.012,2.673-2.313,4.469-5.728,4.469H25.733z M38.396,30.319l-0.363-1.733h-3.095l-0.627,1.733h-4.254l4.796-11.449	c0.3-0.715,0.967-1.158,1.739-1.158h2.97l2.633,12.607H38.396z M37.327,25.206l-0.424-2.033l-0.738,2.033H37.327z M16.716,30.319	l2.697-12.607h4.024l-2.696,12.607H16.716z M10.104,30.319l-2.492-9.638c-0.124-0.486-0.184-0.552-0.468-0.706	c-0.612-0.333-1.696-0.692-2.83-0.938l-0.485-0.105l0.258-1.22h5.515c0.934,0,1.688,0.637,1.875,1.586l0.945,5.021l2.614-6.607	h4.245l-5.284,12.607H10.104z"
                        opacity=".07"
                      />
                      <path
                        fill="#fff"
                        d="M23.638,21.836c-0.021,1.672,1.49,2.604,2.628,3.159c1.169,0.569,1.562,0.934,1.558,1.443	c-0.009,0.779-0.933,1.123-1.798,1.136c-1.509,0.023-2.386-0.407-3.083-0.733L22.4,29.384c0.7,0.322,1.995,0.604,3.339,0.616	c3.153,0,5.217-1.557,5.228-3.97c0.012-3.063-4.237-3.233-4.208-4.602c0.01-0.415,0.406-0.858,1.274-0.971	c0.43-0.057,1.616-0.1,2.96,0.519l0.528-2.46C30.798,18.252,29.868,18,28.711,18C25.743,18,23.655,19.578,23.638,21.836 M36.592,18.212c-0.576,0-1.061,0.336-1.278,0.851L30.81,29.819h3.151l0.627-1.733h3.851l0.364,1.733h2.777l-2.424-11.607H36.592 M37.033,21.348l0.909,4.359h-2.491L37.033,21.348 M19.818,18.212l-2.484,11.607h3.003l2.483-11.607H19.818 M15.375,18.212	l-3.126,7.9l-1.264-6.717c-0.148-0.75-0.734-1.183-1.385-1.183h-5.11L4.42,18.549c1.049,0.228,2.241,0.595,2.963,0.988	c0.442,0.24,0.568,0.45,0.713,1.02l2.395,9.263h3.174l4.865-11.607L15.375,18.212"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 48 48"
                      width="100px"
                      height="100px"
                    >
                      <path
                        fill="#3F51B5"
                        d="M45,35c0,2.209-1.791,4-4,4H7c-2.209,0-4-1.791-4-4V13c0-2.209,1.791-4,4-4h34c2.209,0,4,1.791,4,4V35z"
                      />
                      <path fill="#FFC107" d="M30 14A10 10 0 1 0 30 34A10 10 0 1 0 30 14Z" />
                      <path
                        fill="#FF3D00"
                        d="M22.014,30c-0.464-0.617-0.863-1.284-1.176-2h5.325c0.278-0.636,0.496-1.304,0.637-2h-6.598C20.07,25.354,20,24.686,20,24h7c0-0.686-0.07-1.354-0.201-2h-6.598c0.142-0.696,0.359-1.364,0.637-2h5.325c-0.313-0.716-0.711-1.383-1.176-2h-2.973c0.437-0.58,0.93-1.122,1.481-1.595C21.747,14.909,19.481,14,17,14c-5.523,0-10,4.477-10,10s4.477,10,10,10c3.269,0,6.162-1.575,7.986-4H22.014z"
                      />
                    </svg>
                  </button>
                  {appUser.firestoreUser?.paymentPreApprove && (
                    <span className="text-danger" style={{fontSize: 10}}>
                      Previous Card verification Failed Please Add another Card
                    </span>
                  )}
                </form>
              </>
            )}
          </div> */}
        </Col>
      </Row>
      <Row>
        <Col md={1} className="ab-l-col"></Col>
      </Row>
    </Container>
  );
};

export default AccountBody;
