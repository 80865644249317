import firebase from 'firebase';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { Package } from '../models/Package';
import { sortByPackageName } from '../utils';

export interface PackagesContext {
  allPackages: Package[];
  isLoading: boolean;
  fetchPackages: () => void;
  setLoading: (value: boolean) => void;
  setPackages: (packages: Package[]) => void;
}

interface State {
  allPackages: Package[];
  isLoading: boolean;
}

const initialContext: PackagesContext = {
  allPackages: [],
  isLoading: false,
  fetchPackages: () => {},
  setLoading: (value: boolean) => {},
  setPackages: (packages: Package[]) => {}
};

const initialState: State = {
  allPackages: [],
  isLoading: false
};

const Context = createContext<PackagesContext>(initialContext);

export const PackageProvider: FC = ({ children }) => {

  const [state, setState] = useState(initialState);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
        fetchPackages();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPackages = () => {
    const packages: Package[] = [];

    setState((ps) => {
      return {
        ...ps,
        isLoading: true,
      };
    });

    firebase
      .firestore()
      .collection('packages')
      .get()
      .then((data) => {
        data.forEach((doc) => {
            packages.push({id: doc.id, ...doc.data()} as any);
        });


        setState((ps) => {
          return {
            ...ps,
            allPackages: sortByPackageName(packages),
            isLoading: false,
          };
        });
      });
    };
    
  const setLoading = (value: boolean) => {
    setState((ps) => ({
      ...ps,
      isLoading: value,
    }));
  };

  const setPackages = (packages: Package[]) => {
    setState((ps) => {
      return {
        ...ps,
        allPackages: sortByPackageName(packages),
        isLoading: false,
      };
    });
  };

  return (
    <Context.Provider
      value={{
        ...state,
        fetchPackages,
        setLoading,
        setPackages,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const usePackages = (): PackagesContext => useContext(Context);
