import React, { FC, useEffect, useRef, useState } from 'react';
import Plyr from 'plyr';
import Hls from 'hls.js';
import { videoUrlPrefix } from '../configs';

interface Props {
  fileNameInUrl: string;
}

interface Options {
  quality: {
    default: number;
    options: any;
    forced: boolean;
    onChange: (e: any) => void;
  };
  i18n: {
    qualityLabel: any;
  };
}

const VideoPlayer: FC<Props> = (props) => {
  const [error, setError] = useState('');

  const [player, setPlayer] = useState<Plyr | null>(null);
  const [vid, setVid] = useState<any>(null);
  const hlsObject = useRef(new Hls());

  useEffect(() => {
    try {
      const encryptedUrl = props.fileNameInUrl.split('.')[0] + '.m3u8';

      hlsObject.current.loadSource(videoUrlPrefix + encryptedUrl);

      hlsObject.current.on(Hls.Events.ERROR, function (event, data) {
        setError(
          'Either the video is not available or the video is still being processed. Please try in few minutes'
        );
      });

      hlsObject.current.on(Hls.Events.MANIFEST_PARSED, () => {
        const qualityLevels = hlsObject.current.levels;

        const resolutions = qualityLevels.map((level) => level.height);

        const videoElement = document.getElementById('id' + props.fileNameInUrl) as any;
        const defaultOptions: Options = {
          quality: {
            default: 0, //Default - AUTO
            options: resolutions,
            forced: true,
            onChange: (e) => updateQuality(e),
          },
          i18n: {
            qualityLabel: {
              0: 'Auto',
            },
          },
        };

        const plyrPlayer = new Plyr(videoElement, defaultOptions);
        const PLAYER = plyrPlayer as any;
        const video = PLAYER.media;
        hlsObject.current.attachMedia(video);

        setVid(video);
        setPlayer(PLAYER);
      });
    } catch (e) {
      setError(
        'Either the video is not available or the video is still being processed. Please try in few minutes'
      );
    }
  }, [props.fileNameInUrl]);

  useEffect(() => {
    try {
      if (player && vid) {
        if (Hls.isSupported()) {
          hlsObject.current.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
            var span = document.querySelector(
              ".plyr__menu__container [data-plyr='quality'][value='0'] span"
            );

            if (span) {
              if (hlsObject.current.autoLevelEnabled) {
                span.innerHTML = `AUTO (${hlsObject.current.levels[data.level].height}p)`;
              } else {
                span.innerHTML = `AUTO`;
              }
            }

            setError('');
          });

          hlsObject.current.on(Hls.Events.ERROR, function (event, data) {
            setError(
              'Either the video is not available or the video is still being processed. Please try in few minutes'
            );
          });
        } else if (vid.canPlayType('application/vnd.apple.mpegurl')) {
          vid.addEventListener('canplay', function () {
            vid.play();
          });
        }
      }
    } catch (e) {
      setError(
        'Either the video is not available or the video is still being processed. Please try in few minutes'
      );
    }
  }, [player, vid, props.fileNameInUrl]);

  function updateQuality(newQuality: number) {
    if (newQuality === 0) {
      hlsObject.current.currentLevel = -1; //Enable AUTO quality if option.value = 0
    } else {
      hlsObject.current.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          console.log('Found quality match with ' + newQuality);
          hlsObject.current.currentLevel = levelIndex;
        }
      });
    }
  }

  return (
    <>
      <div className="plyr">
        <video controls id={'id' + props.fileNameInUrl}>
          <source src={''} type="application/x-mpegURL" />
        </video>
      </div>
      {error ? <div className="text-white">{error}</div> : <></>}
    </>
  );
};

export default VideoPlayer;
