import { isNumeric } from '../utils';

export const authError = new Map([
  [
    'Firebase: Error (auth/email-already-in-use).',
    'Duplicate email: You already have a Scrapbook account with this email. Please login with the existing account or use forget password option if you forget your password'
  ],
  ['auth/network-request-failed', 'Network faliure'],
  [
    'The email address is already in use by another account.',
    'Duplicate email: You already have a Scrapbook account with this email. Please login with the existing account or use forget password option if you forget your password'
  ],
  ['The password is invalid or the user does not have a password.', 'Bad Credentials'],
  ['Request Invalid!', 'Request Invalid!']
]);

export const validateString = (name: string, value: string) => {
  if (name.toLowerCase() === 'role') {
    return value ? '' : 'Select your user role';
  }
  return value && value.toString().trim() ? '' : `The ${name.toLowerCase()} cannot be empty`;
};

export const validateProfileNames = (name: string, value: string) => {
  if (/^\d+$/.test(value)) {
    return `The ${name.toLowerCase()} cannot contain only numbers`;
  }
  if (/^[^a-zA-Z0-9]+$/.test(value)) {
    return `The ${name.toLowerCase()} cannot contain only special characters`;
  }
  return value && value.toString().trim() ? '' : `The ${name.toLowerCase()} cannot be empty`;
};

export const validatePackagePrice = (name: string, value: string) => {
  if (/[a-zA-Z]/.test(value)) {
    return `The ${name.toLowerCase()} cannot include letters`;
  }
  if (/[!@#$%^&*()\-=_+{}[\]|\\:;"'<>,.?/~`]/.test(value)) {
    return `The ${name.toLowerCase()} cannot include special characters`;
  }
  return value.trim() ? '' : `The ${name.toLowerCase()} cannot be empty`;
};

export const validateStringWithNumbersAndSpecialChars = (name: string, value: string) => {
  if (!value || !value.trim()) {
    return name.toLowerCase() === 'bio' || name.toLowerCase() === 'address'
      ? ''
      : `The ${name.toLowerCase()} cannot be empty`;
  }
  if (/^\d+$/.test(value)) {
    return `The ${name.toLowerCase()} cannot contain only numbers`;
  }
  if (/^[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]*$/.test(value)) {
    return `The ${name.toLowerCase()} cannot contain only special characters`;
  }
  return ''; // Validation passed
};

export const validateDobNotToday = (name: string, value: string) => {
  if (!value) {
    return `The DOB cannot be empty`;
  }
  const today = new Date();
  const inputDate = new Date(value);
  if (inputDate.toDateString() === today.toDateString()) {
    return `The DOB cannot be the same as today's date`;
  }
  return ''; // Validation passed
};

export const validateLessons = (name: string, value: any[]) => {
  return value?.length ? '' : `The ${name.toLowerCase()} cannot be empty`;
};

export const validateUnits = (name: string, value: any[]) => {
  return value?.length ? '' : `The ${name.toLowerCase()} cannot be empty`;
};

export const validateStringUserRole = (name: string, value: string) => {
  return value ? '' : 'Select your user role';
};

export const validateStringLength = (name: string, value: string, min: number, max: number) => {
  if (value.trim().length <= min && min !== -1) {
    return `${name} must have more than ${min} characters`;
  } else if (value.trim().length >= max) {
    return `${name} must have less than ${max} characters`;
  }
  return '';
};

export const validateMaxLength = (name: string, value: string, maxLength: number = 100) => {
  // console.log(
  //   'INSIDE VALIDATE MAX LENGTH ',
  //   'name ',
  //   name,
  //   ' ',
  //   'value ',
  //   value,
  //   ' ',
  //   'maxLength ',
  //   maxLength
  // );

  return value.trim().length > maxLength
    ? `${name} should be less than ${maxLength} characters`
    : '';
};

export const validateSpecialCharacter = (name: string, value: string) => {
  var format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;

  if (format.test(value)) {
    return `${name} cannot have special characters`;
  } else {
    return '';
  }
};

export const validateOnlyUnderscoreAndHypenAllowed = (name: string, value: string) => {
  var format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;
  let flag = true;

  for (let i = 0; i < value.length; i++) {
    if (flag && format.test(value[i]) && !(value[i] === '-' || value[i] === '_')) {
      return `${name} can only include letters, numbers and following special characters "_" (Underscore) and "-" (Dash)`;
    }
  }

  return '';
};

export const validateSubjectNameAndLesson = (name: string, value: string) => {
  let error: string = '';

  if (value.length > 40) {
    error = `${name} cannot be created than 40 characters`;
  }

  if (containsOnlyNumbers(value)) {
    error = `The ${name.toLowerCase()} cannot contain only numbers`;
  }

  return error;
};

export const validateMinLength = (name: string, value: string, minLength: number = 3) => {
  const error =
    value.trim().length < minLength
      ? `The ${name.toLowerCase()} should not be less than ${minLength} characters`
      : '';

  // console.log('validateMinLength error ', error);
  return error;
};

export const validateMinValue = (name: string, value: string, minValue: number = 1) => {
  let error: string;

  if (!isNaN(+value)) {
    error = +value < minValue ? `${name} should be greater than or equal to ${minValue}` : '';
  } else {
    error = 'Not a number';
  }

  return error;
};

export const validateMinTotalAmount = (name: string, value: string) => {
  let error: string;

  if (!isNaN(+value)) {
    error = +value < 1 ? `${name} should be greater than or equal to 1` : '';
  } else {
    error = 'Not a number';
  }

  return error;
};

export const validateMaxForMarks = (name: string, value: string) => {
  let error: string;

  if (!isNaN(+value)) {
    error = +value > 1000 ? `${name} should be less than or equal to 1000` : '';
  } else {
    error = 'Not a number';
  }

  return error;
};

export const validatePhoneNumber = (name: string, value: string) => {
  let error: string = '';
  let isValid = false;

  if (value.substring(0, 1) !== '+') {
    if (value.length === 10 && isNumeric(value)) {
      isValid = true;
    }
  } else {
    if (value.length === 12 && value.substring(0, 3) === '+94') {
      isValid = true;
    }
  }

  if (!isValid) {
    if (value.trim().length === 0) {
      error = `The ${name.toLowerCase()} number cannot be empty`;
    } else {
      error = `Incorrect ${name.toLowerCase()} number format`;
    }
  }

  return error;
};

export const validateMinForMarks = (name: string, value: string) => {
  let error: string;

  if (!isNaN(+value)) {
    error = +value < 1 ? `${name} should be greater than or equal to 1` : '';
  } else {
    error = 'Not a number';
  }

  return error;
};

export const validateMinimumTime = (
  name: string,
  value: { hours: string; minutes: string },
  minimum: number
) => {
  const hours = value.hours || '00';
  const minutes = value.minutes || '00';

  if (+hours) {
    return '';
  } else if (+minutes < minimum) {
    return `${name} should be greater than ${minimum} minutes`;
  } else {
    return '';
  }
};

export const validateAnswerBody = (answerArray: any[]) => {
  const isEmptyBodyPresent = answerArray.filter(a => !a.answerText?.length).length;

  return isEmptyBodyPresent ? 'Answers cannot be empty' : '';
};

export const validateAnswerCount = (answerArray: any[]) => {
  return answerArray.length < 2 ? `There should be at least 2 answers` : '';
};

export const atLeastOneCorrectAnswer = (answerArray: any[]) => {
  const noOfCorrectAns = answerArray.filter(a => a.isCorrect).length;

  return noOfCorrectAns < 1 ? 'There should be at least one correct answer' : '';
};

export const validateTermAndConditions = (name: string, value: string) => {
  return value ? '' : `Check ${name}`;
};

export const validateEmail = (name: string, value: string) => {
  const regexp: RegExp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  if (value?.trim().length < 1) {
    name = name.charAt(0).toUpperCase() + name.substring(1);

    return `The ${name.toLowerCase()} cannot be empty`;
  }

  if (!regexp.test(value)) {
    return `Invalid ${name} address`;
  }

  return '';
};

// export const validatePassword = (name: string, password: string = '') => {
//   let message: any = '';
//   let options: string[] = [];
//   const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@!"#(*.,/\\:;<?`|{~}[\]>)'$%^&+\-_=]).{8,}$/gm;
//   const regexp: RegExp = new RegExp(regex);

//   const latter = /^(?=.*[a-zA-Z])/gm;
//   const numericReg = /^(?=.*[0-9])/gm;
//   const specialCharacterReg = /^(?=.*[@!"#(*.,/\\:;<?`|{~}[\]>)'$%^&+\-_=])/gm;
//   const lengthReg = /^(?=.{8,})/gm;

//   if (password.trim().length < 1) {
//     message = `${name} cannot be empty`;
//   } else {
//     if (!regexp.test(password)) {
//       message = `Please add one of the following things to make your ${name}
//               stronger: `;
//       if (!latter.test(password)) options.push('Letters');
//       if (!numericReg.test(password)) options.push('Numbers');
//       if (!specialCharacterReg.test(password)) options.push('Special characters');
//       if (!lengthReg.test(password)) options.push('8 Characters');
//     }

//     options.forEach((text) => {
//       message = message + text + ', ';
//     });
//   }
//   return message.includes('empty') ? message : message.substring(0, message.length - 2);
// };

const containsUpperCase = (value: string) => {
  var result = value.match(/[a-zA-Z]/g);

  let containsUppercase = false;

  result?.forEach(char => {
    if (char === char.toUpperCase()) {
      containsUppercase = true;
    }
  });

  return containsUppercase;
};

const containsNumber = (value: string) => {
  const regex = /\d/;

  return regex.test(value);
};

const containsSpecialsCharacters = (value: string) => {
  const regex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

  return regex.test(value);
};

export const validatePassword = (name: string, password: string = '') => {
  let message: any = '';

  if (
    !(
      containsNumber(password) &&
      containsSpecialsCharacters(password) &&
      containsUpperCase(password) &&
      password.length >= 8
    )
  ) {
    message = `Your password must have 8 or more characters, at least one uppercase letter, one number and one special character.`;
  }

  return message;
};

export const validateUnitDescriptionWordCount = (name: string, value: string) => {
  let error: string = '';
  let count: number = 0;

  count = value.trim().split(/\s+/).length;

  if (count > 40) {
    error = `${name} cannot be greater than 40 words`;
  }

  return error;
};

const containsOnlyNumbers = (value: string) => {
  const regex = /^(\d+-)*(\d+)$/;
  return regex.test(value.trim());
};

export const validateStringSpaces = (name: string, value: string) => {
  let error: string = '';
  let removeTagsInValue = value
    .replace(/<[^>]+>/g, '')
    .replace(/&nbsp;/g, '')
    .trim();

  if (removeTagsInValue.length === 0) {
    error = `The ${name.toLowerCase()} cannot be empty`;
  }

  return error;
};

export const validateDescriptionStringSpaces = (name: string, value: string) => {
  let error: string = '';
  let lowercaseName: string = name.toLowerCase();

  let removeTagsInValue = value
    .replace(/<[^>]+>/g, '')
    .replace(/&nbsp;/g, '')
    .trim();

  if (removeTagsInValue.length === 0) {
    error = `The ${lowercaseName} cannot be empty`;
  }

  return error;
};

export const isContainOnlyNumbers = (name: string, value: string) => {
  let error: string = '';
  let removeTagsInValue = value
    .replace(/<[^>]+>/g, '')
    .replace(/&nbsp;/g, '')
    .replace(/ /g, '')
    .trim();

  if (containsOnlyNumbers(removeTagsInValue)) {
    error = `The ${name.toLowerCase()} cannot contain only numbers`;
  }

  return error;
};
