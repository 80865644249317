import React, { FC } from 'react';
import text from '../assets/skillsurf-logo.png';
import './MainLayout.scss';

import { Col, Container, Row } from 'react-bootstrap';

interface Props {
  rightHeightClassName?: string;
}

const MainLayout: FC<Props> = (props) => {
  return (
    <Container fluid className="mainlayout pb-3 pb-lg-0 ">
      <Row className="no-gutters align-items-center h-100 d-block d-lg-flex">
        <Col md={12} lg={6} className="mainlayout__left-section">
          <div className="mainlayout__scrapbook-title">
            <img src={text} alt="scrapbook" />
          </div>
          <div className="mainlayout__scrabook-image d-none d-lg-block">
            <div></div>
          </div>
        </Col>
        <Col
          md={12}
          lg={6}
          className={`d-flex justify-content-center d-lg-block mainlayout__right-section ${props.rightHeightClassName}`}
        >
          {props.children}
        </Col>
      </Row>
    </Container>
  );
};

export default MainLayout;
