import React, { useState } from 'react';
import AccountBody from '../../components/AccountBody';
import AccountHeader from '../../components/AccountHeader';
import AccountSecondaryNav from '../../components/AccountSecondaryNav';
import AlertPopup from '../../components/AlertPopup';
import PrimaryNav from '../../components/PrimaryNav';
import { ACCOUNT_TAB, SCREEN } from '../../constants';
import { useAppRestrictions } from '../../context/RestrictionsContext';
import useSessionStorage from '../../hooks/useSessionStorage';
import LayoutNew from '../../layouts/LayoutNew';

const Account = () => {
  const [state, setState] = useSessionStorage(SCREEN.ACCOUNT, {
    currentTab: ACCOUNT_TAB.PROFILE_INFO,
  });

  const changeCurrentTabHandler = (tab: string) => {
    setState({ ...state, currentTab: tab });
  };

  const secondaryNav = (
    <AccountSecondaryNav
      onCurrentTabChange={changeCurrentTabHandler}
      currentTab={state.currentTab}
      screen={SCREEN.ACCOUNT}
    />
  );

  const appRestrictions = useAppRestrictions();

  const fireRestrictionFunction = () => {
    if (appRestrictions.isPhoneNumberPresent) {
      setShow(true);
    }
  };

  const header = (
    <AccountHeader
      restrictions={{
        value: appRestrictions.isPhoneNumberPresent,
        onClick: fireRestrictionFunction,
      }}
    />
  );
  const primaryNav = (
    <PrimaryNav
      restrictions={{
        value: appRestrictions.isPhoneNumberPresent,
        onClick: fireRestrictionFunction,
      }}
    />
  );
  const body = <AccountBody />;

  const [show, setShow] = useState(false);

  return (
    <>
      <LayoutNew secondaryNav={secondaryNav} header={header} primaryNav={primaryNav} body={body} />
      <AlertPopup
        message="Please enter your phone number"
        header="Attention"
        isShow={show}
        onOk={() => {
          setShow(false);
        }}
        onClose={() => {
          setShow(false);
        }}
        type="OK"
      />
    </>
  );
};

export default Account;
