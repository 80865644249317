import { sort } from 'fast-sort';
import firebase from 'firebase';
import moment from 'moment';
import {
  FaClock,
  FaExclamation,
  FaExclamationTriangle,
  FaRegCheckCircle,
  FaRocket,
} from 'react-icons/fa';
import { MdAssignmentInd } from 'react-icons/md';
import { titleCase } from 'title-case';
import truncate from 'truncate';

import { SUBJECT_SECONDARY_NAV_MAIN_ITEMS } from '../constants';
import { PapersContext } from '../context/PapersContext';
import { SecondaryNavigationData } from '../context/subjectsUI';
import { ExamToDoDTO } from '../dtos/ExamToDoDTO';
import { PaperDTO } from '../dtos/PaperDTO';
import { StructEssayDataPaperStatus } from '../dtos/StructEssayData';
import { EXAM_TYPE_TABS, StatusOfPaper } from '../enums';
import { ExamType } from '../enums/ExamType';
import { PaperTypes } from '../enums/paperTypes';
import { EXAM_CREATION_STATE, FilterBucket, SUBJECT_DIRECTIVE_STATE } from '../interfaces';
import { UserRole } from '../interfaces/UserRole';
import { CommonAccessCode } from '../models/CommonAccessCode';
import { Exam } from '../models/Exam';
import { Lesson } from '../models/Lesson';
import { Package } from '../models/Package';
import { Paper } from '../models/Paper';
import { MCQ, TFQuestion } from '../models/Question';
import { StructEssayPaper } from '../models/StructEssayPaper';
import { Subject } from '../models/Subject';
import { Unit } from '../models/Unit';
import { UserFirestore } from '../models/UserFirestore';
import { SubBulkAccessCode } from '../models/BulkAccessCode';

export const getSelectedSubjectSecondaryNav = (
  data: SecondaryNavigationData[] | null | undefined
) => {
  const { LESSONS, MODAL_PAPERS, ANNOUCEMENTS, SETTINGS } = SUBJECT_SECONDARY_NAV_MAIN_ITEMS;

  const isLessonSubTab = data && data[0]?.currentTab === LESSONS;

  const isModalPapers = data && data[0]?.currentTab === MODAL_PAPERS;

  const isAnnoucements = data && data[0]?.currentTab === ANNOUCEMENTS;

  if (isLessonSubTab) {
    return LESSONS;
  } else if (isModalPapers) {
    return MODAL_PAPERS;
  } else if (isAnnoucements) {
    return ANNOUCEMENTS;
  } else {
    return SETTINGS;
  }
};

export const changeTabHandelr = (
  tab: EXAM_TYPE_TABS,
  setData: React.Dispatch<React.SetStateAction<SUBJECT_DIRECTIVE_STATE>>
) => {
  setData((ps) => ({
    ...ps,
    tab: tab,
    isGraded: false,
  }));
};

export const getSubmissionTypeArray = (
  submissionType:
    | {
        MCQ: boolean;
        TrueFalse: boolean;
        File: boolean;
      }
    | undefined
) => {
  const submissionTypeArray = [];

  if (submissionType?.MCQ) {
    submissionTypeArray.push('MCQ');
  }

  if (submissionType?.TrueFalse) {
    submissionTypeArray.push('TF');
  }

  if (submissionType?.File) {
    submissionTypeArray.push('File');
  }

  return submissionTypeArray;
};

export const getExamSubmissionStats = (exam: Exam) => {
  let startedUsers = exam.usersWhoStartedExamCount || 0;
  let completedUsers = exam.completedUsersCount || 0;

  let sp = 0;
  let nsp = 0;

  if (completedUsers > startedUsers) {
    startedUsers = completedUsers;
  }

  if (completedUsers) {
    sp = Math.round((startedUsers / completedUsers) * 100);
    nsp = 100 - sp;
  }

  return {
    submittedPercentage: sp,
    notSubmittedPercentage: nsp,
    completedUsers: completedUsers,
    startedUsers: startedUsers,
  };
};

export const filterExams = (
  array: Exam[],
  hideGraded: boolean | undefined,
  applyFilters: boolean | undefined,
  filterBucket?: FilterBucket,
  searchString?: string
) => {
  let resultArray: Exam[] = [...array];

  if (!applyFilters) {
    return resultArray
      .filter((ex) => ex.examTitle.toUpperCase().indexOf(searchString || ''.toUpperCase()) > -1)
      .filter((ex) => !(hideGraded && ex.completedUsersCount));
  } else {
    return resultArray
      .filter((ex) =>
        !searchString ? true : ex.examTitle.toUpperCase().indexOf(searchString.toUpperCase()) > -1
      )
      .filter((ex) => !(hideGraded && ex.completedUsersCount))
      .filter((ex) =>
        filterBucket?.lessonId ? ex.lessonIds?.includes(filterBucket?.lessonId) : true
      )
      .filter((ex) => (filterBucket?.unitId ? ex.unitIds?.includes(filterBucket?.unitId) : true));
  }
};

export const getButtonTextAndTab = (tab: EXAM_TYPE_TABS | undefined) => {
  return tab === EXAM_TYPE_TABS.LIVE
    ? { text: 'Create Live Exam', tab: EXAM_TYPE_TABS.LIVE }
    : tab === EXAM_TYPE_TABS.PRACTISE
    ? { text: 'Create Practice Exam', tab: EXAM_TYPE_TABS.PRACTISE }
    : { text: 'Create Extra Exam', tab: EXAM_TYPE_TABS.EXTRA };
};

export const getModifiedSubjectAndSubjectOption = (subjectId: string = '', array: Subject[]) => {
  const subject = array.find((sub) => sub.id === subjectId);

  const mappedSubjects = array.map((sub) => ({
    id: sub.id || '',
    displayValue: sub.name || '',
  }));

  const selectedSubject = {
    id: subject?.id || '',
    displayValue: subject?.name || '',
  };

  const selectedGrade = {
    id: subject?.grade || '',
    displayValue: subject?.grade || '',
  };

  return { mappedSubjects, selectedSubject, selectedGrade };
};

export const getModifiedLessonAndSubjectOptions = (
  subjectId: string = '',
  array: Lesson[],
  lessonId?: string
) => {
  const lesson = array.find((less) => less.id === lessonId);

  const mappedLessons = array
    .filter((less) => less.subjectId === subjectId)
    .map((sub) => ({
      id: sub.id || '',
      displayValue: sub.name || '',
    }));

  const selectedLesson = {
    id: lesson?.id || '',
    displayValue: lesson?.name || '',
  };

  return { mappedLessons, selectedLesson };
};

export const getExamTypeName = (tab: EXAM_TYPE_TABS | null | undefined) => {
  const { EXTRA, LIVE, PRACTISE } = EXAM_TYPE_TABS;

  if (tab === LIVE) {
    return 'Create Live Exam';
  } else if (tab === PRACTISE) {
    return 'Create Practice Exam';
  } else if (tab === EXTRA) {
    return 'Create Extra Exam';
  }
  return '';
};

export const isValidSubjectForUser = (input: {
  user: UserFirestore | null;
  subjectId: string;
  subjects: Subject[];
}) => {
  const subject = input.subjects.find((sub) => sub.id === input.subjectId);

  const isSubCreatedByTheCurrentUser = subject?.createdBy === input.user?.uid;
  const isSubAssignedToCurrentUser = subject?.assignedUsers?.includes(input.user?.uid || '');

  const isAdmin = input.user?.userRole?.isAdmin;
  const isInstructor = input.user?.userRole?.isInstructor;
  const isTA = input.user?.userRole?.isTeachingAssistant;
  const isStudent = input.user?.userRole?.isStudent;

  if (isAdmin && subject) {
    return true;
  } else if (isInstructor && (isSubCreatedByTheCurrentUser || isSubAssignedToCurrentUser)) {
    return true;
  } else if (isTA && isSubAssignedToCurrentUser) {
    return true;
  } else if (isStudent && isSubAssignedToCurrentUser) {
    return true;
  }
  return false;
};

export const isValidLessonForUser = (input: {
  subjectId: string;
  lessonId: string;
  lessons: Lesson[];
}) => {
  const lesson = input.lessons.find((lesson) => lesson.id === input.lessonId);

  const isValidLesson = input.subjectId === lesson?.subjectId;

  return { isValidLesson, lessonName: lesson?.name || '' };
};

export const setInitialDatesAndTimesForLiveExams = (
  state: EXAM_CREATION_STATE,
  setState: React.Dispatch<React.SetStateAction<EXAM_CREATION_STATE>>
) => {
  let tHours = 0;
  let tMins = 0;
  let totalTimeInMinsOfAllPapers = 0;

  state.papers.value.forEach((p) => {
    tHours = tHours + +p.allocatedTime.split(':')[0];
    tMins = tMins + +p.allocatedTime.split(':')[1];
  });

  totalTimeInMinsOfAllPapers = tHours * 60 + tMins;

  const dateToday = new Date();
  dateToday.setHours(23);
  dateToday.setMinutes(59);
  dateToday.setSeconds(0);
  dateToday.setMilliseconds(0);

  const fiveMinutesInMs = 5 * 60000;

  const finalTimeMiliseconds = totalTimeInMinsOfAllPapers * 60_000;

  const currentTimeAddedFiveMinutes = new Date(new Date().getTime() + fiveMinutesInMs);

  const BEGINTIME = state.id ? state.beginTime : currentTimeAddedFiveMinutes;

  const finalTime = new Date(+BEGINTIME + +finalTimeMiliseconds);

  if (+finalTime > +dateToday) {
    finalTime.setHours(23);
    finalTime.setMinutes(59);
    finalTime.setSeconds(0);
    finalTime.setMilliseconds(0);
  }

  const startHours = BEGINTIME.getHours();
  const startMins = BEGINTIME.getMinutes();
  const endHours = new Date(+finalTime).getHours();
  const endMins = new Date(+finalTime).getMinutes();

  setState((ps) => {
    return {
      ...ps,
      startTime: {
        ...ps.startTime,
        value: {
          hours: appendZeroInFront(startHours + ''),
          minutes: appendZeroInFront(startMins + ''),
        },
      },
      endTime: {
        ...ps.endTime,
        value: {
          hours: appendZeroInFront(endHours + ''),
          minutes: appendZeroInFront(endMins + ''),
        },
      },
    };
  });
};

export const getSubmissionTypes = (papers: any[]) => {
  const subTypeMCQ = !!papers.find((val) => val.paperType === PaperTypes.MCQ);
  const subTypeTF = !!papers.find((val) => val.paperType === PaperTypes.TRUEFALSE);

  const subTypeSTR = !!papers.find((val) => val.paperType === PaperTypes.STRUCTURED);

  const subTypeESY = !!papers.find((val) => val.paperType === PaperTypes.ESSAY);

  return { subTypeMCQ, subTypeTF, subTypeSTR, subTypeESY };
};

export const getExamTypeQueryParam = (examType: ExamType | undefined) => {
  return examType === ExamType.EXTRA_EXAM
    ? '?name=extra'
    : examType === ExamType.PRACTICE_EXAM
    ? '?name=practise'
    : '?name=live';
};

export const hasUserInitiatedExam = (
  examDetailsState: ExamToDoDTO | null,
  user: UserFirestore | null
) => {
  return examDetailsState?.usersExamState?.find((val) => val.userId === user?.uid);
};

export const getHoursAndMinutes = (inputTime: string) => {
  const hours = inputTime.split(':')[0];
  const minutes = inputTime.split(':')[1];

  return [+hours, +minutes];
};

export const getExaminationStartDate = (examDetailsState: ExamToDoDTO | null) => {
  let date: Date = new Date();

  date = convertTimeStampToDate(examDetailsState?.startDate);
  const startTimeHoursAndMinutes = getHoursAndMinutes(examDetailsState?.startTime || '00:00');

  date.setHours(startTimeHoursAndMinutes[0]);
  date.setMinutes(startTimeHoursAndMinutes[1]);
  date.setSeconds(0);

  return date;
};

export const getUserExamStartTime = (exam: ExamToDoDTO, userId: string | undefined) => {
  const time = exam?.usersExamState?.find((value) => value.userId === userId)?.examStartedTime;

  return time ? new Date(time) : null;
};

export const getDateToSave = (date: Date, time: string) => {
  const hours = parseInt(time.split(':')[0]);
  const minutes = parseInt(time.split(':')[1]);

  date.setHours(hours);
  date.setMinutes(minutes);

  return date;
};

export const convertUTCDateToLocalDate = (date: string | undefined) => {
  // const inputDate = date ? new Date(date) : new Date();
  // const localDate = new Date(inputDate.getTime() - inputDate.getTimezoneOffset() * 60 * 1000);
  // return localDate;

  return date ? new Date(date) : new Date();
};

export const getDaysHoursMinutesSeconds = (beginTime: Date, finishTime: Date) => {
  // get total seconds between the times
  var delta = Math.abs(+finishTime - +beginTime) / 1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  var seconds = delta % 60;

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

//LEGACY DATA SUPPORT
export const getExamStartScreenDetails = (examDetailsState: ExamToDoDTO | null) => {
  const examType = examDetailsState?.examType;

  if (
    examType !== ExamType.EXTRA_EXAM &&
    examDetailsState?.beginTime &&
    examDetailsState?.finishTime
  ) {
    const startDate = moment(convertUTCDateToLocalDate(examDetailsState.beginTime)).format(
      'MM / DD / YYYY'
    );
    const startDateString = convertUTCDateToLocalDate(examDetailsState.beginTime);
    const startTime = moment(examDetailsState.beginTime).format('LT');
    const endDate = moment(convertUTCDateToLocalDate(examDetailsState.finishTime)).format(
      'MM / DD / YYYY'
    );
    const endDateString = convertUTCDateToLocalDate(examDetailsState.finishTime);
    const endTime = moment(examDetailsState.finishTime).format('LT');

    const duration = getDaysHoursMinutesSeconds(startDateString, endDateString);

    return {
      startDate,
      startTime,
      endDate,
      endTime,
      duration,
    };
  } else if (examType !== ExamType.EXTRA_EXAM) {
    return {
      startDate: moment(convertTimeStampToDate(examDetailsState?.startDate)).format(
        'MM / DD / YYYY'
      ),
      startTime: `${moment(convertStringToDate(examDetailsState?.startTime || '00:00')).format(
        'LT'
      )} `,
      endDate: moment(
        convertTimeStampToDate(examDetailsState?.endDate || examDetailsState?.startDate)
      ).format('MM / DD / YYYY'),
      endTime: `${moment(convertStringToDate(examDetailsState?.endTime || '00:00')).format('LT')} `,
      duration: getTimeDifferenceInDDHHMMSSOfTwoDates(
        examDetailsState?.startDate,
        examDetailsState?.startTime,
        examDetailsState?.endDate,
        examDetailsState?.endTime
      ),
    };
  } else {
    return {
      startDate: moment(new Date()).format('MM / DD / YYYY'),
      startTime: '00:00',
      endDate: moment(new Date()).format('MM / DD / YYYY'),
      endTime: '00:00',
      duration: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  }
};

export const EXAM_DASH_GRADE_SETTINGS_TA_SCREEN = {
  DASH: 'dashboard',
  GRADE: 'grades',
  PAYMENTS: 'payments',
  SETTINGS: 'settings',
  TA: 'ta',
};

export const validationResult = (
  state: any,
  ignoreField?: string[]
): { state: any; formValidity: string } => {
  let formValidity = '';
  for (const item of Object.entries(state)) {
    const val: any = item[1];
    const name: string = item[0];

    if (val?.validations && val?.validations.length && !ignoreField?.includes(name)) {
      let foundError = 'NO-ERROR';

      for (const validation of val.validations) {
        if (foundError === 'NO-ERROR') {
          if (validation.name === 'validateMaxLength') {
            // console.log('validator name - ', validation.name);
            // console.log('label ', val.label);
            // console.log('value ', val.value);
            // console.log('max ', val.max);
            foundError = validation(val.label, val.value, val.max);
          } else if (validation.name === 'validateMinLength') {
            // console.log('validator name - ', validation.name);
            // console.log('label ', val.label);
            // console.log('value ', val.value);
            // console.log('min ', val.min);
            foundError = validation(val.label, val.value, val.min);
          } else if (validation.name === 'validateMinValue') {
            foundError = validation(val.label, val.value, val.min);
          } else if (validation.name === 'validateMaxValue') {
            foundError = validation(val.label, val.value, val.max);
          } else if (validation.name === 'validateMinimumTime') {
            foundError = validation(val.label, val.value, val.min);
          } else if (val?.type === 'dropdown') {
            foundError = validation(val.label, val.value.id);
            foundError = validation(val.label, val.value.displayValue);
          } else if (validation.name === 'validateMaxForMarks') {
            foundError = validation(val.label, val.value);
          } else if (validation.name === 'validatePhoneNumber') {
            foundError = validation(val.label, val.value);
          } else if (validation.name === 'validateMinForMarks') {
            foundError = validation(val.label, val.value);
          } else if (validation.name === 'validateMinTotalAmount') {
            foundError = validation(val.label, val.value);
          } else if (val?.type === 'answers') {
            const array = val.value;
            foundError = validation(array);
          } else if (validation.name === 'validateLessons') {
            foundError = validation(val.label, val.value);
          } else if (validation.name === 'validateUnits') {
            foundError = validation(val.label, val.value);
          } else {
            foundError = validation(val.label, val.value);
          }

          if (!foundError) {
            foundError = 'NO-ERROR';
          }

          state = {
            ...state,
            [name]: { ...val, error: foundError },
          };

          if (!formValidity) {
            if (foundError === 'NO-ERROR') {
              formValidity = '';
            } else {
              formValidity = foundError;
            }
          }
        }
      }
    } else {
      state = {
        ...state,
        [name]: { ...val, error: 'NO-ERROR' },
      };
    }
  }

  return { state, formValidity };
};

export const convertToHypens = (value: string) => {
  return value.replace(/\s+/g, '-');
};

export const convertToTitleCaseAndRemoveHypens = (value: string) => {
  return titleCase(value.replace(/-/g, ' '));
};
export const removeHypens = (value: string) => {
  return value.replace(/-/g, ' ');
};

export const isPathMatch = (fullPath: string, currentTab: string) => {
  const array = fullPath.split('/');
  let match = false;

  array.forEach((item) => {
    if (convertToHypens(item) === currentTab) match = true;
  });

  return match;
};

interface inputArrayType {
  text: string;
  active: boolean;
  subMenuItems: any[];
}

export const getMenuItems = (
  array: inputArrayType[],
  currentTab: string,
  onTabChange: (tab: string) => void,
  screen: string,
  onSubTabChange?: (subTab: string) => void,
  currentSubTab?: string
): inputArrayType[] => {
  const updatedMenuItems = array.map((item) => {
    item.subMenuItems.map((subMenuItem) => {
      if (subMenuItem.text === currentSubTab) {
        subMenuItem.active = true;
      } else {
        subMenuItem.active = false;
      }
      return subMenuItem;
    });

    if (item.text === currentTab) {
      item.active = true;
    } else {
      item.active = false;
    }

    return item;
  });

  // const isAnyActive = !!updatedMenuItems.filter((item) => item.active).length;

  // if (!isAnyActive) {
  //   if (screen === 'subject') {
  //     onTabChange('Lessons');
  //   } else if (screen === 'unit') {
  //     onTabChange('Units');
  //   }
  // }

  return updatedMenuItems;
};

export const removeHypensAndConvertToBlocks = (str: string) => {
  var i,
    frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
};

export const splitArrayIntoChunksOfLen = (arr: any, len: number): any => {
  // tslint:disable-next-line:prefer-const
  let chunks = [],
    i = 0,
    // tslint:disable-next-line:prefer-const
    n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
};

export function shuffle<T>(array: T[]): T[] {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export function arrayDiff(a1: any[], a2: any[]) {
  var a = [],
    diff = [];
  for (let i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }
  for (let j = 0; j < a2.length; j++) {
    if (a[a2[j]]) {
      delete a[a2[j]];
    } else {
      a[a2[j]] = true;
    }
  }
  for (var k in a) {
    diff.push(k);
  }
  return diff;
}

export const convertTimeStampToDate = (timeStamp: firebase.firestore.Timestamp | undefined) => {
  if (timeStamp) {
    // return timeStamp.toDate();
    return new Date((timeStamp as any)._seconds * 1000 || new Date());
  }

  return new Date();
};

export const convertTimeStampToDateVersionTwo = (
  timeStamp: firebase.firestore.Timestamp | undefined
) => {
  if (timeStamp) {
    return timeStamp.toDate();
    // return new Date((timeStamp as any)._seconds * 1000 || new Date());
  }

  return new Date();
};

export const convertArrayToDDHHMMSS = (input: number[]): string => {
  const days = input[0] < 10 ? '0' + input[0] : input[0] + '';
  const hours = input[1] < 10 ? '0' + input[1] : input[1] + '';
  const minutes = input[2] < 10 ? '0' + input[2] : input[2] + '';
  const seconds = input[3] < 10 ? '0' + input[3] : input[3] + '';

  if (!input[0] && !input[1]) {
    return minutes + ':' + seconds;
  }

  if (!input[0]) {
    return hours + ':' + minutes + ':' + seconds;
  }

  return days + ':' + hours + ':' + minutes + ':' + seconds;
};

export const convertStringToTwelveHoursClock = (inputDate: string) => {
  const hours = inputDate.split(':')[0];
  const minutes = inputDate.split(':')[1];

  if (+hours < 12) {
    return hours + ':' + minutes + ' AM';
  }

  if (+hours === 12) {
    return hours + ':' + minutes + ' PM';
  }

  if (+hours > 12) {
    return +hours - 12 + ':' + minutes + ' PM';
  }
};

export const convertStringToDate = (inputDate: string) => {
  const hours = inputDate.split(':')[0];
  const minutes = inputDate.split(':')[1];

  let date = new Date();

  date.setHours(+hours);
  date.setMinutes(+minutes);
  date.setSeconds(0);

  return date;
};

export const decodeHtml = (html: string) => {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export const getDuration = (startTime: string, endTime: string) => {
  const start = startTime.split(':');
  const end = endTime.split(':');

  const startDate = new Date();
  const endDate = new Date();

  startDate.setHours(+start[0]);
  startDate.setMinutes(+start[1]);
  startDate.setSeconds(0);

  endDate.setHours(+end[0]);
  endDate.setMinutes(+end[1]);
  endDate.setSeconds(0);

  const diff = endDate.getTime() - startDate.getTime();
  const diffInSeconds = diff / 1000;
  const diffInMinutes = diffInSeconds / 60;
  const diffInHours = diffInMinutes / 60;
  return diffInHours;
};

export const constructDateWithTime = (
  timeStamp: firebase.firestore.Timestamp | undefined,
  time: string
) => {
  const end = time.split(':');
  const date = convertTimeStampToDate(timeStamp);

  date.setHours(+end[0]);
  date.setMinutes(+end[1]);
  date.setSeconds(0);

  return date;
};

export const constructDateWithTimeVersionTwo = (
  timeStamp: firebase.firestore.Timestamp | undefined,
  time: string
) => {
  const end = time.split(':');
  const date = convertTimeStampToDateVersionTwo(timeStamp);

  date.setHours(+end[0]);
  date.setMinutes(+end[1]);
  date.setSeconds(0);

  return date;
};

export const getTimeDifferenceInDDHHMMSSOfTwoDates = (
  startDateTS: firebase.firestore.Timestamp | undefined,
  startTime: string | undefined,
  endDateTS: firebase.firestore.Timestamp | undefined,
  endTime: string | undefined
) => {
  const start = startTime ? startTime?.split(':') : '00:00';
  const end = endTime ? endTime.split(':') : '00:00';

  const startDate = convertTimeStampToDate(startDateTS);
  const endDate = convertTimeStampToDate(endDateTS || startDateTS);

  startDate.setHours(+start[0]);
  startDate.setMinutes(+start[1]);
  startDate.setSeconds(0);

  endDate.setHours(+end[0]);
  endDate.setMinutes(+end[1]);
  endDate.setSeconds(0);

  const difference = +endDate - +startDate;

  return {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
  };
};

export const suffixAllocatedTime = (time: string) => {
  if (time.length === 5) {
    const hours = time.split(':')[0];
    const minutes = time.split(':')[1];
    return hours + 'h ' + minutes + 'm';
  }
  return '';
};

// export const differenceBetweenTimes = (startTime: string, endTime: string): number[] => {
//   const startHours = startTime.split(':')[0];
//   const startMinutes = startTime.split(':')[1];

//   const endHours = endTime.split(':')[0];
//   const endMinutes = endTime.split(':')[1];

//   const startDate = new Date();
//   const endDate = new Date();

//   startDate.setHours(+startHours);
//   startDate.setMinutes(+startMinutes);
//   startDate.setSeconds(0);

//   endDate.setHours(+endHours);
//   endDate.setMinutes(+endMinutes);
//   endDate.setSeconds(0);

//   const difference = +endDate - +startDate;

//   const resultingHours = Math.floor((difference / (1000 * 60 * 60)) % 24);
//   const resultingMinutes = Math.floor((difference / 1000 / 60) % 60);

//   return [resultingHours, resultingMinutes];
// };

export const allocatedTimeHoursAndMinutes = (allocatedTime: string): string[] => {
  const hours = allocatedTime.split(':')[0];
  const minutes = allocatedTime.split(':')[1];

  return [hours, minutes];
};

export const getAbbrivation = (val: PaperTypes | undefined) => {
  if (val === PaperTypes.MCQ) {
    return val;
  } else if (val === PaperTypes.TRUEFALSE) {
    return 'TF';
  } else if (val === PaperTypes.STRUCTURED) {
    return 'STR';
  } else if (val === PaperTypes.ESSAY) {
    return 'ESY';
  }
  return '';
};

export const getAbbrivationExam = (val: string | undefined) => {
  if (val === 'MCQ') {
    return val;
  } else if (val === 'T/F') {
    return 'TF';
  } else if (val === 'STRUCTURED') {
    return 'STR';
  } else if (val === 'ESSAY') {
    return 'ESY';
  }
  return '';
};

export const formatAllocatedTimeHoursAndMinutes = (str: string) => {
  if (!str) {
    return '00';
  } else if (str.length === 1) {
    return '0' + str;
  } else {
    return str;
  }
};

export function isNumeric(str: any) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export const sortSubjectsByNameUsingContext = (
  subjectFromContext: Subject[]
): { id: string; displayValue: string }[] => {
  let subjects: { id: string; displayValue: string }[] = [];

  const sortedSub = sort(subjectFromContext).asc((sub) => sub.name);

  sortedSub.forEach((subject) => {
    subjects.push({ id: subject.id || '', displayValue: subject.name });
  });

  return subjects;
};

export const sortPackagesByTitleUsingContext = (
  packageFromContext: Package[]
): { id: string; displayValue: string }[] => {
  let packages: { id: string; displayValue: string }[] = [];

  const sortedPkg = sort(packageFromContext).asc((pkg) => pkg.title);

  sortedPkg.forEach((packge) => {
    packages.push({ id: packge.id || '', displayValue: packge.title });
  });

  return packages;
};

export const sortLessonsByNameUsingContext = (
  lessonFromContext: Lesson[]
): { id: string; displayValue: string }[] => {
  let lessons: { id: string; displayValue: string }[] = [];

  const sortLess = sort(lessonFromContext).asc((less) => less.name);

  sortLess.forEach((lesson) => {
    lessons.push({ id: lesson.id || '', displayValue: lesson.name });
  });

  return lessons;
};

export const sortUnitsByNameUsingContext = (
  unitsFromContext: Unit[]
): { id: string; displayValue: string }[] => {
  let units: { id: string; displayValue: string }[] = [];

  const sortUnit = sort(unitsFromContext).asc((unit) => unit.name);

  sortUnit.forEach((unit) => {
    units.push({ id: unit.id || '', displayValue: unit.name });
  });

  return units;
};

export const sortSubjects = (subjectsFromContext: Subject[]) => {
  return sort(subjectsFromContext).asc((sub) => sub.name);
};

export const sortLessons = (lessonsFromContext: Lesson[]) => {
  return sort(lessonsFromContext).asc((les) => les.name);
};

export const sortSubjectsByName = (
  data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
): { id: string; displayValue: string }[] => {
  let subjectFromDB: Subject[] = [];
  let subjects: { id: string; displayValue: string }[] = [];

  data.forEach((doc) => {
    subjectFromDB.push({ id: doc.id, ...doc.data() } as Subject);
  });

  const sortedSub = sort(subjectFromDB).asc((sub) => sub.name);

  sortedSub.forEach((subject) => {
    subjects.push({ id: subject.id || '', displayValue: subject.name });
  });

  return subjects;
};

export const sortLessonsByName = (
  data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
): { id: string; displayValue: string }[] => {
  let lessonFromDB: Lesson[] = [];
  let lessons: { id: string; displayValue: string }[] = [];

  data.forEach((doc) => {
    lessonFromDB.push({ id: doc.id, ...doc.data() } as Lesson);
  });

  const sortedLess = sort(lessonFromDB).asc((sub) => sub.name);

  sortedLess.forEach((lesson) => {
    lessons.push({ id: lesson.id || '', displayValue: lesson.name });
  });

  return lessons;
};

export const sortUserByName = (
  data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>
): { id: string; displayValue: string }[] => {
  let userFromDB: UserFirestore[] = [];
  let users: { id: string; displayValue: string }[] = [];

  data.forEach((doc) => {
    userFromDB.push({ id: doc.id, ...doc.data() } as UserFirestore);
  });

  const sortedUser = sort(userFromDB).asc((sub) => sub.username);

  sortedUser.forEach((user) => {
    users.push({ id: user.uid, displayValue: user.username });
  });

  return users;
};

export const sortUsersFromUserName = (usersInput: UserFirestore[]): UserFirestore[] => {
  return sort(usersInput).asc((user) => user.username);
};

export const sortUsersFromUserNameDesc = (usersInput: UserFirestore[]): UserFirestore[] => {
  return sort(usersInput).desc((user) => user.username);
};

export const sortUsersFromUserRole = (usersInput: UserFirestore[]): UserFirestore[] => {
  const predicateTAs = (user: any) => {
    return user.userRole?.isTeachingAssistant;
  };

  const predicateInstructors = (user: any) => {
    return user.userRole?.isInstructor;
  };

  const teachingAssistants: UserFirestore[] = [...usersInput.filter(predicateTAs)];
  const instructors: UserFirestore[] = [...usersInput.filter(predicateInstructors)];

  const sortedUsersBasedOnUserRole: UserFirestore[] = [...teachingAssistants, ...instructors];

  return sortedUsersBasedOnUserRole;
};

export const sortUsersFromUserRoleDESC = (usersInput: UserFirestore[]): UserFirestore[] => {
  const predicateTAs = (user: any) => {
    return user.userRole?.isTeachingAssistant;
  };

  const predicateInstructors = (user: any) => {
    return user.userRole?.isInstructor;
  };

  const teachingAssistants: UserFirestore[] = [...usersInput.filter(predicateTAs)];
  const instructors: UserFirestore[] = [...usersInput.filter(predicateInstructors)];

  const sortedUsersBasedOnUserRoleDESC: UserFirestore[] = [...instructors, ...teachingAssistants];

  return sortedUsersBasedOnUserRoleDESC;
};

export const sortUsersFromUserStatus = (
  userInput: UserFirestore[],
  isUserStatusSorted: boolean
): UserFirestore[] => {
  let sortedUsersBasedOnUserStatus: UserFirestore[];
  const enabledUsers: UserFirestore[] = [...userInput.filter((user) => user.enabled)];
  const disabledUsers: UserFirestore[] = [...userInput.filter((user) => !user.enabled)];

  if (isUserStatusSorted) {
    sortedUsersBasedOnUserStatus = [...enabledUsers, ...disabledUsers];
  } else {
    sortedUsersBasedOnUserStatus = [...disabledUsers, ...enabledUsers];
  }

  return sortedUsersBasedOnUserStatus;
};

export const sortExamsByRank = (exams: ExamToDoDTO[], rankSorted: boolean) => {
  if (rankSorted) {
    exams.sort(sortRankAsc);
  } else {
    exams.sort(sortRankDesc);
  }
  return exams;
};

function sortRankAsc(a: ExamToDoDTO, b: ExamToDoDTO) {
  if (a?.rank && b?.rank) {
    if (a.rank < b.rank) {
      return -1;
    }
    if (a.rank > b.rank) {
      return 1;
    }
    return 0;
  } else {
    return 0;
  }
}

function sortRankDesc(a: ExamToDoDTO, b: ExamToDoDTO) {
  if (a?.rank && b?.rank) {
    if (a.rank > b.rank) {
      return -1;
    }
    if (a.rank < b.rank) {
      return 1;
    }
    return 0;
  } else {
    return 0;
  }
}

export const sortByExamToDoDTOArrayUsername = (examToDoDTOs: ExamToDoDTO[]): ExamToDoDTO[] => {
  return sort(examToDoDTOs).asc((user) => user.userWhoDidTheExam);
};

export const sortByLessonsName = (lessons: Lesson[]): Lesson[] => {
  return sort(lessons).asc((less) => less.name);
};

export const sortBySubjectName = (sub: Subject[]): Subject[] => {
  return sort(sub).asc((sub) => sub.name);
};

export const sortByPackageName = (pkg: Package[]): Package[] => {
  return sort(pkg).asc((pkg) => pkg.title);
};

export const isMCQTFSTRESYpapersPresent = (
  appPapers: PapersContext,
  paperIds: string[]
): {
  isMCQsPresent: boolean;
  isTFsPresent: boolean;
  isSTRPresent: boolean;
  isESSAYPresent: boolean;
} => {
  const isMCQsPresent = !!appPapers.allMCQTFPapers
    .filter((p) => p.paperType === PaperTypes.MCQ)
    .find((p) => paperIds.includes(p.id || ''));
  const isTFsPresent = !!appPapers.allMCQTFPapers
    .filter((p) => p.paperType === PaperTypes.TRUEFALSE)
    .find((p) => paperIds.includes(p.id || ''));
  const isSTRPresent = !!appPapers.structuredPapers.find((p) => paperIds.includes(p.id || ''));
  const isESSAYPresent = !!appPapers.essayPapers.find((p) => paperIds.includes(p.id || ''));

  return { isMCQsPresent, isTFsPresent, isSTRPresent, isESSAYPresent };
};

export const getAllSubjects = () => {
  return firebase.firestore().collection('subjects').get();
};

export const getAllLessons = () => {
  return firebase.firestore().collection('lessons').get();
};

export const getRole = (role: UserRole | undefined) => {
  const isAdmin = role?.isAdmin;
  const isInstructor = role?.isInstructor;
  const isStudent = role?.isStudent;
  const isTeachingAssistant = role?.isTeachingAssistant;

  return { isAdmin, isInstructor, isTeachingAssistant, isStudent };
};

export const getMCQQuestionBasedOnRole = (
  uid: string,
  subjects: Subject[],
  role: UserRole | undefined,
  mcqQuestions: MCQ[]
) => {
  const { isAdmin, isInstructor, isStudent, isTeachingAssistant } = getRole(role);

  const subjectIdsArray = subjects
    .filter((sub) => sub.assignedUsers?.includes(uid))
    .map((s) => s.id);

  const usersSubjects = subjects.filter((sub) => sub.createdBy === uid).map((s) => s.id);

  const appQtns = isAdmin
    ? [...mcqQuestions]
    : isInstructor
    ? [...mcqQuestions].filter(
        (q) =>
          q.createdBy === uid ||
          subjectIdsArray.includes(q.subjectId || '') ||
          usersSubjects.includes(q.subjectId || '')
      )
    : isTeachingAssistant
    ? [...mcqQuestions].filter((q) => subjectIdsArray.includes(q.subjectId || ''))
    : isStudent
    ? []
    : [];

  return appQtns;
};

export const getTFQuestionBasedOnRole = (
  uid: string,
  subjects: Subject[],
  role: UserRole | undefined,
  tfQuestions: TFQuestion[]
) => {
  const { isAdmin, isInstructor, isStudent, isTeachingAssistant } = getRole(role);

  const subjectIdsArray = subjects
    .filter((sub) => sub.assignedUsers?.includes(uid))
    .map((s) => s.id);

  const usersSubjects = subjects.filter((sub) => sub.createdBy === uid).map((s) => s.id);

  const appQtns = isAdmin
    ? [...tfQuestions]
    : isInstructor
    ? [...tfQuestions].filter(
        (q) =>
          q.createdBy === uid ||
          subjectIdsArray.includes(q.subjectId || '') ||
          usersSubjects.includes(q.subjectId || '')
      )
    : isTeachingAssistant
    ? [...tfQuestions].filter((q) => subjectIdsArray.includes(q.subjectId || ''))
    : isStudent
    ? []
    : [];

  return appQtns;
};

export const getMCQPapersBasedOnRole = (
  uid: string,
  subjects: Subject[],
  role: UserRole | undefined,
  paper: Paper[]
) => {
  const { isAdmin, isInstructor, isStudent, isTeachingAssistant } = getRole(role);

  const subjectIdsArray = subjects
    .filter((sub) => sub.assignedUsers?.includes(uid))
    .map((s) => s.id);

  const usersSubjects = subjects.filter((sub) => sub.createdBy === uid).map((s) => s.id);

  const appPapers = isAdmin
    ? [...paper]
    : isInstructor
    ? [...paper].filter(
        (q) =>
          q.createdBy === uid ||
          subjectIdsArray.includes(q.subjectId || '') ||
          usersSubjects.includes(q.subjectId || '')
      )
    : isTeachingAssistant
    ? [...paper].filter((q) => subjectIdsArray.includes(q.subjectId || ''))
    : isStudent
    ? []
    : [];

  return appPapers;
};

export const getTFPapersBasedOnRole = (
  uid: string,
  subjects: Subject[],
  role: UserRole | undefined,
  paper: Paper[]
) => {
  const { isAdmin, isInstructor, isStudent, isTeachingAssistant } = getRole(role);

  const subjectIdsArray = subjects
    .filter((sub) => sub.assignedUsers?.includes(uid))
    .map((s) => s.id);

  const usersSubjects = subjects.filter((sub) => sub.createdBy === uid).map((s) => s.id);

  const appPapers = isAdmin
    ? [...paper]
    : isInstructor
    ? [...paper].filter(
        (q) =>
          q.createdBy === uid ||
          subjectIdsArray.includes(q.subjectId || '') ||
          usersSubjects.includes(q.subjectId || '')
      )
    : isTeachingAssistant
    ? [...paper].filter((q) => subjectIdsArray.includes(q.subjectId || ''))
    : isStudent
    ? []
    : [];

  return appPapers;
};

export const getSTRPapersBasedOnRole = (
  uid: string,
  subjects: Subject[],
  role: UserRole | undefined,
  structPapers: StructEssayPaper[]
) => {
  const { isAdmin, isInstructor, isStudent, isTeachingAssistant } = getRole(role);

  const usersSubjects = subjects.filter((sub) => sub.createdBy === uid).map((s) => s.id);

  const subjectIdsArray = subjects
    .filter((sub) => sub.assignedUsers?.includes(uid))
    .map((s) => s.id);

  const appPapers = isAdmin
    ? [...structPapers]
    : isInstructor
    ? [...structPapers].filter(
        (q) =>
          q.createdBy === uid ||
          subjectIdsArray.includes(q.subjectId || '') ||
          usersSubjects.includes(q.subjectId || '')
      )
    : isTeachingAssistant
    ? [...structPapers].filter((q) => subjectIdsArray.includes(q.subjectId || ''))
    : isStudent
    ? []
    : [];

  return appPapers;
};

export const getESYPapersBasedOnRole = (
  uid: string,
  subjects: Subject[],
  role: UserRole | undefined,
  structPapers: StructEssayPaper[]
) => {
  const { isAdmin, isInstructor, isStudent, isTeachingAssistant } = getRole(role);

  const subjectIdsArray = subjects
    .filter((sub) => sub.assignedUsers?.includes(uid))
    .map((s) => s.id);

  const usersSubjects = subjects.filter((sub) => sub.createdBy === uid).map((s) => s.id);

  const appPapers = isAdmin
    ? [...structPapers]
    : isInstructor
    ? [...structPapers].filter(
        (q) =>
          q.createdBy === uid ||
          subjectIdsArray.includes(q.subjectId || '') ||
          usersSubjects.includes(q.subjectId || '')
      )
    : isTeachingAssistant
    ? [...structPapers].filter((q) => subjectIdsArray.includes(q.subjectId || ''))
    : isStudent
    ? []
    : [];

  return appPapers;
};

export const removeDecimalSection = (score: string) => {
  return score?.toString().split('.')[1]?.length
    ? score.toString().split('.')[0] + '.' + score.toString().split('.')[1].substring(0, 2)
    : score.toString();
};

export const removeAtSymboleAndTruncate = (inputText: string, truncateIndex: number) => {
  const collection: number[] = [];
  let removedAtSymbolesText = '';

  for (let i = 0; i < inputText.length; i++) {
    if (inputText[i] === '@') {
      collection.push(i);
    } else {
      removedAtSymbolesText = removedAtSymbolesText + inputText[i];
    }
  }

  const truncateValue = truncate(removedAtSymbolesText, truncateIndex);

  const firstElipseIdx = truncateValue.length - 3;

  const firstSegment = removedAtSymbolesText.substring(0, firstElipseIdx);
  const secondSegment = removedAtSymbolesText.substring(firstElipseIdx);

  let construction = '';

  for (let i = 0; i < firstSegment.length; i++) {
    if (collection.includes(i)) {
      const first = firstSegment.substring(0, i);
      const second = firstSegment.substring(i);

      construction = first + '@' + second;
    } else {
      construction = construction + inputText[i];
    }
  }

  return construction + secondSegment;
};

export const timeTakenFormatted = (timeTaken: string) => {
  const numberOfSemis = timeTaken.replace(/[^:]/g, '').length;

  let days = '';
  let hours = '';
  let minutes = '';
  let seconds = '';
  let array = timeTaken.split(':');

  if (numberOfSemis === 3) {
    days = array[0];
    hours = array[1];
    minutes = array[2];
    seconds = array[3];

    return `${days}D ${hours}H ${minutes}M ${seconds}S`;
  } else if (numberOfSemis === 2) {
    hours = array[0];
    minutes = array[1];
    seconds = array[2];

    return `${hours}H ${minutes}M ${seconds}S`;
  } else if (numberOfSemis === 1) {
    minutes = array[0];
    seconds = array[1];

    return ` ${minutes}M ${seconds}S`;
  } else if (numberOfSemis === 0) {
    return timeTaken + 'S';
  }
};

export const checkArrayContains = (arr: string[], target: string[]) => {
  console.log('ARRAY ', arr);
  console.log('TARGET ', target);
  return target.every((v) => arr.includes(v));
};

export const sortByCreatedAt = (array: any[]) => {
  array.sort(function (a, b) {
    if (b?.createdAt && a?.createdAt) {
      return +new Date(b.createdAt?.toDate()) - +new Date(a.createdAt?.toDate());
    }
    return 0;
  });

  return array;
};

export const getAnnouncementStatus = (date: Date, duration: number): 'active' | 'inactive' => {
  var result = new Date(date);
  result.setDate(result.getDate() + duration);
  const start = date.getTime();
  const end = result.getTime();
  const now = new Date().getTime();

  if (now >= start && now <= end) {
    return 'active';
  } else {
    return 'inactive';
  }
};

export const getAnnouncementStatusV2 = (date: Date, duration: number): 'active' | 'inactive' => {
  var result = new Date(date);
  result.setDate(result.getDate() + duration);
  const start = date.getTime();
  // const end = result.getTime();
  const now = new Date().getTime();

  if (now >= start) {
    return 'active';
  } else {
    return 'inactive';
  }
};

export const getPaperType = (type: string) => {
  switch (type) {
    case PaperTypes.MCQ:
      return 'MCQ';
    case PaperTypes.TRUEFALSE:
      return 'T/F';
    case PaperTypes.STRUCTURED:
      return 'STR';
    default:
      return 'ESY';
  }
};

export const formatScore = (num?: number) => {
  if (!num) {
    return 0;
  } else if (typeof num === 'string') {
    return num;
  } else if (num?.toFixed(2)?.split('.')[1] === '00') {
    return num;
  } else {
    return num.toFixed(2);
  }
};

export const roundNumber = (num: number, dp: number) => {
  const multiplier = Math.pow(10, dp);
  return Math.round(num * multiplier) / multiplier;
};
export const roundNumberWithDigits = (n: number) => {
  if (typeof n === 'string') {
    return n;
  } else {
    const validator = new RegExp('00$');
    const s = n.toFixed(2);
    if (s.match(validator)) {
      return `${+s}`;
    }
    return s;
  }
};
export const round = (
  value: number,
  minimumFractionDigits: number,
  maximumFractionDigits: number
) => {
  const formattedValue = value.toLocaleString('en', {
    useGrouping: false,
    minimumFractionDigits,
    maximumFractionDigits,
  });
  return Number(formattedValue);
};

export const getStatusOfPaper = (
  role: 'ADMININS' | 'TA',
  isGradeTypePercentage: boolean,
  structEssayPaper: PaperDTO | undefined,
  isAssignedPaper?: boolean,
  PaperTypeMCQorTF?: boolean,
  notAssignedCount?: number
) => {
  const isUploaded = structEssayPaper?.structEssayData?.answerSheetUrl;
  let isMarked = structEssayPaper?.structEssayData?.markedAnswerSheetUrl;
  let isReleased = structEssayPaper?.released;

  if (role === 'TA') {
    if (!isUploaded) {
      // NOT UPLOAED
      return {
        icon: { iconVal: FaExclamation, color: 'red' },
        status: StatusOfPaper.NOT_UPLOADED,
        score: 'NOT UPLOAD',
      };
    } else if (isReleased) {
      const score = isGradeTypePercentage
        ? removeDecimalSection(structEssayPaper?.score?.toString() || '') + '%'
        : (structEssayPaper?.paperPoints || 0) + '/' + structEssayPaper?.marks;

      return {
        icon: { iconVal: FaRegCheckCircle, color: 'green' },
        status: StatusOfPaper.APPROVED,
        score: score,
      };
    } else if (
      isMarked &&
      structEssayPaper?.structEssayData?.status === StructEssayDataPaperStatus.APPROVED
    ) {
      // APPROVED
      const score = isGradeTypePercentage
        ? removeDecimalSection(structEssayPaper?.score?.toString() || '') + '%'
        : (structEssayPaper?.paperPoints || 0) + '/' + structEssayPaper?.marks;

      return {
        icon: { iconVal: FaRegCheckCircle, color: 'green' },
        status: StatusOfPaper.APPROVED,
        score: score,
      };
    } else if (
      !isMarked &&
      structEssayPaper?.structEssayData?.status === StructEssayDataPaperStatus.REJECTED
    ) {
      // REJECTED
      return {
        icon: { iconVal: FaExclamationTriangle, color: 'red' },
        status: StatusOfPaper.REJECTED,
        score: '-',
      };
    } else if (
      isMarked &&
      structEssayPaper?.structEssayData?.status === StructEssayDataPaperStatus.REJECTED
    ) {
      // REJECTED
      return {
        icon: { iconVal: FaExclamationTriangle, color: 'red' },
        status: StatusOfPaper.REJECTED,
        score: '-',
      };
    } else if (
      isMarked &&
      structEssayPaper?.structEssayData?.status === StructEssayDataPaperStatus.PENDING
    ) {
      // PENDING

      return {
        icon: { iconVal: FaClock, color: 'yellow' },
        status: StatusOfPaper.PENDING,
        score: 'PENDING',
      };
    } else if (!isMarked) {
      // NOT MARKED
      return {
        icon: { iconVal: MdAssignmentInd, color: 'blue' },
        status: StatusOfPaper.ASSIGNED,
        score: '-',
      };
    }

    // ADMIN AND INS STARTS HERE
  } else {
    if (PaperTypeMCQorTF) {
      return {
        icon: null,
        status: StatusOfPaper.NOT_UPLOADED,
        score: 'NOT UPLOAD',
      };
    } else if (!isUploaded) {
      // NOT UPLOAED
      return {
        icon: { iconVal: FaExclamation, color: 'red' },
        status: StatusOfPaper.NOT_UPLOADED,
        score: 'NOT UPLOAD',
      };
    } else if (isReleased && !isAssignedPaper) {
      const score = isGradeTypePercentage
        ? removeDecimalSection(structEssayPaper?.score?.toString() || '') + '%'
        : (structEssayPaper?.paperPoints || 0) + '/' + structEssayPaper?.marks;

      if (notAssignedCount !== undefined) {
        notAssignedCount++;
      }

      return {
        icon: { iconVal: FaRocket, color: 'green' },
        status: StatusOfPaper.RELEASED,
        score: score,
        notAssignedCount: notAssignedCount,
      };
    } else if (isReleased && isAssignedPaper) {
      const score = isGradeTypePercentage
        ? removeDecimalSection(structEssayPaper?.score?.toString() || '') + '%'
        : (structEssayPaper?.paperPoints || 0) + '/' + structEssayPaper?.marks;

      return {
        icon: { iconVal: FaRocket, color: 'green' },
        status: StatusOfPaper.RELEASED_ASSIGNED,
        score: score,
      };
    } else if (
      isMarked &&
      structEssayPaper?.structEssayData?.status === StructEssayDataPaperStatus.APPROVED
    ) {
      // APPROVED
      const score = isGradeTypePercentage
        ? removeDecimalSection(structEssayPaper?.score?.toString() || '') + '%'
        : (structEssayPaper?.paperPoints || 0) + '/' + structEssayPaper?.marks;

      return {
        icon: { iconVal: FaRegCheckCircle, color: 'green' },
        status: StatusOfPaper.APPROVED,
        score: score,
      };
    } else if (
      isMarked &&
      structEssayPaper?.structEssayData?.status === StructEssayDataPaperStatus.REJECTED
    ) {
      // REJECTED
      return {
        icon: { iconVal: FaExclamationTriangle, color: 'red' },
        status: StatusOfPaper.REJECTED,
        score: '-',
      };
    } else if (isMarked && isAssignedPaper) {
      // const score = isGradeTypePercentage
      //   ? removeDecimalSection(structEssayPaper?.score?.toString() || '') + '%'
      //   : (structEssayPaper?.paperPoints || 0) + '/' + structEssayPaper?.marks;

      return {
        icon: { iconVal: FaClock, color: 'yellow' },
        status: StatusOfPaper.PENDING,
        score: 'PENDING',
      };
    } else if (isMarked && !isAssignedPaper) {
      const score = isGradeTypePercentage
        ? removeDecimalSection(structEssayPaper?.score?.toString() || '') + '%'
        : (structEssayPaper?.paperPoints || 0) + '/' + structEssayPaper?.marks;
      if (notAssignedCount !== undefined) {
        notAssignedCount++;
      }

      return {
        icon: { iconVal: MdAssignmentInd, color: '#8B8B8B' },
        status: StatusOfPaper.MARKED_NOT_ASSIGNED,
        score: score,
        notAssignedCount: notAssignedCount,
      };
    } else if (!isMarked && isAssignedPaper) {
      return {
        icon: { iconVal: MdAssignmentInd, color: 'blue' },
        status: StatusOfPaper.ASSIGNED,
        score: '-',
      };
    } else if (!isMarked && !isAssignedPaper) {
      return {
        icon: { iconVal: MdAssignmentInd, color: '#8B8B8B' },
        status: StatusOfPaper.NOT_MARKED_NOT_ASSIGNED,
        score: '-',
        notAssignedCount: notAssignedCount,
      };
    }
  }
};

export const sortByUnitsName = (units: Unit[]): Unit[] => {
  return sort(units).asc((unts) => unts.name);
};

export const sortByCommonAccessCodesName = (
  CommonAccessCode: CommonAccessCode[]
): CommonAccessCode[] => {
  return sort(CommonAccessCode).asc((cAccessCodes) => cAccessCodes.accessCode);
};

export const arraysEqual = (arr1: string[], arr2: string[]) => {
  // return arr2.every((elem) => arr1.includes(elem));
  return arr1.every((elem) => arr2.includes(elem));
};

export const appendZeroInFront = (num: string) => {
  if (num.length < 2) {
    return '0' + num;
  }
  return num;
};

export const getUrlsForExamTypeTabs = (
  subjectId?: string,
  lessonId?: string,
  unitId?: string,
  tab?: EXAM_TYPE_TABS,
  unitsPresent?: boolean
) => {
  const { PRACTISE, EXTRA } = EXAM_TYPE_TABS;

  let liveUrls =
    subjectId && lessonId && unitId
      ? `/my-subjects/subject/${subjectId}/lesson/${lessonId}/unit/${unitId}/Examinations?name=${tab}`
      : subjectId && lessonId
      ? `/my-subjects/subject/${subjectId}/lesson/${lessonId}/Examinations?name=${tab}`
      : `/my-subjects/subject/${subjectId}/Examinations?name=${tab}`;

  let practiseUrls =
    subjectId && lessonId && unitId
      ? `/my-subjects/subject/${subjectId}/lesson/${lessonId}/unit/${unitId}/Examinations?name=${tab}`
      : subjectId && lessonId
      ? `/my-subjects/subject/${subjectId}/lesson/${lessonId}/Examinations?name=${tab}`
      : `/my-subjects/subject/${subjectId}/Examinations?name=${tab}`;

  let extraUrls =
    subjectId && lessonId && unitId
      ? `/my-subjects/subject/${subjectId}/lesson/${lessonId}/unit/${unitId}/Examinations?name=${tab}`
      : subjectId && lessonId
      ? `/my-subjects/subject/${subjectId}/lesson/${lessonId}/Examinations?name=${tab}`
      : `/my-subjects/subject/${subjectId}/Examinations?name=${tab}`;

  if (unitsPresent) {
    liveUrls =
      subjectId && lessonId
        ? `/my-subjects/subject/${subjectId}/lesson/${lessonId}/Content/Examinations?name=${tab}`
        : `/my-subjects/subject/${subjectId}/Content/Examinations?name=${tab}`;
  }

  if (unitsPresent) {
    practiseUrls =
      subjectId && lessonId
        ? `/my-subjects/subject/${subjectId}/lesson/${lessonId}/Content/Examinations?name=${tab}`
        : `/my-subjects/subject/${subjectId}/Content/Examinations?name=${tab}`;
  }

  if (unitsPresent) {
    extraUrls =
      subjectId && lessonId
        ? `/my-subjects/subject/${subjectId}/lesson/${lessonId}/Content/Examinations?name=${tab}`
        : `/my-subjects/subject/${subjectId}/Content/Examinations?name=${tab}`;
  }

  return tab === EXTRA ? extraUrls : tab === PRACTISE ? practiseUrls : liveUrls;
};

export const rankingHandler = (array1: number[]) =>
  array1.map((x, y, z) => z.filter((w) => w > x).length + 1);

export const getExamAndTheCompletedExam = () => {};

export const truncateText = (text: string, maxLength: number) => {
  let truncatedText: string;
  truncatedText = text.length > maxLength ? text.substring(0, maxLength) + '...' : text;

  return truncatedText;
};

export const sortSubBulkAccessCodes = (
  subBulkAccessCodes: SubBulkAccessCode[]
): SubBulkAccessCode[] => {
  return sort(subBulkAccessCodes).asc((bulkCode) => bulkCode.subBulkAccessCode);
};

export const sortSubBulkAccessCodesDESC = (
  subBulkAccessCodes: SubBulkAccessCode[]
): SubBulkAccessCode[] => {
  return sort(subBulkAccessCodes).desc((bulkCode) => bulkCode.subBulkAccessCode);
};

export const sortUsersFromName = (usersInput: UserFirestore[]): UserFirestore[] => {
  return sort(usersInput).asc((user) => user.firstName);
};

export const sortUsersFromNameDesc = (usersInput: UserFirestore[]): UserFirestore[] => {
  return sort(usersInput).desc((user) => user.firstName);
};