import firebase from 'firebase';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { BulkAccessCode, SubBulkAccessCode } from '../models/BulkAccessCode';

export interface BulkAccessCodeContext {
  bulkAccessCodes: BulkAccessCode[];
  isLoading: boolean;
  fetchBulkAccessCodes: () => void;
  setLoading: (value: boolean) => void;
  addBulkAccessCode: (bulkAccessCode: BulkAccessCode) => void;
  updateBulkAccessCodeStatus: (value: boolean, bulkCodeId: string) => void;
  updateSubBulkCodeStatus: (value: boolean, bulkCodeId: string, subBulkCodeId: string) => void;
}

interface State {
  bulkAccessCodes: BulkAccessCode[];
  isLoading: boolean;
}

const initialContext: BulkAccessCodeContext = {
  bulkAccessCodes: [],
  isLoading: false,
  fetchBulkAccessCodes: () => {},
  setLoading: (value: boolean) => {},
  addBulkAccessCode: (bulkAccessCode: BulkAccessCode) => {},
  updateBulkAccessCodeStatus: (value: boolean, bulkCodeId: string) => {},
  updateSubBulkCodeStatus: (value: boolean, bulkCodeId: string, subBulkCodeId: string) => {},
};

const initialState: State = {
  bulkAccessCodes: [],
  isLoading: false,
};

const Context = createContext<BulkAccessCodeContext>(initialContext);

function getUniqueListBy(arr: any[]) {
  const ids = arr.map((o: any) => o.id);
  const filtered = arr.filter(({ id }: any, index: any) => !ids.includes(id, index + 1));
  return filtered;
}

export const BulkAccessCodeProvider: FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    fetchBulkAccessCodes();
  }, []);

  const fetchBulkAccessCodes = () => {
    const bulkAccessCodes: BulkAccessCode[] = [];

    firebase
      .firestore()
      .collection('bulkAccessCodes')
      .get()
      .then((data) => {
        data.forEach((doc) => {
          bulkAccessCodes.push({ id: doc.id, ...doc.data() } as any);
        });

        setState((ps) => {
          return {
            ...ps,
            bulkAccessCodes: getUniqueListBy(bulkAccessCodes),
          };
        });
      })
      .catch((err) => {
        console.log('ERROR, ', err);
      });
  };

  const addBulkAccessCode = (bulkAccessCode: BulkAccessCode) => {
    const currentBulkAccessCodes = [...state.bulkAccessCodes];
    currentBulkAccessCodes.push(bulkAccessCode);

    setState((ps) => {
      return {
        ...ps,
        bulkAccessCodes: currentBulkAccessCodes,
      };
    });
  };

  const updateBulkAccessCodeStatus = (value: boolean, bulkCodeId: string) => {
    const currentBulkAccessCodes: BulkAccessCode[] = [...state.bulkAccessCodes];
    let updatedBulkAccessCodes: BulkAccessCode[] = [];

    let bulkAccessCode: BulkAccessCode = currentBulkAccessCodes.find(
      (bulkCode: BulkAccessCode) => bulkCode.id === bulkCodeId
    )!;

    updatedBulkAccessCodes = currentBulkAccessCodes.filter(
      (bulkCode: BulkAccessCode) => bulkCode.id !== bulkCodeId
    );

    const updatedSubBulkAccessCodes = bulkAccessCode.subBulkAccessCodes?.map(
      (subBulkCode: SubBulkAccessCode) => {
        if (!subBulkCode.userId?.length) {
          return {
            ...subBulkCode,
            subBulkAccessCodeStatus: value,
          };
        } else {
          return subBulkCode;
        }
      }
    );

    const updatedBulkAcessCode: BulkAccessCode = {
      ...bulkAccessCode,
      bulkAccessCodeStatus: value,
      subBulkAccessCodes: updatedSubBulkAccessCodes,
    };

    updatedBulkAccessCodes.push(updatedBulkAcessCode);

    setState((ps) => {
      return {
        ...ps,
        bulkAccessCodes: updatedBulkAccessCodes,
      };
    });
  };

  const updateSubBulkCodeStatus = (value: boolean, bulkCodeId: string, subBulkCodeId: string) => {
    const currentBulkAccessCodes: BulkAccessCode[] = [...state.bulkAccessCodes];
    let updatedBulkAccessCodes: BulkAccessCode[] = [];
    let updatedSubBulkAccessCodes: SubBulkAccessCode[] = [];

    let bulkAccessCode: BulkAccessCode = currentBulkAccessCodes.find(
      (bulkCode: BulkAccessCode) => bulkCode.id === bulkCodeId
    )!;

    let subBulkAccessCodes: SubBulkAccessCode[] = bulkAccessCode.subBulkAccessCodes!;

    let subBulkAccessCode: SubBulkAccessCode = subBulkAccessCodes.find(
      (subBulkCode: SubBulkAccessCode) => subBulkCode.subBulkAccessCode === subBulkCodeId
    )!;

    updatedBulkAccessCodes = currentBulkAccessCodes.filter(
      (bulkCode: BulkAccessCode) => bulkCode.id !== bulkCodeId
    );
    updatedSubBulkAccessCodes = subBulkAccessCodes.filter(
      (subBulkCode: SubBulkAccessCode) => subBulkCode.subBulkAccessCode !== subBulkCodeId
    );

    const updateSubBulkAccessCode = {
      ...subBulkAccessCode,
      subBulkAccessCodeStatus: value,
    };

    updatedSubBulkAccessCodes.push(updateSubBulkAccessCode);

    const updatedBulkAcessCode: BulkAccessCode = {
      ...bulkAccessCode,
      subBulkAccessCodes: updatedSubBulkAccessCodes,
    };

    updatedBulkAccessCodes.push(updatedBulkAcessCode);

    setState((ps) => {
      return {
        ...ps,
        bulkAccessCodes: updatedBulkAccessCodes,
      };
    });
  };

  const setLoading = (value: boolean) => {
    setState((ps) => ({
      ...ps,
      isLoading: value,
    }));
  };

  return (
    <Context.Provider
      value={{
        ...state,
        fetchBulkAccessCodes,
        setLoading,
        addBulkAccessCode,
        updateBulkAccessCodeStatus,
        updateSubBulkCodeStatus,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useBulkAccessCodes = (): BulkAccessCodeContext => useContext(Context);
