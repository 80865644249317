import devConfig from './development.json';

const config: Config = devConfig;
export default config;

export interface Config {
  firebase: FirebaseConfig;
}

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

const reFactor = {
  baseUrl: 'https://skf-al.scrapbooklms.com',
};

export const passwordResetURL = 'https://skf-al.scrapbooklms.com';

export const videoUrlPrefix =
  'https://scrapbooklms-hls-stream-bucket-687365432960.s3.amazonaws.com/';

export const AppConfig = reFactor;
