import React, { Fragment } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import EmailVerification from '../components/EmailVerification';
import ForgotPassword from '../components/ForgotPassword';
import Loader from '../components/Loader';
import ResetEmailSent from '../components/ResetEmailSent';
import ResetPassword from '../components/ResetPassword';
import SignIn from '../components/SignIn';
import SignUp from '../components/SignUp';
import VerifyPhoneNumber from '../components/VerifyPhoneNumber';
import { AdminsAndInstructorsProvider } from '../context/AdminsAndInstructors';
import { AnouncementProvider } from '../context/AnnouncementContext';
import { FlagProvider } from '../context/FlagContext';
import { LabelProvider } from '../context/LabelContext';
import { LessonProvider } from '../context/LessonsContext';
import { MobileNavProvider } from '../context/MobNavContext';
import { PapersProvider } from '../context/PapersContext';
import { PaymentSwitchProvider } from '../context/PaymentContext';
import { QuestionsProvider } from '../context/QuestionsContext';
import { RestrictionsProvider } from '../context/RestrictionsContext';
import { SubjectProviderUpdated } from '../context/SubjectContextUpdated';
import { SubjectProvider } from '../context/SubjectsContext';
import { UnitProvider } from '../context/UnitsContext';
import { useAppUser } from '../context/UserContext';
import AdminExamDetailsAndStatsScreen from '../screens/admin/AdminExamDetailsAndStatsScreen';
import AdminLessonScreen from '../screens/admin/AdminLessonScreen';
import AdminSubjectScreen from '../screens/admin/AdminSubjectScreen';
import AdminUnitScreen from '../screens/admin/AdminUnitScreen';
import Account from '../screens/common/Account';
import Notices from '../screens/common/Notices';
import PageNotFoundScreen from '../screens/common/PageNotFoundScreen';
import Resource from '../screens/common/Resource';
import Settings from '../screens/common/Settings';
import Subjects from '../screens/common/Subjects';
// import InstructorExamDetailsAndStatsScreen from '../screens/instructor/InstructorExamDetailsAndStatsScreen';
// import InstructorLessonModalPapersScreen from '../screens/instructor/InstructorLessonModalPapersScreen';
// import InstructorLessonMyUnitScreen from '../screens/instructor/InstructorLessonMyUnitScreen';
import InstructorSubjectScreen from '../screens/instructor/InstructorSubjectScreen';
// import InstructorUnitScreen from '../screens/instructor/InstructorUnitScreen';
// import InstructorUnitScreenContent from '../screens/instructor/InstructorUnitScreenContent';
// import InstructorUpdateExamScreen from '../screens/instructor/InstructorUpdateExamScreen';
import StudentExamAnswerScreen from '../screens/student/StudentExamAnswerScreen';
import StudentLessonScreen from '../screens/student/StudentLessonScreen';
import StudentPaperResultScreen from '../screens/student/StudentPaperResultScreen';
import StudentResultScreen from '../screens/student/StudentResultScreen';
import StudentSubjectViewScreen from '../screens/student/StudentSubjectViewScreen';
import TAExamDetailsAndStatsScreen from '../screens/ta/TAExamDetailsAndStatsScreen';
import TASubjectScreen from '../screens/ta/TASubjectScreen';
import AdminPackageViewScreen from '../screens/admin/AdminPackageViewScreen';
import ViewNotice from '../components/ViewNotice';
import PrimaryNav from '../components/PrimaryNav';
import LayoutNew from '../layouts/LayoutNew';
import CreateNotice from '../components/CreateNotice';
import StudentContentScreen from '../modules/content/screen/student/StudentContentScreen';
import { CommonAccessCodeProvider } from '../context/CommonAccessCodeContext';
import { BulkAccessCodeProvider } from '../context/BulkAccessCodeContext';
import { PackageProvider } from '../context/PackageContext';

const Routes = () => {
  const user = useAppUser().fireUser;
  const fireStoreUser = useAppUser().firestoreUser;
  let routesUpdated: JSX.Element;

  const adminRoutes = (
    <Fragment>
      <Switch>
        <Route
          path="/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId"
          component={AdminUnitScreen}
        />
        <Route
          exact
          path="/my-subjects/subject/:subjectId/Announcements/:id"
          component={() => (
            <LayoutNew header={null} primaryNav={<PrimaryNav />} body={<ViewNotice />} />
          )}
        />
        <Route
          exact
          path="/my-subjects/subject/:subjectId/Announcements/edit/:id"
          component={() => (
            <LayoutNew
              header={null}
              primaryNav={<PrimaryNav />}
              body={<CreateNotice type="update" />}
            />
          )}
        />
        <Route
          exact
          path="/my-subjects/announcements/create/:subjectId"
          component={() => (
            <LayoutNew
              header={null}
              primaryNav={<PrimaryNav />}
              body={<CreateNotice type="create" />}
            />
          )}
        />
        <Route
          path="/my-subjects/subject/:subjectId/lesson/:lessonId"
          component={AdminLessonScreen}
        />
        <Route path="/my-subjects/subject/:subjectId" component={AdminSubjectScreen} />
        <Route path="/account" component={Account} />
        <Route exact path="/my-subjects" component={Subjects} />
        <Route path="/resource" component={Resource} />
        <Route path="/settings" component={Settings} />
        <Route path="/announcements" component={Notices} />
        <Route
          path="/"
          exact
          component={() => {
            return <Redirect to="my-subjects" />;
          }}
        />
        <Route path="*" component={PageNotFoundScreen} />
      </Switch>
    </Fragment>
  );

  const instructorRoutes = (
    <Fragment>
      <Switch>
        <Route
          path="/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId"
          component={AdminUnitScreen}
        />
        <Route
          exact
          path="/my-subjects/subject/:subjectId/Announcements/:id"
          component={() => (
            <LayoutNew header={null} primaryNav={<PrimaryNav />} body={<ViewNotice />} />
          )}
        />
        <Route
          exact
          path="/my-subjects/subject/:subjectId/Announcements/edit/:id"
          component={() => (
            <LayoutNew
              header={null}
              primaryNav={<PrimaryNav />}
              body={<CreateNotice type="update" />}
            />
          )}
        />
        <Route
          exact
          path="/my-subjects/announcements/create/:subjectId"
          component={() => (
            <LayoutNew
              header={null}
              primaryNav={<PrimaryNav />}
              body={<CreateNotice type="create" />}
            />
          )}
        />
        <Route
          path="/my-subjects/subject/:subjectId/lesson/:lessonId"
          component={AdminLessonScreen}
        />

        <Route
          path="/my-subjects/subject/:subjectId/Examinations/:examId"
          component={AdminExamDetailsAndStatsScreen}
        />

        <Route path="/my-subjects/subject/:subjectId" component={InstructorSubjectScreen} />
        <Route path="/account" component={Account} />
        <Route exact path="/my-subjects" component={Subjects} />
        <Route path="/resource" component={Resource} />
        <Route path="/settings" component={Settings} />
        <Route path="/announcements" component={Notices} />
        <Route
          path="/"
          exact
          component={() => {
            return <Redirect to="my-subjects" />;
          }}
        />
        <Route path="*" component={PageNotFoundScreen} />
      </Switch>
    </Fragment>
  );

  const taRoutes = (
    <Fragment>
      <Switch>
        <Route
          path="/my-subjects/subject/:subjectId/Examinations/:examId"
          component={TAExamDetailsAndStatsScreen}
        />
        <Route
          exact
          path="/my-subjects/subject/:subjectId/Announcements/:id"
          component={() => (
            <LayoutNew header={null} primaryNav={<PrimaryNav />} body={<ViewNotice />} />
          )}
        />
        <Route path="/my-subjects/subject/:subjectId" component={TASubjectScreen} />
        <Route path="/account" component={Account} />
        <Route exact path="/my-subjects" component={Subjects} />
        <Route path="/announcements" component={Notices} />
        <Route
          path="/"
          exact
          component={() => {
            return <Redirect to="my-subjects" />;
          }}
        />
        <Route path="*" component={PageNotFoundScreen} />
      </Switch>
    </Fragment>
  );

  const studentRoutes = (
    <Fragment>
      <Switch>
        <Route
          exact
          path={[
            '/my-subjects/subject/:subjectId/Lessons/:lessonId/Content',
            '/my-subjects/subject/:subjectId/Lessons/:lessonId/My Units/:unitId/Content',
          ]}
        >
          <StudentContentScreen />
        </Route>
        <Route exact path="/my-subjects/subject/:subjectId/Packages/:packageId">
          <AdminPackageViewScreen />
        </Route>
        <Route path="/account" component={Account} />
        <Route
          path="/my-subjects/:subject/:subjectId/exam/result/:examId/paper/:paperId"
          exact
          component={StudentPaperResultScreen}
        />
        <Route
          path="/my-subjects/subject/:subjectId/exam/result/:examId"
          exact
          component={StudentResultScreen}
        />
        <Route
          exact
          path="/my-subjects/subject/:subjectId/Announcements/:id"
          component={() => (
            <LayoutNew header={null} primaryNav={<PrimaryNav />} body={<ViewNotice />} />
          )}
        />
        <Route
          path="/my-subjects/subject/:subjectId/exam/:examId"
          exact
          component={StudentExamAnswerScreen}
        />
        <Route
          path="/my-subjects/subject/:subjectId/Lessons/:lessonId"
          component={StudentLessonScreen}
        />
        <Route path="/my-subjects/subject/:subjectId" component={StudentSubjectViewScreen} />
        <Route path="/announcements" component={Notices} />
        <Route exact path="/my-subjects" component={Subjects} />
        <Route
          path="/"
          exact
          component={() => {
            return <Redirect to="my-subjects" />;
          }}
        />

        <Route path="*" component={PageNotFoundScreen} />
      </Switch>
    </Fragment>
  );

  const designatedRoutes = fireStoreUser?.userRole?.isAdmin ? (
    adminRoutes
  ) : fireStoreUser?.userRole?.isInstructor ? (
    instructorRoutes
  ) : fireStoreUser?.userRole?.isStudent ? (
    studentRoutes
  ) : fireStoreUser?.userRole?.isTeachingAssistant ? (
    taRoutes
  ) : (
    <></>
  );

  if (user === undefined || fireStoreUser === undefined) {
    routesUpdated = <Loader />;
  } else if (user === null) {
    routesUpdated = (
      <Switch>
        <Route path="/signup" component={SignUp} />
        <Route path="/signin" component={SignIn} />
        <Route path="/email-verification" component={EmailVerification} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/password-verification/:email" component={ResetEmailSent} />
        <Route path="/verify-phoneno" component={VerifyPhoneNumber} />
        <Redirect to="/signin" />
      </Switch>
    );
  } else if (
    (fireStoreUser?.userRole?.isInstructor || fireStoreUser?.userRole?.isTeachingAssistant) &&
    !fireStoreUser?.isApproved
  ) {
    routesUpdated = (
      <Switch>
        <Route path="/account" component={Account} />
        <Redirect to="/account" />
      </Switch>
    );
  } else {
    routesUpdated = (
      <MobileNavProvider>
        <QuestionsProvider>
          <PapersProvider>
            <RestrictionsProvider>
              <FlagProvider>
                <SubjectProviderUpdated>
                  <SubjectProvider>
                    <PackageProvider>
                    <LessonProvider>
                      <UnitProvider>
                        <AdminsAndInstructorsProvider>
                          <PaymentSwitchProvider>
                            <LabelProvider>
                              <AnouncementProvider>
                                <CommonAccessCodeProvider>
                                  <BulkAccessCodeProvider>
                                    <Switch>
                                      {designatedRoutes}
                                      <Redirect to="/404" />
                                    </Switch>
                                  </BulkAccessCodeProvider>
                                </CommonAccessCodeProvider>
                              </AnouncementProvider>
                            </LabelProvider>
                          </PaymentSwitchProvider>
                        </AdminsAndInstructorsProvider>
                      </UnitProvider>
                    </LessonProvider>
                    </PackageProvider>
                  </SubjectProvider>
                </SubjectProviderUpdated>
              </FlagProvider>
            </RestrictionsProvider>
          </PapersProvider>
        </QuestionsProvider>
      </MobileNavProvider>
    );
  }

  return <Router>{routesUpdated}</Router>;
};

export default Routes;
