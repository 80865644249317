import React, { FC, useEffect } from 'react';
import './SubjectSecondaryNav.scss';
import { SUBJECT_SECONDARY_NAV_MAIN_ITEMS } from '../constants';

import { useHistory, useParams } from 'react-router-dom';
import { useSubjects } from '../context/SubjectsContext';
import Backarrow from './Backarrow';

const SubjectSecondaryNav: FC = () => {
  const { LESSONS, MODAL_PAPERS, ANNOUCEMENTS, SETTINGS } = SUBJECT_SECONDARY_NAV_MAIN_ITEMS;
  const navItems = [LESSONS, MODAL_PAPERS, ANNOUCEMENTS, SETTINGS];

  const { subjectId } = useParams() as { subjectId: string };
  const appSubjects = useSubjects();
  const history = useHistory();

  const subjectName = appSubjects.allSubjects.find((sub) => sub.id === subjectId)?.name || '';

  // const user = useAppUser().firestoreUser;
  useEffect(() => {
    // const isValid = isValidSubjectForUser({
    //   user: user,
    //   subjectId: subjectId,
    //   subjects: appSubjects.allSubjects,
    // });
    // if (!isValid && appSubjects.allSubjects.length) {
    //   history.push('/404');
    // }
  }, [appSubjects.allSubjects]);

  const localBaseUrl = `my-subjects/subject/${subjectId}`;
  const splittedUrl = history.location.pathname.split('/');

  const uRILength = splittedUrl.length;
  const lastSegment = decodeURI(splittedUrl.pop() || '');
  const oneBeforeTheLastSegment = decodeURI(splittedUrl[uRILength - 2]);
  const threeFromLast = decodeURI(splittedUrl[uRILength - 3]);

  const specialCheck =
    lastSegment === 'create-extra-exam' ||
    lastSegment === 'create-practise-exam' ||
    lastSegment === 'create-live-exam';

  return (
    <aside className="subject-snav subject-snav__100">
      <div>
        <header className="subject-snav__header">
          <Backarrow
            onClick={() => {
              history.push('/my-subjects');
            }}
          />
          <h6>{subjectName}</h6>
        </header>
        <li className="subject-snav__items">
          {navItems.map((item, idx) => (
            <ul
              className={`subject-snav__item  ${
                (lastSegment === item ||
                  (specialCheck && item === 'Examinations') ||
                  (oneBeforeTheLastSegment === 'Examinations' && item === 'Examinations') ||
                  (oneBeforeTheLastSegment === 'update-exam' && item === 'Examinations') ||
                  (threeFromLast === 'Examinations' && item === 'Examinations')) &&
                'subject-snav__item--active'
              }`}
              onClick={() => {
                history.push(`/${localBaseUrl}/${item}`);
              }}
              key={idx}
            >
              {item}
            </ul>
          ))}
        </li>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {/* <button
          className="subject-snav__button "
          onClick={() => {
            history.push(`/${localBaseUrl}/Packages`);
          }}
        >
          Packages
        </button> */}
      </div>
    </aside>
  );
};

export default SubjectSecondaryNav;
