import React, { FC, Fragment, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaAngleRight, FaHome } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import truncate from 'truncate';
import HamburgerIcon from '../components/HamburgerIcon';
import LoadingIndicator from '../components/LoadingIndicator';
import { useLessons } from '../context/LessonsContext';
import { useMobileNav } from '../context/MobNavContext';
import { useSubjects } from '../context/SubjectsContext';
import { useUnits } from '../context/UnitsContext';

import './BreadCrumbUpdate.scss';
import { useAppUser } from '../context/UserContext';

interface Props {
  loading?: boolean;
  className?: string;
}

const BreadCrumbUpdate: FC<Props> = (props) => {
  const history = useHistory();
  const params = useParams() as { subjectId: string; lessonId: string; unitId: string };

  const appSubjects = useSubjects();
  const appLessons = useLessons();
  const appUnits = useUnits();
  const appMobileNav = useMobileNav();
  const appUser = useAppUser();

  const goToMySubjects = () => {
    history.push('/my-subjects');
  };

  const goToSubject = () => {
    history.push(`/my-subjects/subject/${params.subjectId}/Lessons`);
  };

  const goToLesson = () => {
    const isLessonContainsUnits = appLessons.lessons.find(
      (les) => les.id === params.lessonId
    )?.isLessonContainsUnits;

    if (appUser.firestoreUser?.userRole?.isStudent) {
      if (isLessonContainsUnits) {
        history.push(
          `/my-subjects/subject/${params.subjectId}/Lessons/${params.lessonId}/My Units`
        );
      } else {
        history.push(`/my-subjects/subject/${params.subjectId}/Lessons/${params.lessonId}/Content`);
      }
    } else {
      if (isLessonContainsUnits) {
        history.push(`/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/My Units`);
      } else {
        history.push(`/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/Content`);
      }
    }
  };

  const goToUnit = () => {
    if (appUser.firestoreUser?.userRole?.isStudent) {
      history.push(
        `/my-subjects/subject/${params.subjectId}/Lessons/${params.lessonId}/My Units/${params.unitId}/Content`
      );
    } else {
      history.push(
        `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/unit/${params.unitId}/Content`
      );
    }
  };

  const initialBreadCrumb = [
    { name: 'Home', onClick: goToMySubjects },
    { name: 'My Subjects', onClick: goToMySubjects },
  ];

  const [state, setState] = useState([
    { name: 'Home', onClick: goToMySubjects },
    { name: 'My Subjects', onClick: goToMySubjects },
  ]);

  const length = state?.filter((val) => val.name).length;

  useEffect(() => {
    const subjectName =
      appSubjects.allSubjects.find((sub) => sub.id === params.subjectId)?.name || '';
    const lessonName = appLessons.lessons.find((les) => les.id === params.lessonId)?.name || '';
    const unitName = appUnits.units.find((unit) => unit.id === params.unitId)?.name || '';

    const sub = { name: subjectName, onClick: goToSubject };
    const les = { name: lessonName, onClick: goToLesson };
    const unit = { name: unitName, onClick: goToUnit };

    const array = [...initialBreadCrumb];

    if (sub) array.push(sub);

    if (les) array.push(les);

    if (unit) array.push(unit);

    if (appSubjects.allSubjects.length) {
      setState(array);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSubjects.allSubjects, appLessons.lessons]);

  return (
    <Fragment>
      <div
        className={`text-white d-flex ${props.className} pr-4`}
        style={{ transition: 'none', flexWrap: 'wrap' }}
      >
        {state
          ?.filter((val) => val.name)
          .map((b, index) => (
            <OverlayTrigger
              key={index}
              placement="bottom"
              overlay={<Tooltip id="verifiedId">{b.name}</Tooltip>}
            >
              <div className={`mr-3 bcub`} onClick={b.onClick}>
                {index === 0 ? (
                  <FaHome />
                ) : (
                  <React.Fragment>
                    <FaAngleRight className={index !== 0 ? 'mr-3' : ''} />
                    <div
                      className={`bcub-item ${index !== length - 1 && 'bcub-item--not-current'}`}
                      style={{ maxWidth: 'none' }}
                    >
                      {truncate(b?.name || '', 30)}
                    </div>
                  </React.Fragment>
                )}
              </div>
            </OverlayTrigger>
          ))}
        <HamburgerIcon
          onClick={() => {
            appMobileNav.setOpenMobileNav(!appMobileNav.openMobileNav);
          }}
          className="d-md-none ml-auto"
        />
      </div>

      {props.loading ? <LoadingIndicator /> : <></>}
    </Fragment>
  );
};

export default BreadCrumbUpdate;
