import React, { FC, useState } from 'react';
import { useFunctions, useStorage } from 'reactfire';
import truncate from 'truncate';
import { usePapers } from '../context/PapersContext';
import { useAppUser } from '../context/UserContext';
import {
  CreateStructEssayPaperDTO,
  UpdateStructEssayPaperDTO,
} from '../dtos/CreateUpdateStructEssayPaperDTO';
import { ST_ES_PAPER_BREADCRUM_STEPS } from '../enums/PaperBreadCrum';
import { PaperStatus } from '../enums/PaperStatus';
import { PaperTypes } from '../enums/paperTypes';
import { PaperData, ResourcePaperState } from '../interfaces';
import { formatAllocatedTimeHoursAndMinutes } from '../utils';
import Notification from './../components/Notification';
import ButtonComp from './ButtonComp';

import './EssayPaperFileUploader.scss';
import LoadingIndicator from './LoadingIndicator';
import PDFUploaderComp from './PDFUploaderComp';

interface Props {
  state: ResourcePaperState;
  setState: React.Dispatch<React.SetStateAction<ResourcePaperState>>;
  structuredEssayPaperNaviationData: {
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    step: ST_ES_PAPER_BREADCRUM_STEPS;
    paperData: PaperData | null;
  };
  setStructuredEssayPaperNaviationData: (para: {
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    step: ST_ES_PAPER_BREADCRUM_STEPS;
    paperData: PaperData | null;
  }) => void;
}

const EssayPaperFileUploader: FC<Props> = (props) => {
  const [loading, setLoading] = useState(false);

  const appUser = useAppUser();

  const appPapers = usePapers();

  const storage = useStorage();

  const createStructEssayPaperRef = useFunctions().httpsCallable('createStructEssayPaper');
  const updateStructEssayPaperRef = useFunctions().httpsCallable('updateStructEssayPaper');

  const createHandler = async (paperStatus: PaperStatus) => {
    setLoading(true);
    if (!props.state.paper.value) {
      setLoading(false);
      return Notification({
        isSuccess: false,
        message: 'Please upload a question paper',
      });
    } else if (!props.state.markingScheme.value) {
      setLoading(false);
      return Notification({
        isSuccess: false,
        message: 'Please upload a marking scheme',
      });
    }

    const isAddUnits = !(props.state.lessons.value.length > 1);

    const createStructEssayPaperDTO: CreateStructEssayPaperDTO = {
      paperTitle: props.state.paperTitle.value,
      description: props.state.description.value,
      grade: props.state.grade.value.id,
      subjectId: props.state.subject.value.id,
      lessonId: props.state.lesson.value.id,
      unitId: props.state.unit.value.id,
      lessonIds: props.state.lessons.value.map((l) => l.id),
      unitIds: isAddUnits ? props.state.units.value.map((u) => u.id) : [],
      paperType: PaperTypes.ESSAY,
      paperUrl: props.state.paper.value,
      paperName: props.state.paper.file?.name || props.state.paper.paperName,
      markingSchemeUrl: props.state.markingScheme.value,
      markingSchemeName:
        props.state.markingScheme.file?.name || props.state.markingScheme.markingSchemeName,
      totalNoOfQuestions: parseInt(props.state.totalNoOfQuestions.value),
      allocatedTime:
        formatAllocatedTimeHoursAndMinutes(props.state.allocatedTime.value.hours) +
        ':' +
        formatAllocatedTimeHoursAndMinutes(props.state.allocatedTime.value.minutes),
      authorName: appUser.firestoreUser?.username || '',
      status: paperStatus,
      marks: +props.state.marks.value,
    };

    createStructEssayPaperRef(createStructEssayPaperDTO)
      .then((res) => {
        const paper = res.data.data;
        appPapers.fetchStructuredEssay();
        props.setStructuredEssayPaperNaviationData({
          ...props.structuredEssayPaperNaviationData,
          mode: 'PREVIEW',
          paperData: {
            unitId: paper.unitId || '',
            lessonId: paper.lessonId || '',
            unitIds: paper.unitIds || [],
            lessonIds: paper.lessonIds || [],
            subjectId: paper.subjectId,
            createdBy: paper.createdBy || '',
            paperId: paper.id || '',
            grade: paper.grade,
          },
        });

        if (paperStatus === PaperStatus.DRAFT) {
          Notification({
            isSuccess: true,
            message: 'Paper created successfully as a draft',
          });
        } else {
          Notification({
            isSuccess: true,
            message: 'Paper created successfully',
          });
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        return Notification({
          isSuccess: false,
          message: 'Failed to create paper',
        });
      });
  };

  const updateHandler = async (paperStatus: PaperStatus) => {
    setLoading(true);
    if (!props.state.paper.value) {
      setLoading(false);
      return Notification({
        isSuccess: false,
        message: 'Please upload a question paper',
      });
    } else if (!props.state.markingScheme.value) {
      setLoading(false);
      return Notification({
        isSuccess: false,
        message: 'Please upload a marking scheme',
      });
    }

    const isAddUnits = !(props.state.lessons.value.length > 1);

    const updateStructEssayPaperDTO: UpdateStructEssayPaperDTO = {
      id: props.structuredEssayPaperNaviationData.paperData?.paperId,
      paperTitle: props.state.paperTitle.value,
      description: props.state.description.value,
      grade: props.state.grade.value.id,
      subjectId: props.state.subject.value.id,
      lessonId: props.state.lesson.value.id,
      unitId: props.state.unit.value.id,
      lessonIds: props.state.lessons.value.map((l) => l.id),
      unitIds: isAddUnits ? props.state.units.value.map((u) => u.id) : [],
      paperType: PaperTypes.ESSAY,
      paperUrl: props.state.paper.value,
      paperName: props.state.paper.file?.name || props.state.paper.paperName,
      markingSchemeUrl: props.state.markingScheme.value,
      markingSchemeName:
        props.state.markingScheme.file?.name || props.state.markingScheme.markingSchemeName,

      totalNoOfQuestions: parseInt(props.state.totalNoOfQuestions.value),
      allocatedTime:
        formatAllocatedTimeHoursAndMinutes(props.state.allocatedTime.value.hours) +
        ':' +
        formatAllocatedTimeHoursAndMinutes(props.state.allocatedTime.value.minutes),
      authorName: appUser.firestoreUser?.username || '',
      status: paperStatus,
      marks: +props.state.marks.value,
    };

    updateStructEssayPaperRef(updateStructEssayPaperDTO)
      .then((res) => {
        const paper = res.data.data;
        appPapers.fetchStructuredEssay();
        props.setStructuredEssayPaperNaviationData({
          ...props.structuredEssayPaperNaviationData,
          mode: 'PREVIEW',
          paperData: {
            unitId: paper.unitId || '',
            lessonId: paper.lessonId || '',
            unitIds: paper.unitIds || [],
            lessonIds: paper.lessonIds || [],
            subjectId: paper.subjectId,
            createdBy: paper.createdBy || '',
            paperId: paper.id || '',
            grade: paper.grade,
          },
        });

        if (paperStatus === PaperStatus.DRAFT) {
          Notification({
            isSuccess: true,
            message: 'Paper updated successfully as a draft',
          });
        } else {
          Notification({
            isSuccess: true,
            message: 'Paper updated successfully',
          });
        }

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        return Notification({
          isSuccess: false,
          message: 'Failed to update paper',
        });
      });
  };

  const types = ['application/pdf'];

  //-----------------------For Question Paper---------------------
  const changeEssayPaperHandler = (e: any) => {
    let selected = e.target.files[0] as File;

    if (selected && types.includes(selected.type)) {
      const size = selected.size / 1024 / 1024;
      if (size > 1) {
        Notification({
          isSuccess: false,
          message: 'File size should be less than 1 MB',
        });
        return;
      }

      const storageRef = storage.ref().child(`EssayPapers/${+new Date()}_${selected?.name}`);

      let paperURL: any = null;

      props.setState((ps) => ({
        ...ps,
        paper: { ...ps.paper, loading: true },
      }));

      storageRef.put(selected).on(
        'state_changed',
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;

          props.setState((ps) => ({
            ...ps,
            paper: {
              ...ps.paper,
              fileUploadedProgress: percentage,
            },
          }));
        },
        () => {
          Notification({
            isSuccess: false,
            message: 'Failed to upload PDF. ',
          });
          props.setState((ps) => ({
            ...ps,
            paper: {
              ...ps.paper,
              file: null,
              error: 'Failed to upload PDF',
              value: '',
              loading: false,
            },
          }));
        },
        async () => {
          paperURL = await storageRef.getDownloadURL();
          props.setState((ps) => ({
            ...ps,
            paper: {
              ...ps.paper,
              file: selected,
              paperName: selected.name,
              error: '',
              value: paperURL || '',
              loading: false,
            },
          }));
          Notification({
            isSuccess: true,
            message: 'Question paper uploaded successfully',
          });
        }
      );
    } else {
      if (types.includes(selected.type)) {
        Notification({
          isSuccess: false,
          message: 'File size should be less than 1 MB',
        });
      } else {
        Notification({
          isSuccess: false,
          message: 'Only PDF files are accepted for upload',
        });
      }
      props.setState((ps) => ({
        ...ps,
        paper: {
          ...ps.paper,
          file: null,
          error: 'Only PDF files are accepted for upload',
          value: '',
        },
      }));
    }
  };

  //-----------------------------For Marking scheme-----------------------------
  const EssayPaperMarkingschemeHandler = (e: any) => {
    let selected = e.target.files[0] as File;

    if (selected && types.includes(selected.type)) {
      const size = selected.size / 1024 / 1024;
      if (size > 1) {
        Notification({
          isSuccess: false,
          message: 'File size should be less than 1 MB',
        });
        return;
      }

      const storageRef = storage.ref().child(`EssayMarkingScheme/${+new Date()}_${selected?.name}`);

      let markingSchemeURL: any = null;

      props.setState((ps) => ({
        ...ps,
        markingScheme: { ...ps.markingScheme, loading: true },
      }));

      storageRef.put(selected).on(
        'state_changed',
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;

          props.setState((ps) => ({
            ...ps,
            markingScheme: {
              ...ps.markingScheme,
              fileUploadedProgress: percentage,
            },
          }));
        },
        () => {
          Notification({
            isSuccess: false,
            message: 'Failed to upload PDF. ',
          });
          props.setState((ps) => ({
            ...ps,
            markingScheme: {
              ...ps.markingScheme,
              file: null,
              error: 'Failed to upload PDF',
              value: '',
              loading: false,
            },
          }));
        },
        async () => {
          markingSchemeURL = await storageRef.getDownloadURL();
          props.setState((ps) => ({
            ...ps,
            markingScheme: {
              ...ps.markingScheme,
              file: selected,
              paperName: selected.name,
              error: '',
              value: markingSchemeURL || '',
              loading: false,
            },
          }));
          Notification({
            isSuccess: true,
            message: 'Marking Scheme uploaded successfully',
          });
        }
      );
    } else {
      if (types.includes(selected.type)) {
        Notification({
          isSuccess: false,
          message: 'File size should be less than 1 MB',
        });
      } else {
        Notification({
          isSuccess: false,
          message: 'Please select a pdf file',
        });
      }
      props.setState((ps) => ({
        ...ps,
        markingScheme: {
          ...ps.markingScheme,
          file: null,
          error: 'Please select an PDF file',
          value: '',
        },
      }));
    }
  };

  // const removeStructureEssayURLRef = useFunctions().httpsCallable('removeStructureEssayURL');
  const removeStructureEssayURLFirestoreRef = useFunctions().httpsCallable(
    'removeStructureEssayURLFirestore'
  );

  const removePaperFileReferenceFromFirestore = () => {
    if (props.structuredEssayPaperNaviationData.paperData?.paperId) {
      removeStructureEssayURLFirestoreRef({
        paperId: props.structuredEssayPaperNaviationData.paperData?.paperId,
        type: 'PAPER',
      })
        .then(() => {
          setLoading(false);
          props.setState((ps) => ({
            ...ps,
            paper: {
              ...ps.paper,
              file: null,
              error: '',
              value: '',
              loading: false,
              paperName: '',
              fileUploadedProgress: 0,
            },
          }));

          Notification({
            isSuccess: true,
            message: 'Question paper removed successfully',
          });
        })
        .catch(() => {
          setLoading(false);
          Notification({
            isSuccess: false,
            message: 'Failed to remove Paper from.',
          });
        });
    } else {
      setLoading(false);
      props.setState((ps) => ({
        ...ps,
        paper: {
          ...ps.paper,
          file: null,
          error: '',
          value: '',
          loading: false,
          paperName: '',
          fileUploadedProgress: 0,
        },
      }));

      Notification({
        isSuccess: true,
        message: 'Question paper removed successfully',
      });
    }
  };

  //---------Remove PDFs from Essay papers-------------
  const removePaperFromStorage = (url: string) => {
    setLoading(true);
    storage
      .refFromURL(url)
      .delete()
      .then(() => {
        removePaperFileReferenceFromFirestore();
      })
      .catch((err) => {
        removePaperFileReferenceFromFirestore();
      });
  };

  const removeMarkingFileReferenceFromFirestore = () => {
    if (props.structuredEssayPaperNaviationData.paperData?.paperId) {
      removeStructureEssayURLFirestoreRef({
        paperId: props.structuredEssayPaperNaviationData.paperData?.paperId,
        type: 'MARKING',
      })
        .then(() => {
          setLoading(false);
          props.setState((ps) => ({
            ...ps,
            markingScheme: {
              ...ps.markingScheme,
              file: null,
              error: '',
              value: '',
              loading: false,
              markingSchemeName: '',
              fileUploadedProgress: 0,
            },
          }));

          Notification({
            isSuccess: true,
            message: 'Marking Scheme removed.',
          });
        })
        .catch(() => {
          setLoading(false);
          Notification({
            isSuccess: false,
            message: 'Failed to remove marking scheme.',
          });
        });
    } else {
      setLoading(false);
      props.setState((ps) => ({
        ...ps,
        markingScheme: {
          ...ps.markingScheme,
          file: null,
          error: '',
          value: '',
          loading: false,
          markingSchemeName: '',
          fileUploadedProgress: 0,
        },
      }));
      Notification({
        isSuccess: true,
        message: 'Marking Scheme removed.',
      });
    }
  };

  //---------Remove PDFs from Essay Marking Scheme-------------
  const removeMarkingSchemeFromStorage = (url: string) => {
    setLoading(true);
    storage
      .refFromURL(url)
      .delete()
      .then(() => {
        removeMarkingFileReferenceFromFirestore();
      })
      .catch((err) => {
        removeMarkingFileReferenceFromFirestore();
      });
  };

  return (
    <div className="text-white" style={{ marginTop: '100px' }}>
      {loading && <LoadingIndicator />}
      <div className="StructuredPapers-Uploader-step-2">
        <section className="" style={{ width: '50%' }}>
          <div className="add-subject-modal__header" style={{ color: '#73847F' }}>
            Upload Question Paper
          </div>

          <PDFUploaderComp
            style={{ marginTop: 36 }}
            stateName="paper"
            stateValue={truncate(props.state.paper.file?.name || props.state.paper.paperName, 40)}
            state={props.state}
            file={props.state.paper.file}
            progress={props.state.paper.fileUploadedProgress}
            setState={props.setState}
            error={props.state.paper.error}
            placeHolder="File Name"
            fileLoading={props.state.paper.loading}
            onChange={changeEssayPaperHandler}
            removeFromStorage={removePaperFromStorage}
            url={props.state.paper.value}
          />
        </section>

        {/* ----------Marking Scheme---------------- */}
        <section className="mt-5" style={{ width: '50%' }}>
          <div className="add-subject-modal__header" style={{ color: '#73847F' }}>
            Upload Marking Scheme
          </div>
          <PDFUploaderComp
            style={{ marginTop: 36 }}
            stateName="markingScheme"
            stateValue={truncate(
              props.state.markingScheme.file?.name || props.state.markingScheme.markingSchemeName,
              40
            )}
            state={props.state}
            file={props.state.markingScheme.file}
            progress={props.state.markingScheme.fileUploadedProgress}
            setState={props.setState}
            error={props.state.markingScheme.error}
            placeHolder="File Name"
            fileLoading={props.state.markingScheme.loading}
            onChange={EssayPaperMarkingschemeHandler}
            removeFromStorage={removeMarkingSchemeFromStorage}
            url={props.state.markingScheme.value}
          />
        </section>
      </div>
      <div
        className="footer-btn d-flex mt-5"
        style={{ gap: '50px', position: 'absolute', bottom: '40px' }}
      >
        <div
          className={` ${
            props.state.paper.loading || props.state.markingScheme.loading
              ? 'btn-disable-add-sub'
              : ''
          }`}
          onClick={() => {}}
        >
          <ButtonComp
            type="one"
            text={`${props.structuredEssayPaperNaviationData.mode === 'EDIT' ? 'Update' : 'Save'}`}
            onClick={() => {
              if (props.structuredEssayPaperNaviationData.mode === 'EDIT') {
                updateHandler(PaperStatus.DRAFT);
              } else {
                createHandler(PaperStatus.DRAFT);
              }
            }}
            loading={props.state.paper.loading || props.state.markingScheme.loading || loading}
          />
        </div>
        <div
          className={` ${
            props.state.paper.loading || props.state.markingScheme.loading
              ? 'btn-disable-add-sub'
              : ''
          }`}
        >
          <ButtonComp
            type="one"
            text={`${
              props.structuredEssayPaperNaviationData.mode === 'EDIT' ? 'Update' : 'Save'
            } and Publish`}
            onClick={() => {
              if (props.structuredEssayPaperNaviationData.mode === 'EDIT') {
                updateHandler(PaperStatus.PUBLISHED);
              } else {
                createHandler(PaperStatus.PUBLISHED);
              }
            }}
            loading={props.state.paper.loading || props.state.markingScheme.loading || loading}
          />
        </div>
        <div
          className={` ${
            props.state.paper.loading || props.state.markingScheme.loading
              ? 'btn-disable-add-sub'
              : ''
          }`}
        >
          <ButtonComp
            style={{ background: 'transparent', gap: '20px' }}
            type={'one'}
            text={'Back'}
            onClick={() => {
              props.setStructuredEssayPaperNaviationData({
                ...props.structuredEssayPaperNaviationData,
                step: ST_ES_PAPER_BREADCRUM_STEPS.STEP_ONE,
              });
            }}
            loading={props.state.paper.loading || props.state.markingScheme.loading || loading}
          />
        </div>
      </div>
    </div>
  );
};

export default EssayPaperFileUploader;
