import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { FaFilePdf, FaLink } from 'react-icons/fa';
import { useAppContent } from '../../context/ContentContext';
import { Item, Links, VideoItem } from '../../interfaces/LocalContentState';
import { CONTENT_MEDIA_TYPE } from '../../utils';
import renderHTML from 'react-render-html';
import './ChapterPreview.scss';
import truncate from 'truncate';
import VideoPlayer from '../../../../components/VideoPlayer';

interface Props {
  droppableId: string;
}

const ChapterPreview: FC<Props> = (props) => {
  const appContent = useAppContent();

  const CONTENTS = appContent.contentData.find(
    (data) => data.droppableId === appContent.mainDroppableId
  )?.contentItems;

  const getMaterialNameFromUrl = (url: string) => {
    return truncate(url.substring(url.indexOf('mns') + 3, url.indexOf('mne')), 20);
  };

  return (
    <Container className="content-main-preview" fluid>
      <section className="content-main-preview__main">
        {CONTENTS?.map((val, idx) => {
          if (val.mediaType === CONTENT_MEDIA_TYPE.LINKS) {
            const item = val as {
              links: Links;
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };

            return item.links.linkGroups.map((v, index) => {
              return (
                <a
                  className={`d-flex mb-1 content-main-preview__links ${
                    item.links.linkGroups.length - 1 === index ? 'mb-3' : ''
                  }`}
                  href={v.url.value}
                  onClick={(event) => {
                    if (!v.url.value || v.url.value === '#') {
                      event.preventDefault();
                    }
                  }}
                  target="_blank"
                  rel="noreferrer"
                  key={idx + ' ' + index}
                >
                  <FaLink className="mr-2 " style={{ color: '#979797' }} /> {v.ref.value}
                </a>
              );
            });
          } else if (val.mediaType === CONTENT_MEDIA_TYPE.MATERIALS) {
            const item = val as {
              materialUrls: Item[];
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };

            const i = item.materialUrls.map((val, index) => {
              return (
                <div
                  className="d-flex mb-3 align-items-center"
                  style={{ marginRight: '2rem' }}
                  key={idx + ' ' + index}
                >
                  {/* <FaFileAlt className="mr-2 " size={40} /> */}
                  <FileIcon fileName={''} url={val.value} />
                  <div className="content-main-preview__filename">
                    {getMaterialNameFromUrl(decodeURI(val.value))}
                  </div>
                </div>
              );
            });

            return (
              <section className="pb-4 " style={{ marginTop: '4rem' }} key={idx}>
                <h4 className="content-main-preview-title__tertiary">Attachments:</h4>
                <div
                  className="d-flex"
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                  }}
                >
                  {i}
                </div>
              </section>
            );
          } else if (val.mediaType === CONTENT_MEDIA_TYPE.TEXT) {
            const item = val as {
              textContent: Item;
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };
            return (
              <section className="mb-3 content-text" key={idx}>
                {renderHTML(item.textContent.value || '')}
              </section>
            );
          } else if (val.mediaType === CONTENT_MEDIA_TYPE.VIDEO) {
            const item = val as {
              videoValue: VideoItem;
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };

            return (
              <div className="mb-3 content-main-preview__m-0" key={idx}>
                <VideoPlayer fileNameInUrl={item.videoValue.value} />
              </div>
            );
          } else if (val.mediaType === CONTENT_MEDIA_TYPE.QUIZ) {
            return <></>;
          } else {
            return <></>;
          }
        })}
      </section>
    </Container>
  );
};

export default ChapterPreview;

const FileIcon = (props: { fileName: string; url: string }) => {
  return (
    <div
      className="fi-wrapper"
      onClick={() => {
        const link = document.createElement('a');
        link.href = props.url;
        link.setAttribute('download', props.fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      }}
    >
      <div className="file-icon">
        <div style={{ width: '2.9rem', height: '2.9rem' }}>
          <FaFilePdf style={{ fontSize: '1.5rem' }} className="micon" />
        </div>
      </div>
      <div className="fifn" style={{ wordBreak: 'break-all' }}>
        {props.fileName}
      </div>
    </div>
  );
};
