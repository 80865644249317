import React, { FC, Fragment, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import { useFirestore, useFunctions } from 'reactfire';
import InitialView from '../../../../components/InitialView';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import LinksContent from '../../components/LinksContent';
import MaterialContentCopy from '../../components/MaterialContentCopy';
import VideoContent from '../../components/VideoContent';
import { useAppContent } from '../../context/ContentContext';
import { LocalContentState } from '../../interfaces/LocalContentState';
import AdminAndInstructorContentServices from '../../services/AdminAndInstructorContentServices';
import { CONTENT_MEDIA_TYPE } from '../../utils';
import ContentTopicListItem from './ContentTopicListItem';
import RoutingPaths from '../../routes/RoutingPaths';
import './ContentTopicListScreen.scss';
import TextContext from './TextContext';
import { FaUndo } from 'react-icons/fa';
import Notification from './../../../../components/Notification';
import AlertPopup from '../../../../components/AlertPopup';

interface Props {
  subjectId: string;
  lessonId: string;
  unitId: string;
  contentId: string;
  droppableId: string;
  setContentIdHandler: (data: string) => void;
}

const ContentTopicList: FC<Props> = (props) => {
  const createContentRef = useFunctions().httpsCallable('createContent');
  // const initialContent1 = [
  //   {
  //     id: '1',
  //     name: 'Lion',
  //   },
  //   {
  //     id: '2',
  //     name: 'Tiger',
  //   },
  // ];

  const history = useHistory();

  // const [draftContent] = useState(initialContent1);

  const appContent = useAppContent();

  const store = useFirestore();

  const [loading, setLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string>('');

  const onFinishHander = (val: any) => {
    if (val.data.status === 400) {
      appContent.setLoading(false);
      return setSubmitError(val.data.data);
    }
    appContent.setLoading(false);
    Notification({
      isSuccess: true,
      message: `Content saved successfully`,
    });
  };

  const onProgressHandler = () => {
    appContent.setLoading(true);
  };

  const removeComponents = () => {
    for (let i = 0; i < appContent.contentData.length; i++) {
      for (let j = 0; j < appContent.contentData[i].contentItems.length; j++) {
        appContent.contentData[i].contentItems.forEach((v) => {
          v.component = null;
        });
      }
    }
  };

  const fetchData = () => {
    setLoading(true);
    store
      .collection('content')
      .where('subjectId', '==', props.subjectId || '')
      .where('lessonId', '==', props.lessonId || '')
      .where('unitId', '==', props.unitId || 'noUnit')
      .get()
      .then((res) => {
        const resData = { ...res.docs[0].data() } as {
          contentData: LocalContentState[];
          subjectId: string;
          lessonId: string;
          unitId: string | undefined;
        };

        setLoading(false);

        setIndexHandler(resData.contentData);
        props.setContentIdHandler(res.docs[0].id);
      })
      .catch(() => {
        setLoading(false);
        setIndexHandler([]);
      });
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subjectId, props.lessonId, props.unitId]);

  // useEffect(() => {

  //     setIndexHandler(appContent.contentData.find((val) => val.droppableId === lastElementIndexString)?.contentItems)
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [appContent.contentData.length])

  const setIndexHandler = (localContentStateArray: LocalContentState[]) => {
    const resultLocalContentState: LocalContentState[] = [...localContentStateArray];

    for (let i = 0; i < localContentStateArray.length; i++) {
      const droppableId = localContentStateArray[i].droppableId;

      const ha = localContentStateArray[i].contentItems;

      for (let j = 0; j < ha.length; j++) {
        let contentItemDroppableId = ha[j].contentItemDroppableId;

        if (ha[j].mediaType === CONTENT_MEDIA_TYPE.LINKS) {
          // let links = resultLocalContentState[i].contentItems[j] as { links: Links, mediaType: CONTENT_MEDIA_TYPE, contentItemDroppableId: string, component: any }
          // links = { ...initialLinkSegmentValue, component: <LinksContent contentItemDroppableId={contentItemDroppableId} droppableId={droppableId} /> }]
          // resultLocalContentState[i].contentItems[j] = links

          resultLocalContentState[i].contentItems[j].component = (
            <LinksContent
              contentItemDroppableId={contentItemDroppableId}
              droppableId={droppableId}
            />
          );
        } else if (ha[j].mediaType === CONTENT_MEDIA_TYPE.MATERIALS) {
          // let materials = resultLocalContentState[i].contentItems[j] as { materialUrls: Item[], mediaType: CONTENT_MEDIA_TYPE, contentItemDroppableId: string, component: any }
          // materials = { ...initialMaterialUrlsValue, component: <MaterialContentCopy contentItemDroppableId={contentItemDroppableId} droppableId={droppableId} /> }
          // resultLocalContentState[i].contentItems[j] = materials
          resultLocalContentState[i].contentItems[j].component = (
            <MaterialContentCopy
              contentItemDroppableId={contentItemDroppableId}
              droppableId={droppableId}
            />
          );
        } else if (ha[j].mediaType === CONTENT_MEDIA_TYPE.TEXT) {
          // let text = resultLocalContentState[i].contentItems[j] as { textContent: Item, mediaType: CONTENT_MEDIA_TYPE, contentItemDroppableId: string, component: any }
          // text = { ...initialTextSegmentValue, component: <TextContext contentItemDroppableId={contentItemDroppableId} droppableId={droppableId} /> }
          // resultLocalContentState[i].contentItems[j] = text
          resultLocalContentState[i].contentItems[j].component = (
            <TextContext
              contentItemDroppableId={contentItemDroppableId}
              droppableId={droppableId}
            />
          );
        } else if (ha[j].mediaType === CONTENT_MEDIA_TYPE.VIDEO) {
          // let vid = resultLocalContentState[i].contentItems[j] as { videoUrl: Item, mediaType: CONTENT_MEDIA_TYPE, contentItemDroppableId: string, component: any }
          // vid = { ...initialVideoSegmentValue, component: <VideoContent contentItemDroppableId={contentItemDroppableId} droppableId={droppableId} /> }
          // resultLocalContentState[i].contentItems[j] = vid

          resultLocalContentState[i].contentItems[j].component = (
            <VideoContent
              contentItemDroppableId={contentItemDroppableId}
              droppableId={droppableId}
            />
          );
        } else if (ha[j].mediaType === CONTENT_MEDIA_TYPE.QUIZ) {
        }
      }
    }

    appContent.setList && appContent.setList([...resultLocalContentState]);
  };

  function handleOnDragEnd(result: DropResult) {
    if (!result.destination) return;

    const items = Array.from(appContent.contentData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    appContent.setList(items);
    // appContent.setTinyMCEChangedTimeSnap();
  }

  useEffect(() => {
    appContent.setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return appContent.contentData.length > 0 ? (
    <Fragment>
      <div className="content-topic-list ">
        {loading ? <LoadingIndicator /> : <></>}

        <h5 className="content-topic-list__title" style={{ color: '#979797', fontSize: '1.1rem' }}>
          Published Content
        </h5>
        <ul className="content-topic-list__list">
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="publishTopicNames1">
              {(provided) => (
                <ul
                  className="characters"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{ listStyle: 'none' }}
                >
                  {appContent.contentData.map((listItem, index) => {
                    return (
                      <Draggable
                        key={listItem.droppableId}
                        draggableId={listItem.droppableId}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <ContentTopicListItem
                              text={listItem.contentTitle.value}
                              key={index}
                              className="mt-2"
                              subjectId={props.subjectId}
                              lessonId={props.lessonId}
                              unitId={props.unitId}
                              droppableId={listItem.droppableId}
                              contentId={props.contentId}
                            />
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </ul>

        <br></br>

        {/* <h5 className="content-topic-list__title" style={{ color: '#979797', fontSize: '1.1rem' }}>
        Unpublished Content
      </h5>
      <ul className="content-topic-list__list">
        {draftContent.map((d) => (
          <ContentTopicListItem
            text={d.name}
            key={d.id}
            className="mt-2"
            subjectId={props.subjectId}
            lessonId={props.lessonId}
            unitId={props.unitId}
            droppableId={''}
            contentId={props.contentId}
          />
        ))}
      </ul> */}

        <div className="content-topic-list__actions" style={{ marginTop: '3rem' }}>
          <button
            className="content-topic-list__save-btn"
            onClick={() => {
              removeComponents();

              const data = {
                content: {
                  contentData: appContent.contentData,
                  subjectId: props.subjectId,
                  lessonId: props.lessonId,
                  unitId: props.unitId || 'noUnit',
                },
                id: props.contentId,
              };

              new AdminAndInstructorContentServices(createContentRef).createContent(
                data,
                onProgressHandler,
                onFinishHander
              );
            }}
          >
            Save
          </button>
          <button
            className="content-topic-list__save-draft content-topic-list__save-draft--disabled"
            onClick={() => {}}
          >
            <FaUndo />
          </button>
        </div>
      </div>
      <AlertPopup
        message={submitError}
        header="Attention"
        isShow={!!submitError}
        onOk={() => {
          setSubmitError('');
        }}
        onClose={() => {
          setSubmitError('');
        }}
      />
    </Fragment>
  ) : (
    <Fragment>
      {appContent.loading ? <LoadingIndicator /> : <></>}

      <InitialView
        title={`This ${props.unitId ? 'unit' : 'lesson'} is empty`}
        description={`This ${
          props.unitId ? 'unit' : 'lesson'
        } does not contain any content. Click the button below to add new content to the ${
          props.unitId ? 'unit' : 'lesson'
        }`}
        btnTitle="Add Content"
        btnOnClick={() => {
          history.push(
            new RoutingPaths(
              props?.subjectId || '-',
              props.lessonId || '-',
              props?.unitId
            ).createContent()
          );
        }}
      />
    </Fragment>
  );
};

export default ContentTopicList;
