import firebase from 'firebase';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { CommonAccessCode } from '../models/CommonAccessCode';
import { sortByCommonAccessCodesName } from '../utils';

export interface CommonAccessCodeContext {
  commonAccessCodes: CommonAccessCode[];
  isLoading: boolean;
  fetchCommonAccessCodes: () => void;
  setLoading: (value: boolean) => void;
  addCommonAccessCode: (commonAccessCode: CommonAccessCode) => void;
}

interface State {
  commonAccessCodes: CommonAccessCode[];
  isLoading: boolean;
}

const initialContext: CommonAccessCodeContext = {
  commonAccessCodes: [],
  isLoading: false,
  fetchCommonAccessCodes: () => { },
  setLoading: (value: boolean) => { },
  addCommonAccessCode: (commonAccessCodes: CommonAccessCode) => { },
};
const initialState: State = {
  commonAccessCodes: [],
  isLoading: false,
};

const Context = createContext<CommonAccessCodeContext>(initialContext);

function getUniqueListBy(arr: any[]) {
  const ids = arr.map((o: any) => o.id);
  const filtered = arr.filter(({ id }: any, index: any) => !ids.includes(id, index + 1));
  return filtered;
}

export const CommonAccessCodeProvider: FC = ({ children }) => {
  const [state, setState] = useState(initialState);


  useEffect(() => {
    fetchCommonAccessCodes();
  }, []);

  const fetchCommonAccessCodes = () => {
    const CommonAccessCodes: CommonAccessCode[] = [];

    firebase
      .firestore()
      .collection('commonAccessCodes')
      .get()
      .then(data => {
        data.forEach(doc => {
          CommonAccessCodes.push({ id: doc.id, ...doc.data() } as any);
        });

        setState(ps => {
          return {
            ...ps,
            commonAccessCodes: getUniqueListBy(CommonAccessCodes)
          };
        });
      })
      .catch(e => {
        console.log('ERROR', e);
      });
  };

  const addCommonAccessCode = (commonAccessCode: CommonAccessCode) => {
    const currentCommonAccessCodes = [...state.commonAccessCodes];
    currentCommonAccessCodes.push(commonAccessCode);
    setState((ps) => {
      return {
        ...ps,
        commonAccessCodes: sortByCommonAccessCodesName(currentCommonAccessCodes),
      };
    });
  };

  const setLoading = (value: boolean) => {
    setState((ps) => ({
      ...ps,
      isLoading: value,
    }));
  };

  return (
    <Context.Provider
      value={{
        ...state,
        fetchCommonAccessCodes,
        setLoading,
        addCommonAccessCode,

      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useCommonAccessCodes = (): CommonAccessCodeContext => useContext(Context);
