import { CONTENT_MEDIA_TYPE } from '../utils';

export interface LocalContentState {
  contentTitle: Item;
  contentItems: [
    { links: Links; mediaType: CONTENT_MEDIA_TYPE; contentItemDroppableId: string; component: any },
    {
      textContent: Item;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    },
    {
      videoValue: VideoItem;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    },
    {
      materialUrls: Item[];
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
      error?: string;
    }
  ];
  droppableId: string;
  inPreviewChapterReadCheck?: string;
  chapterReadCheckUids?: string[];
}

export const initLink: Links = {
  linkGroups: [
    {
      ref: { value: '', error: '' },
      url: { value: '', error: '' },
    },
  ],
};

export interface Links {
  linkGroups: {
    ref: {
      value: string;
      error: string;
    };
    url: {
      value: string;
      error: string;
    };
  }[];
}

export interface Item {
  value: string;
  error: string;
}

export interface VideoItem {
  value: string;
  videoName: string;
  error: string;
}

export const iniComps = [
  {
    links: initLink,
    mediaType: CONTENT_MEDIA_TYPE.LINKS,
    contentItemDroppableId: '0',
    component: null,
  },
  {
    textContent: { value: '', error: '' },
    mediaType: CONTENT_MEDIA_TYPE.TEXT,
    contentItemDroppableId: '1',
    component: null,
  },
  {
    videoValue: { value: '', videoName: '', error: '' },
    mediaType: CONTENT_MEDIA_TYPE.VIDEO,
    contentItemDroppableId: '2',
    component: null,
  },
  {
    materialUrls: [],
    mediaType: CONTENT_MEDIA_TYPE.MATERIALS,
    contentItemDroppableId: '3',
    component: null,
  },
];

export const initialLinkSegmentValue = {
  links: initLink,
  mediaType: CONTENT_MEDIA_TYPE.LINKS,
  contentItemDroppableId: '0',
  component: null,
};
export const initialTextSegmentValue = {
  textContent: { value: '', error: '' },
  mediaType: CONTENT_MEDIA_TYPE.TEXT,
  contentItemDroppableId: '1',
  component: null,
};
export const initialVideoSegmentValue = {
  videoValue: { value: '', videoName: '', error: '' },
  mediaType: CONTENT_MEDIA_TYPE.VIDEO,
  contentItemDroppableId: '2',
  component: null,
};
export const initialMaterialUrlsValue = {
  materialUrls: [],
  mediaType: CONTENT_MEDIA_TYPE.MATERIALS,
  contentItemDroppableId: '3',
  component: null,
};

export const initialLocalContentState: LocalContentState[] = [
  {
    contentTitle: { value: '', error: '' },
    contentItems: [
      initialLinkSegmentValue,
      initialTextSegmentValue,
      initialVideoSegmentValue,
      initialMaterialUrlsValue,
    ],
    droppableId: '0',
  },
];
