import React, { FC, useEffect, useState } from 'react';
// import DownArrow from './DownArrow';
import SwitchBtn from './SwitchBtn';
import './AssignSubjects.scss';
import { useFunctions } from 'reactfire';
import Notification from '../components/Notification';
import { UserFirestore } from '../models/UserFirestore';

interface Props {
  user: any;
  setUser: (value: any) => void;
  userRosterPackagesModalHandler: (value: boolean) => void;
  setIsLoading?: (value: boolean) => void;
  loading: boolean;
  initSubjectAssignedStudents?: UserFirestore[];
  studentStatusHandler?: (assignedStudents: any) => void;
}

const AssignedSubjectsStudentCard: FC<Props> = (props) => {
  const changeUserEnableStatusRef = useFunctions().httpsCallable('changeUserEnableStatus');

  const [checkState, setCheckState] = useState<{ checked: boolean | undefined }>({
    checked: props.user?.enabled,
  });

  useEffect(() => {
    setCheckState((ps) => {
      return { ...ps, checked: props.user?.enabled };
    });
  }, [props.user.enabled]);

  //Switch btn handler
  const changeHandler = (value: boolean) => {
    props.setIsLoading && props.setIsLoading(true);

    changeUserEnableStatusRef({
      id: props.user.uid,
      value: value,
    })
      .then((result) => {
        props.setIsLoading && props.setIsLoading(false);

        const updatedStatusStudent = { ...props.user, enabled: value };

        const assignedStudents = props.initSubjectAssignedStudents?.filter(
          (user) => user.uid !== props.user.uid
        );

        assignedStudents?.push(updatedStatusStudent);

        props.studentStatusHandler && props.studentStatusHandler(assignedStudents);

        Notification({
          isSuccess: true,
          message: value
            ? `${props.user.username} was activated successfully!`
            : `${props.user.username}  was deactivated successfully!`,
        });
      })
      .catch((e) => {
        Notification({
          isSuccess: false,
          message: `${
            value ? 'Activation failed!' : 'Deactivation failed!'
          }, Please try again later.`,
        });
        console.log('Error ', e);
      })
      .finally(() => {
        props.setIsLoading && props.setIsLoading(false);
      });
  };

  return (
    <>
      <div className="settings__roaster-body__content__users" key={props.user.id}>
        <div className="settings__roaster-body__content__users__name">
          {/* <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="verifiedId">{props.user.username}</Tooltip>}
          >
            <div>{truncate(props.user.username || '', 35)}</div>
          </OverlayTrigger> */}
          {props.user.firstName} {props.user.lastName}
        </div>

        <div
          className="settings__roaster-body__content__users__packages"
          onClick={() => {
            props.setUser(props.user);
          }}
        >
          {/* <DownArrow userRosterPackagesModalHandler={props.userRosterPackagesModalHandler} />{' '} */}
          <span className="d-inline-block">{props.user.email}</span>
        </div>

        <div className="settings__roaster-body__content__users__status">
          Enrolled: {props.user.enrolled || <span className="d-inline-block ml-3"> - </span>}
          <span
            style={{
              margin: '0 10px',
              marginLeft: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {props.user.enabled ? (
              <div style={{ width: '50px' }}>Active</div>
            ) : (
              <div style={{ width: '50px' }}>Inactive</div>
            )}
            <div className="pl-1">
              <SwitchBtn
                changeHandler={changeHandler}
                checked={checkState.checked}
                loading={props.loading}
                id={props.user.id}
              />
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export default AssignedSubjectsStudentCard;
