import React, { FC } from 'react';
import { FaEye, FaFileAlt, FaFire, FaTextHeight, FaVideo } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { useAppContent } from '../../context/ContentContext';
import RoutingPaths from '../../routes/RoutingPaths';
import Notification from './../../../../components/Notification';
import './AddContentHeader.scss';

interface Props {
  subjectId: string;
  lessonId: string;
  unitId?: string;
}

const UpdateContentHeader: FC<Props> = (props) => {
  const appContent = useAppContent();

  const history = useHistory();

  return (
    <>
      <header className="add-content-header">
        <div className="add-content-header__title">
          <h5 className="add-content-header__title-txt">Update Content</h5>
          <div
            className="add-content-header__eye"
            onClick={() => {
              appContent.setPreviousPathToPreview(history.location.pathname);

              history.push(
                new RoutingPaths(
                  props.subjectId,
                  props.lessonId,
                  props.unitId,
                  appContent.mainDroppableId
                ).previewChapterContent()
              );
            }}
          >
            <FaEye />
          </div>
        </div>
        <div
          className="add-content-header__actions"
          style={{
            marginRight: '1.5rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <button
            className="add-content-header__btn"
            onClick={() => {
              appContent.addNewTextContentSlotHandler(appContent.mainDroppableId);
              Notification({
                isSuccess: true,
                message: 'Added Text Content',
                autoClose: 400,
              });
            }}
          >
            <span>{<FaTextHeight className="mr-2" size={15} />}</span>Add Text
          </button>

          <button
            style={{ marginLeft: '1rem' }}
            className="add-content-header__btn"
            onClick={() => {
              appContent.addNewLinkSlotHandler(appContent.mainDroppableId);
              Notification({
                isSuccess: true,
                message: 'Added Link Content',
                autoClose: 400,
              });
            }}
          >
            <span>{<FaFire className="mr-2" size={15} />}</span>Add Links
          </button>

          <button
            style={{ marginLeft: '1rem' }}
            className="add-content-header__btn"
            onClick={() => {
              appContent.addNewVideoSlotHandler(appContent.mainDroppableId);
              Notification({
                isSuccess: true,
                message: 'Added Video Content',
                autoClose: 400,
              });
            }}
          >
            <span>{<FaVideo className="mr-2" size={15} />}</span>Video/Audio
          </button>

          <button
            style={{ marginLeft: '1rem' }}
            className="add-content-header__btn"
            onClick={() => {
              appContent.addNewExtraMaterialsSlotHandler(appContent.mainDroppableId);

              Notification({
                isSuccess: true,
                message: 'Added Material Content',
                autoClose: 400,
              });
            }}
          >
            <span>{<FaFileAlt className="mr-2" size={15} />}</span>Add Material
          </button>
        </div>
      </header>
      {appContent.loading ? <LoadingIndicator /> : <></>}
    </>
  );
};

export default UpdateContentHeader;
