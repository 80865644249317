import React, { FC, Fragment, useEffect, useRef } from 'react';
import { FaRegFilePdf } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import './PDFUploaderComp.scss';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import TextInput from './TextInput';

interface Props {
  style?: React.CSSProperties;
  stateName: string;
  stateValue: string;
  state: any;
  file: null | File;
  progress: number;
  setState: Function;
  error: string;
  placeHolder: 'File Name';
  fileLoading: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeFromStorage: (url: string, paperId: string) => void;
  url: string;
}

const PDFUploaderComp: FC<Props> = (props) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (!props.url) {
      ref.current = null;
    }
  }, [props.url]);

  return (
    <>
      <div className="add-subject-modal-inputs">
        <div className="cover-imageST" style={{ ...props.style }}>
          <TextInput
            stateName={props.stateName}
            stateValue={props.stateValue}
            state={props.state}
            setState={props.setState}
            error=""
            placeHolder={props.placeHolder}
          />

          <div className="add-cover-image-btn-wrap position-relative">
            <input
              type="file"
              className="position-absolute"
              style={{ opacity: 0 }}
              onChange={(e) => {
                if (!props.fileLoading && !props.stateValue) {
                  props.onChange(e);
                }
              }}
              ref={ref}
              value=""
            />
            <button className="add-cover-image-btn">Upload PDF</button>
          </div>
        </div>
      </div>
      {props.url ? (
        <div
          className="add-subject-modal-image position-relative d-flex"
          style={{ marginTop: '24px', left: '-20px' }}
        >
          {props.stateValue && (
            <div className="col-auto mr-2 ">
              <div
                className="material-container"
                style={{
                  width: '70px',
                  height: '85px',
                  borderRadius: '8px',
                  backgroundColor: '#2c2e41',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    color: '#474A66',
                  }}
                >
                  <FaRegFilePdf
                    className="pdf-icon"
                    style={{
                      height: '30px',
                      width: '30px',
                      transform: 'translate(0px, 0px)',
                      textAlign: 'center',
                    }}
                  />
                </div>
              </div>
              <IoMdClose
                className="close-icon"
                style={{
                  position: 'absolute',
                  top: 40,
                  right: 50,
                  fontSize: 20,
                  color: '#F05A5A',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  props.removeFromStorage(props.url, props.state.paperId);
                }}
              />
            </div>
          )}

          <span
            className="pdf-title"
            style={{
              fontSize: 14,
              fontWeight: 400,
              maxWidth: '250px',
              transform: 'translate(0px, 0px)',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {props.stateValue}
          </span>
        </div>
      ) : props.fileLoading ? (
        <Fragment>
          <div
            className="vh-100 vw-100"
            style={{ top: 0, left: 0, position: 'fixed', zIndex: 1 }}
          ></div>
          <div
            style={{
              height: 50,
              width: 50,
            }}
            className="align-self-center mt-3"
          >
            <CircularProgressbar
              value={props.progress}
              text={props.progress?.toFixed(0) + '%'}
              styles={buildStyles({
                textColor: '#fff',
                pathTransitionDuration: 0.5,
                pathColor: '#246BFD',
                trailColor: '#fff',
                backgroundColor: '#fff',
                textSize: '1.5rem',
                pathTransition: 'none',
              })}
            />
          </div>
        </Fragment>
      ) : null}
    </>
  );
};

export default PDFUploaderComp;
