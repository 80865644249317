import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFunctions, useStorage } from 'reactfire';

import {
  allocatedTimeHoursAndMinutes,
  convertArrayToDDHHMMSS,
  convertUTCDateToLocalDate,
  getUserExamStartTime,
} from '../../utils';
import truncate from 'truncate';
import { useAppUser } from '../../context/UserContext';
import { ExamToDoDTO } from '../../dtos/ExamToDoDTO';
import useSessionStorage from '../../hooks/useSessionStorage';
import { ExaminationNavStateAndCheckpointState } from '../../interfaces';
import { PaperTypes } from '../../enums/paperTypes';
import { QuestionTypes } from '../../enums/questionTypes';
import { PaperQuestionDTO } from '../../dtos/PaperQuestionDTO';
import { Answer } from '../../models/Answer';
import { useCountDown } from '../../hooks/useCountDown';
import { MCQPaperQuestion } from '../../models/Question';
import Backarrow from '../../components/Backarrow';
import HamburgerIcon from '../../components/HamburgerIcon';
import PrimaryNav from '../../components/PrimaryNav';
import { useMobileNav } from '../../context/MobNavContext';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { ExamType } from '../../enums/ExamType';
import TimeBlock from '../../components/TimeBlock';
import PDFUploaderCompExam from '../../components/PDFUploaderCompExam';
import LayoutNew from '../../layouts/LayoutNew';
import { SCREEN } from '../../constants';
import renderHTML from 'react-render-html';
import Notification from './../../components/Notification';
import MCQAnswerCard from '../../components/MCQAnswerCard';
import ExamHeaderNew from '../../components/ExamHeaderNew';
import TrueFalseAnswerCard from '../../components/TrueFalseAnswerCard';
import StructuredEssayRightPanelContent from '../../components/StructuredEssayRightPanelContent';
import ExamPapersInformation from '../../components/ExamPapersInformation';
import NavigatorRightPanelContent from '../../components/NavigatorRightPanelContent';
import './StudentExamAnswerScreen.scss';
import LoadingIndicator from '../../components/LoadingIndicator';
import AlertPopup from '../../components/AlertPopup';
import { useSubjects } from '../../context/SubjectsContext';
import firebase from 'firebase';
import Overlay from '../../shared/Overlay';
import toast, { Toaster } from 'react-hot-toast';
import useDebounce from '../../hooks/useDebounce';

const StudentExamAnswerScreen = () => {
  //hooks
  const location = useLocation();
  const history = useHistory();
  const user = useAppUser();
  const params = useParams() as { subjectId: string; examId: string };

  const examLocationData = location.state as {
    exam: ExamToDoDTO;
    examinationType: string;
    redo: boolean;
    usersExamState: any;
  };

  // console.log("LOCCC ", examLocationData.exam?.usersExamState)
  // console.log("getUserExamStartTime ", getUserExamStartTime(examLocationData.exam, user.firestoreUser?.uid))

  const redo = examLocationData?.redo;

  const [time] = useState<{ userExamStartTime: Date | null; loading: boolean }>(() => {
    return {
      userExamStartTime: getUserExamStartTime(examLocationData.exam, user.firestoreUser?.uid),
      loading: false,
    };
  });

  const [alert, setAlert] = useState<{ msg: string; code: number }>({ msg: '', code: 0 });

  const examinationType = examLocationData.exam.examType;

  const examFromLocation = examLocationData.exam;

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const appUser = useAppUser();

  let [navBtnClicked, setNavBtnClicked] = useState<number>(0);

  const EXAM_SESSION_ID = examLocationData.exam.id
    ? `EXAM_SESSION_${examFromLocation.id}USER_${appUser?.fireUser?.uid}`
    : `EXAM_SESSION_${examFromLocation.examId}USER_${appUser?.fireUser?.uid}`;

  const appSubjects = useSubjects();

  const [examinationNavState, setExaminationNavState] = useSessionStorage<
    string,
    ExaminationNavStateAndCheckpointState
  >(EXAM_SESSION_ID, { loading: false, exam: null, papers: [], paperNo: 0, examContinue: true });

  const [removingLoading, setRemovingLoading] = useState(false);

  const tabState =
    examinationNavState.exam?.examType === ExamType.EXTRA_EXAM
      ? 'extra'
      : examinationNavState.exam?.examType === ExamType.LIVE_EXAM
      ? 'live'
      : 'practice';

  const changePaper = (index: number) => {
    const state = { ...examinationNavState };
    state.papers[index].started = true;
    state.paperNo = index;
    setExaminationNavState(state);
    setNavBtnClicked(++navBtnClicked);
  };

  const startHandler = (idx: number) => {
    const state = { ...examinationNavState };
    state.examContinue = true;
    state.papers[idx].started = true;
    state.paperNo = idx;

    setExaminationNavState(state);
  };

  const setInitialState = () => {
    const stateObj: any = examFromLocation?.papers.map((p, index) => {
      if (p.paperType === PaperTypes.MCQ || p.paperType === PaperTypes.TRUEFALSE) {
        let allQuestions: any[] = [];
        let flag = true;

        p.questions.forEach((q, idx) => {
          if (flag) {
            q.seen = true;
            flag = false;
          } else {
            q.seen = false;
          }

          if (q.questionType === QuestionTypes.TRUEFALSE) {
            q.answers = [];
            q.answers.push({ answerText: 'True', isCorrect: false, isSelected: false });
            q.answers.push({ answerText: 'False', isCorrect: false, isSelected: false });
          }

          q.paperId = p.id;

          allQuestions.push(q);
        });

        return {
          allQuestions: [...allQuestions],
          currentQuestionIdx: 0,
          totalNoOfQuestions: p.totalNoOfQuestions,
          paperType: p.paperType,
          paperId: p.id,
          paperName: p.paperTitle,
          started: false,
          hours: allocatedTimeHoursAndMinutes(p.allocatedTime)[0],
          minutes: allocatedTimeHoursAndMinutes(p.allocatedTime)[1],
        };
      } else {
        return {
          paperName: p.paperTitle,
          paperUrl: p.structEssayData?.paperUrl || p.paperUrl || '',
          totalNoOfQuestions: p.totalNoOfQuestions,
          paperType: p.paperType,
          paperId: p.id,
          started: false,
          hours: allocatedTimeHoursAndMinutes(p.allocatedTime)[0],
          minutes: allocatedTimeHoursAndMinutes(p.allocatedTime)[1],
        };
      }
    });

    return setExaminationNavState((ps) => ({
      ...ps,
      papers: stateObj,
      exam: examFromLocation,
      examContinue: false,
    }));
  };

  const [restoreSessionLoader, setRestoreSessionLoader] = useState(false);

  // NOTE:GETS THE SAVED STATE
  useEffect(() => {
    var sessionState = sessionStorage.getItem(EXAM_SESSION_ID);

    if (sessionState !== 'null' && examinationNavState.exam) {
      try {
        const state = JSON.parse(sessionState!) as ExaminationNavStateAndCheckpointState;

        //If for moving to another tab abrubtly while uploding a paper
        state.papers = state.papers.map((p) => {
          if (p.paperType === PaperTypes.STRUCTURED || p.paperType === PaperTypes.ESSAY) {
            if (p.fileUploadedProgress > 0) {
              p.fileUploadedProgress = 0;
            }

            if (p.loadingInProgress === true) {
              p.loadingInProgress = false;
            }

            return p;
          }
          return p;
        });

        state.examContinue = true;

        setExaminationNavState(state);
      } catch (e) {
        setInitialState();
      }
    } else {
      setRestoreSessionLoader(true);

      firebase
        .firestore()
        .collection('ExamProgressCheckpoint')
        .where('examId', '==', examLocationData.exam.id || examLocationData.exam.id || '')
        .where('userId', '==', appUser.firestoreUser?.uid || '')
        .get()
        .then((checkpointReponse) => {
          if (checkpointReponse.empty) {
            setInitialState();
          } else {
            const state = {
              ...checkpointReponse.docs[0]?.data(),
            } as ExaminationNavStateAndCheckpointState;

            //If for moving to another tab abrubtly while uploding a paper
            state.papers = state.papers.map((p) => {
              if (p.paperType === PaperTypes.STRUCTURED || p.paperType === PaperTypes.ESSAY) {
                if (p.fileUploadedProgress > 0) {
                  p.fileUploadedProgress = 0;
                }

                if (p.loadingInProgress === true) {
                  p.loadingInProgress = false;
                }
                return p;
              }
              return p;
            });

            setExaminationNavState(state);
          }
          setRestoreSessionLoader(false);
        })
        .catch(() => {
          setInitialState();
          setRestoreSessionLoader(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedPaper = examinationNavState.papers[examinationNavState.paperNo];

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    if (!isOnline) {
      history.push('/404');
    }

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  // CHANGED HERE
  const checkPointData = {
    ...examinationNavState,
    examId: examinationNavState.exam?.id || '',
    userId: appUser.firestoreUser?.uid || '',
  };
  const saveExamProgressCheckpointRef = useFunctions().httpsCallable('saveExamProgressCheckpoint');
  useDebounce(navBtnClicked, 5000, (data) => {
    if (
      examinationNavState.exam?.id &&
      appUser.firestoreUser?.uid &&
      !examinationNavState.loading
    ) {
      toast.promise(
        saveExamProgressCheckpointRef(checkPointData)
          .then((res) => {
            console.log('checkpoint saved');
          })
          .catch(() => {
            console.log('exam state saved failure');
          }),
        {
          loading: 'Saving progress',
          success: 'Progress saved',
          error: 'Failed to save progress',
        },
        { id: 'hottoastid' }
      );
    }
  });

  let mcqCurrentQuestions: PaperQuestionDTO | null = null;
  let tfCurrentQuestions: PaperQuestionDTO | null = null;

  let body = <></>;

  //Logic
  const mcqAnswerSelect = (index: number, answer: Answer, isSelected?: boolean) => {
    answer.isSelected = !isSelected;

    if (
      (selectedPaper.paperType === PaperTypes.MCQ ||
        selectedPaper.paperType === PaperTypes.TRUEFALSE) &&
      examinationNavState
    ) {
      const state = { ...examinationNavState };

      const allQuestions = [...selectedPaper.allQuestions];

      const currentQuestion = allQuestions[selectedPaper.currentQuestionIdx] as PaperQuestionDTO;

      currentQuestion.answers[index] = answer;

      state.papers[state.paperNo].allQuestions = allQuestions;

      setExaminationNavState(state);
    }
  };

  const tfAnswerSelect = (answerLabel: string) => {
    if (
      (selectedPaper.paperType === PaperTypes.MCQ ||
        selectedPaper.paperType === PaperTypes.TRUEFALSE) &&
      examinationNavState
    ) {
      const state = { ...examinationNavState };

      const allQuestions = [...selectedPaper.allQuestions];

      const currentQuestion = allQuestions[selectedPaper.currentQuestionIdx] as PaperQuestionDTO;

      if (answerLabel === 'True') {
        currentQuestion.answers[0].isSelected = true;
        currentQuestion.answers[1].isSelected = false;
      } else {
        currentQuestion.answers[0].isSelected = false;
        currentQuestion.answers[1].isSelected = true;
      }

      state.papers[state.paperNo].allQuestions = allQuestions;

      setExaminationNavState(state);
    }
  };

  const moveToPreviousQuestion = () => {
    if (selectedPaper?.currentQuestionIdx) {
      if (!(selectedPaper.currentQuestionIdx <= 0)) {
        const state = { ...examinationNavState };

        state.papers[state.paperNo].currentQuestionIdx = selectedPaper.currentQuestionIdx - 1;

        setExaminationNavState(state);
      }
    }

    setNavBtnClicked(++navBtnClicked);
  };

  const moveToNextQuestion = () => {
    if (
      (selectedPaper.paperType === PaperTypes.MCQ ||
        selectedPaper.paperType === PaperTypes.TRUEFALSE) &&
      examinationNavState
    ) {
      const allQuestions = [...selectedPaper.allQuestions];

      if (!(selectedPaper.currentQuestionIdx + 1 >= selectedPaper.totalNoOfQuestions)) {
        const currentQuestion = allQuestions[selectedPaper.currentQuestionIdx + 1];
        currentQuestion.seen = true;

        const state = { ...examinationNavState };

        state.papers[state.paperNo].allQuestions = allQuestions;
        state.papers[state.paperNo].currentQuestionIdx = selectedPaper.currentQuestionIdx + 1;

        setExaminationNavState(state);
      }
    }

    setNavBtnClicked(++navBtnClicked);
  };

  const moveToNextPaper = () => {
    if (examinationNavState.papers.length - 1 !== examinationNavState.paperNo) {
      const updatedPaperNo = examinationNavState.paperNo + 1;
      const currentPaper = examinationNavState.papers[updatedPaperNo];
      currentPaper.started = true;

      const exNavState = { ...examinationNavState };
      exNavState.paperNo = updatedPaperNo;
      exNavState.papers[updatedPaperNo] = currentPaper;

      setExaminationNavState(exNavState);
    }

    setNavBtnClicked(++navBtnClicked);
  };

  const moveToPreviousPaper = () => {
    if (examinationNavState.papers.length - 1 !== 0) {
      const updatedPaperNo = examinationNavState.paperNo - 1;

      const currentPaper = examinationNavState.papers[updatedPaperNo];
      currentPaper.started = true;

      const exNavState = { ...examinationNavState };
      exNavState.paperNo = updatedPaperNo;
      exNavState.papers[updatedPaperNo] = currentPaper;

      setExaminationNavState(exNavState);
    }

    setNavBtnClicked(++navBtnClicked);
  };

  // const submitExamRef = useFunctions().httpsCallable('submitExam');
  const submitExamRefactoredRef = useFunctions().httpsCallable('submitExamRefactored');
  const submitDoneExamRef = useFunctions().httpsCallable('submitDoneExam');

  const submitExam = () => {
    if (examinationNavState) {
      if (!examinationNavState.loading) {
        setSubmitted(true);

        setExaminationNavState((ps) => ({ ...ps, loading: true }));

        const answeredPapers = examinationNavState.exam?.papers.map((p: any, index: number) => {
          if (p.paperType === PaperTypes.MCQ || p.paperType === PaperTypes.TRUEFALSE) {
            const relaventQuestions = examinationNavState.papers[index].allQuestions.filter(
              (v: any) => v.paperId === p.id
            );

            p.questions = relaventQuestions;
          } else {
            p.structEssayData = {
              ...p.structEssayData,
              answerSheetName: examinationNavState.papers[index].answerSheetName || '',
              answerSheetUrl: examinationNavState.papers[index]?.answerSheetUrl || '',
            };

            // p.structEssayData.answerSheetName =
            //   examinationNavState.papers[index].answerSheetName || '';

            // p.structEssayData.answerSheetUrl =
            //   examinationNavState.papers[index]?.answerSheetUrl || '';
          }

          return p;
        });

        const updatedExam = {
          ...examinationNavState.exam,
          papers: answeredPapers,
          userId: appUser.fireUser?.uid,
          subjectName: appSubjects.allSubjects.find((s) => s.id === params.subjectId)?.name || '',
          timeTaken: convertArrayToDDHHMMSS(timeArray[1]),
        };

        const submitRef = redo ? submitDoneExamRef : submitExamRefactoredRef;

        submitRef({ exam: updatedExam })
          .then((res) => {
            if (res.data.status === 410) {
              setExaminationNavState((ps) => ({ ...ps, loading: false }));
              return setAlert({ msg: res.data.data, code: 410 });
            }

            if (res.data.status === 409) {
              setExaminationNavState((ps) => ({ ...ps, loading: false }));
              return setAlert({ msg: 'This Exam is already submitted', code: 409 });
            }

            history.push({
              pathname: `/my-subjects/subject/${params.subjectId}/Examinations`,
              state: tabState,
            });
            setExaminationNavState((ps) => ({ ...ps, loading: false }));
            sessionStorage.removeItem(EXAM_SESSION_ID);
            Notification({
              isSuccess: true,
              message: 'The exam has submitted successfully',
            });
          })
          .catch((e) => {
            console.log('Error ', e);
            setExaminationNavState((ps) => ({ ...ps, loading: false }));
            Notification({
              isSuccess: false,
              message: 'Failed to submit exam',
            });
          })
          .finally(() => {
            // setExaminationNavState(null);useCountDown
          });
      }
    }
  };

  useEffect(() => {
    if (submitted && examFromLocation.examType !== ExamType.EXTRA_EXAM) {
      submitExam();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted]);

  //LEGACY DATA SUPPORT
  const timeArray = useCountDown(
    convertUTCDateToLocalDate(examFromLocation.finishTime),
    time.userExamStartTime,
    examinationType,
    setSubmitted,
    submitted,
    EXAM_SESSION_ID
  );

  const moveToSpecificQuestion = (idx: number) => {
    if (examinationNavState) {
      const allQuestions = [...selectedPaper.allQuestions];

      const currentQuestion = allQuestions[idx];
      currentQuestion.seen = true;
      const state = { ...examinationNavState };

      state.papers[state.paperNo].allQuestions = allQuestions;
      state.papers[state.paperNo].currentQuestionIdx = idx;

      setExaminationNavState(state);
      setNavBtnClicked(++navBtnClicked);
    }
  };

  const getAnsweredAmount = () => {
    if (
      (selectedPaper.paperType === PaperTypes.MCQ ||
        selectedPaper.paperType === PaperTypes.TRUEFALSE) &&
      examinationNavState
    ) {
      let count = 0;

      selectedPaper.allQuestions.forEach((item: any) => {
        const question = item as MCQPaperQuestion;

        let flag = false;

        question.answers?.forEach((i) => {
          if (i.isSelected) {
            flag = true;
          }
        });

        if (flag) {
          ++count;
        }
      });

      return count;
    } else {
      return 0;
    }
  };

  const types = ['application/pdf'];
  const storage = useStorage();

  const changeAnswerSheetHandler = (e: any) => {
    let selected = e.target.files[0] as File;

    if (!(selected && types.includes(selected.type))) {
      return setAlert((ps) => ({ ...ps, msg: 'Only PDF files are accepted for upload' }));
    }

    if (selected && types.includes(selected.type)) {
      const size = selected.size / 1024 / 1024;
      if (size > 1) {
        Notification({
          isSuccess: false,
          message: 'File size should be less than 1 MB',
        });
        return;
      }

      const storageRef = storage.ref().child(`AnswerSheets/${+new Date()}_${selected?.name}`);

      let paperURL: any = null;

      const state = { ...examinationNavState };
      const paper = state.papers[state.paperNo];
      paper.loadingInProgress = true;

      setExaminationNavState(state);

      storageRef.put(selected).on(
        'state_changed',
        (snap) => {
          const state = { ...examinationNavState };

          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;

          state.papers[state.paperNo].fileUploadedProgress = percentage;

          setExaminationNavState(state);
        },
        (err) => {
          Notification({
            isSuccess: false,
            message: 'Failed to upload PDF. ',
          });

          const state = { ...examinationNavState };

          const paper = state.papers[state.paperNo];
          paper.loadingInProgress = false;
          paper.file = null;
          paper.error = 'Failed to upload PDF';
          paper.answerSheetName = '';
          paper.answerSheetUrl = '';

          setExaminationNavState(state);
        },
        async () => {
          paperURL = await storageRef.getDownloadURL();

          const state = { ...examinationNavState };

          const paper = state.papers[state.paperNo];
          paper.loadingInProgress = false;
          paper.file = selected;
          paper.error = '';
          paper.answerSheetUrl = paperURL || '';
          paper.answerSheetName = selected.name;
          paper.fileUploadedProgress = 0;

          setExaminationNavState(state);

          Notification({
            isSuccess: true,
            message: 'Answer Sheet Uploaded!',
          });
        }
      );
    } else {
      // const state = {...examinationNavState};
      // const paper = state.papers[state.paperNo];
      // paper.file = null;
      // paper.error = 'Please select an PDF file';
      // paper.answerSheetUrl = '';
      // paper.answerSheetName = '';
      // setExaminationNavState(state);
    }
  };

  //---------Remove PDFs from Structured Marking scheme-------------
  const removeAnswerSheet = (url: string, paperId: string) => {
    const state = { ...examinationNavState };

    setRemovingLoading(true);

    storage
      .refFromURL(url)
      .delete()
      .then(() => {
        setRemovingLoading(false);
        const paper = state.papers[state.paperNo];
        paper.file = null;
        paper.error = '';
        paper.answerSheetName = '';
        paper.answerSheetUrl = '';
        paper.fileUploadedProgress = 0;
        Notification({
          isSuccess: true,
          message: 'Answer Sheet Removed.',
        });

        setExaminationNavState({ ...state });
      })
      .catch((err) => {
        setRemovingLoading(false);
        const paper = state.papers[state.paperNo];
        paper.file = null;
        paper.error = '';
        paper.answerSheetName = '';
        paper.answerSheetUrl = '';
        paper.fileUploadedProgress = 0;
      });
  };

  //end of logic

  const [progressWillNotBeSavedModel, setProgressWillNotBeSavedModel] = useState<{
    path: string;
    msg: string;
  }>({ path: '', msg: '' });

  const primaryNav = (
    <PrimaryNav
      restrictions={{
        value: true,
        onClick: (item: string) => {
          if (redo) {
            setProgressWillNotBeSavedModel({
              path: `${item}`,
              msg: 'As this is a retake, you exam progress will not be saved if you do not submit',
            });
          }
        },
      }}
    />
  );

  const appMobileNav = useMobileNav();

  const header =
    selectedPaper?.paperType === PaperTypes.TRUEFALSE ||
    selectedPaper?.paperType === PaperTypes.MCQ ? (
      <ExamHeaderNew
        title={examFromLocation?.examTitle}
        totalNoOfQuestions={selectedPaper.allQuestions.length}
        currentQuestion={getAnsweredAmount()}
        onBack={() => {
          if (redo) {
            setProgressWillNotBeSavedModel({
              path: `/my-subjects/subject/${params.subjectId}/Examinations`,
              msg: 'As this is a retake, you exam progress will not be saved if you do not submit',
            });
          } else {
            const state =
              examFromLocation.examType === ExamType.EXTRA_EXAM
                ? 'extra'
                : examFromLocation.examType === ExamType.LIVE_EXAM
                ? 'live'
                : 'practice';

            history.push({
              pathname: `/my-subjects/subject/${params?.subjectId}/Examinations`,
              state: state,
            });
          }
        }}
      />
    ) : (
      <div className="d-flex align-items-center">
        <Backarrow
          onClick={() => {
            if (redo) {
              setProgressWillNotBeSavedModel({
                path: `/my-subjects/subject/${params.subjectId}/Examinations`,
                msg: 'As this is a retake, you exam progress will not be saved if you do not submit',
              });
            } else {
              const state =
                examFromLocation.examType === ExamType.EXTRA_EXAM
                  ? 'extra'
                  : examFromLocation.examType === ExamType.LIVE_EXAM
                  ? 'live'
                  : 'practice';

              history.push({
                pathname: `/my-subjects/subject/${params.subjectId}/Examinations`,
                state: state,
              });
            }
          }}
        />
        <span className="text-white ml-3" style={{ fontSize: 25 }}>
          {examinationNavState.exam?.examTitle}
        </span>
        <HamburgerIcon
          className="d-md-none ml-auto"
          onClick={() => {
            appMobileNav.setOpenMobileNav(!appMobileNav.openMobileNav);
          }}
        />
      </div>
    );

  if (
    selectedPaper?.paperType === PaperTypes.MCQ &&
    selectedPaper?.allQuestions[selectedPaper.currentQuestionIdx].questionType === QuestionTypes.MCQ
  ) {
    mcqCurrentQuestions = selectedPaper?.allQuestions[
      selectedPaper.currentQuestionIdx
    ] as PaperQuestionDTO;
    body = selectedPaper ? (
      <div className="exam-body">
        <div className="exam-body__question-no">
          <h3>Question</h3>
          <h3>{selectedPaper.currentQuestionIdx + 1}</h3>
        </div>
        <div className="exam-body__question">{renderHTML(mcqCurrentQuestions?.question)}</div>
        <div className="exam-body__answers">
          {mcqCurrentQuestions?.answers.map((a, idx) => {
            return (
              <MCQAnswerCard
                answer={a}
                answerNo={idx}
                className="mb-2"
                mcqAnswerSelect={mcqAnswerSelect}
                key={idx}
              />
            );
          })}
        </div>
        <div className="exam-body__buttons">
          <button
            className={`${
              selectedPaper.currentQuestionIdx === 0 &&
              examinationNavState.paperNo === 0 &&
              'exam-body__pre-btn--diabled'
            }`}
            onClick={() => {
              if (selectedPaper.currentQuestionIdx === 0 && examinationNavState.paperNo !== 0) {
                moveToPreviousPaper();
              } else {
                moveToPreviousQuestion();
              }
            }}
          >
            <FaChevronLeft className="mb-1 mr-md-1 mr-2" />
            {examinationNavState.papers.length - 1 === 0
              ? 'Previous Question'
              : selectedPaper.currentQuestionIdx === 0
              ? 'Previous Paper'
              : 'Previous Question'}
          </button>
          <button
            onClick={() => {
              if (
                examinationNavState.papers.length - 1 === examinationNavState.paperNo &&
                selectedPaper.currentQuestionIdx === selectedPaper.totalNoOfQuestions - 1
              ) {
                submitExam();
              } else if (
                selectedPaper.currentQuestionIdx ===
                selectedPaper.totalNoOfQuestions - 1
              ) {
                moveToNextPaper();
              } else {
                moveToNextQuestion();
              }
            }}
            className={examinationNavState.loading ? 'exam-body__next-btn--diabled' : ''}
          >
            {examinationNavState.papers.length - 1 === examinationNavState.paperNo &&
            selectedPaper.currentQuestionIdx === selectedPaper.totalNoOfQuestions - 1 ? (
              'Submit Exam'
            ) : selectedPaper.currentQuestionIdx === selectedPaper.totalNoOfQuestions - 1 ? (
              'Next Paper'
            ) : (
              <div>
                Next Question
                <FaChevronRight className="mb-1 ml-md-1 ml-2" />
              </div>
            )}
            {selectedPaper.currentQuestionIdx === selectedPaper.totalNoOfQuestions - 1 && (
              <FaChevronRight className="mb-1 ml-md-1 ml-2" />
            )}
          </button>
        </div>
      </div>
    ) : (
      <></>
    );
  } else if (
    selectedPaper?.paperType === PaperTypes.TRUEFALSE &&
    selectedPaper?.allQuestions[selectedPaper.currentQuestionIdx].questionType ===
      QuestionTypes.TRUEFALSE
  ) {
    tfCurrentQuestions = selectedPaper?.allQuestions[
      selectedPaper.currentQuestionIdx
    ] as PaperQuestionDTO;

    body = selectedPaper ? (
      <div className="exam-body">
        <div className="exam-body__question-no">
          <h3>Question</h3>
          <h3>{selectedPaper.currentQuestionIdx + 1}</h3>
        </div>
        <div className="exam-body__question">{renderHTML(tfCurrentQuestions.question)}</div>
        <div className="exam-body__answers">
          {tfCurrentQuestions.answers &&
            tfCurrentQuestions.answers.map((a, idx) => {
              return (
                <TrueFalseAnswerCard
                  answer={a}
                  answerNo={idx}
                  className="mb-2"
                  tfAnswerSelect={tfAnswerSelect}
                  key={idx}
                />
              );
            })}
        </div>
        <div className="exam-body__buttons">
          <button
            className={`${
              selectedPaper.currentQuestionIdx === 0 &&
              examinationNavState.paperNo === 0 &&
              'exam-body__pre-btn--diabled'
            }`}
            onClick={() => {
              if (selectedPaper.currentQuestionIdx === 0 && examinationNavState.paperNo !== 0) {
                moveToPreviousPaper();
              } else {
                moveToPreviousQuestion();
              }
            }}
          >
            <FaChevronLeft className="mb-1 mr-md-1 mr-2" />
            {examinationNavState.papers.length - 1 === 0
              ? 'Previous Question'
              : selectedPaper.currentQuestionIdx === 0
              ? 'Previous Paper'
              : 'Previous Question'}
          </button>

          <button
            onClick={() => {
              if (
                examinationNavState.papers.length - 1 === examinationNavState.paperNo &&
                selectedPaper.currentQuestionIdx === selectedPaper.totalNoOfQuestions - 1
              ) {
                submitExam();
              } else if (
                selectedPaper.currentQuestionIdx ===
                selectedPaper.totalNoOfQuestions - 1
              ) {
                moveToNextPaper();
              } else {
                moveToNextQuestion();
              }
            }}
            className=""
          >
            {examinationNavState.papers.length - 1 === examinationNavState.paperNo &&
            selectedPaper.currentQuestionIdx === selectedPaper.totalNoOfQuestions - 1
              ? 'Submit Exam'
              : selectedPaper.currentQuestionIdx === selectedPaper.totalNoOfQuestions - 1
              ? 'Next Paper'
              : 'Next Question'}
            <FaChevronRight className="mb-1 ml-md-1 ml-2" />
          </button>
        </div>
      </div>
    ) : (
      <></>
    );
  } else if (
    selectedPaper?.paperType === PaperTypes.STRUCTURED ||
    selectedPaper?.paperType === PaperTypes.ESSAY
  ) {
    body = (
      <div className="stuctured-essay-body">
        <div className="text-white time-large stuctured-essay-body__timeblock">
          {examinationType === ExamType.EXTRA_EXAM ? (
            <TimeBlock timeArray={timeArray[1]} />
          ) : (
            <TimeBlock timeArray={timeArray[0]} />
          )}
        </div>
        <div style={{ color: '#73847F' }}>Upload Answers</div>
        <div className="pdf-uploader-comp-wrapper">
          <PDFUploaderCompExam
            style={{ marginTop: 36 }}
            stateValue={truncate(selectedPaper.answerSheetName || '', 40)}
            file={selectedPaper.file}
            progress={selectedPaper.fileUploadedProgress}
            error={selectedPaper.error}
            placeHolder="File Name"
            fileLoading={selectedPaper.loadingInProgress}
            onChange={changeAnswerSheetHandler}
            removeFromStorage={removeAnswerSheet}
            url={selectedPaper.answerSheetUrl}
            paperId={selectedPaper.paperId || ''}
            pageNo={examinationNavState.paperNo}
          />
          {removingLoading ? <LoadingIndicator /> : <></>}
        </div>

        <div className="d-flex justify-content-between">
          <button
            className={`btn-struct-essay ${
              examinationNavState.paperNo === 0 && 'btn-struct-essay--disabled'
            }`}
            onClick={() => {
              if (examinationNavState.paperNo !== 0) {
                moveToPreviousPaper();
              }
            }}
          >
            <FaChevronLeft className="mb-1 mr-md-1 mr-2" />
            Previous Paper
          </button>
          <button
            className={`btn-struct-essay `}
            onClick={() => {
              if (examinationNavState.papers.length - 1 === examinationNavState.paperNo) {
                submitExam();
              } else {
                moveToNextPaper();
              }
            }}
          >
            {examinationNavState.papers.length - 1 === examinationNavState.paperNo
              ? 'Submit Exam'
              : 'Next Paper'}
            <FaChevronRight className="mb-1 ml-md-1 ml-2" />
          </button>
        </div>
      </div>
    );
  }

  const rightPanel =
    selectedPaper &&
    (selectedPaper.paperType === PaperTypes.MCQ ||
      selectedPaper.paperType === PaperTypes.TRUEFALSE) ? (
      <NavigatorRightPanelContent
        timeArray={timeArray[0]}
        timeTakenArray={timeArray[1]}
        questions={selectedPaper?.allQuestions || 0}
        currentQuestionIdx={selectedPaper.currentQuestionIdx}
        moveToSpecificQuestion={moveToSpecificQuestion}
        redo={redo}
        examPapers={examinationNavState.papers}
        changePaper={changePaper}
        selectedIndex={examinationNavState.paperNo}
        paperType={selectedPaper.paperType}
        currentPaper={selectedPaper}
        examType={examinationType}
      />
    ) : selectedPaper &&
      (selectedPaper.paperType === PaperTypes.ESSAY ||
        selectedPaper.paperType === PaperTypes.STRUCTURED) ? (
      <StructuredEssayRightPanelContent
        examPapers={examinationNavState.papers}
        selectedIndex={examinationNavState.paperNo}
        changePaper={changePaper}
        paperName={selectedPaper.paperName}
        paperUrl={selectedPaper.paperUrl}
      />
    ) : (
      <></>
    );

  return !examinationNavState.examContinue ? (
    <ExamPapersInformation
      papers={examinationNavState.papers}
      onClick={startHandler}
      timeArray={
        examinationNavState.exam?.examType === ExamType.EXTRA_EXAM ? timeArray[1] : timeArray[0]
      }
      examinationFinishTime={examinationNavState.exam?.finishTime}
      examName={examinationNavState.exam?.examTitle || ''}
      examType={examinationNavState.exam?.examType}
      loading={time.loading}
    />
  ) : examinationNavState.exam ? (
    <Fragment>
      {restoreSessionLoader && <Overlay msg="Restoring session.." />}
      <Toaster />
      <LayoutNew
        primaryNav={primaryNav}
        secondaryNav={primaryNav}
        header={header}
        body={body}
        rightPanel={{ type: 'NAV_CONTAINER', component: rightPanel }}
        screen={SCREEN.ANSWER_SCREEN}
        isLoading={examinationNavState?.loading}
      />

      <AlertPopup
        message={alert.msg}
        header="Attention"
        isShow={!!alert.msg}
        onClose={() => {
          setAlert((ps) => ({ ...ps, msg: '', code: 0 }));

          if (alert.code === 409 || alert.code === 410) {
            history.push({
              pathname: `/my-subjects/subject/${params.subjectId}/Examinations`,
              state: tabState,
            });
          }
        }}
        type="NO_BUTTON"
      />
      <AlertPopup
        message={progressWillNotBeSavedModel.msg}
        header="Attention"
        isShow={!!progressWillNotBeSavedModel.msg}
        onClose={() => {
          setProgressWillNotBeSavedModel({ path: '', msg: '' });
        }}
        onOk={() => {
          // history.push({
          //   pathname: `/my-subjects/subject/${params.subjectId}/Examinations`,
          //   state: tabState,
          // });

          history.push({
            pathname: progressWillNotBeSavedModel.path,
          });
        }}
      />
    </Fragment>
  ) : (
    <></>
  );
};

export default React.memo(StudentExamAnswerScreen);
