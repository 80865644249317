import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useHistory } from 'react-router-dom';
import { useFirestore, useFunctions } from 'reactfire';
import AlertPopup from '../../../../components/AlertPopup';
import LoadingIndicator from '../../../../components/LoadingIndicator';
import { useLessons } from '../../../../context/LessonsContext';
import ContentTextBox from '../../components/ContentTextBox';
import LinksContent from '../../components/LinksContent';
import MaterialContentCopy from '../../components/MaterialContentCopy';
import VideoContent from '../../components/VideoContent';
import { useAppContent } from '../../context/ContentContext';
import { iniComps, initLink, Links, LocalContentState } from '../../interfaces/LocalContentState';
import RoutingPaths from '../../routes/RoutingPaths';
import AdminAndInstructorContentServices from '../../services/AdminAndInstructorContentServices';
import { CONTENT_MEDIA_TYPE } from '../../utils';
import ErrorBoundary from '../../utils/ErrorBoundary';
import './AddContentCreateScreen.scss';
import TextContext from './TextContext';
import Notification from './../../../../components/Notification';
import { getTheNextDroppableId } from '../../helpers';

interface Props {
  subjectId: string;
  lessonId: string;
  unitId?: string;
  contentId?: string;
}

const AddContentCreateScreen: FC<Props> = (props) => {
  const createContentRef = useFunctions().httpsCallable('createContent');

  const appContent = useAppContent();

  const lastElementIndex = appContent.contentData.length - 1;

  const lastElementIndexString = appContent.contentData.length - 1 + '';

  const [error, setError] = useState<string>('');
  const [submitError, setSubmitError] = useState<string>('');

  const history = useHistory();

  const isLessonContainsUnits = useLessons()?.lessons.find(
    (val) => val.id === props.lessonId
  )?.isLessonContainsUnits;

  function handleOnDragEnd(result: DropResult) {
    if (!result.destination) return;

    const items = Array.from(
      appContent.contentData.find((val) => val.droppableId === lastElementIndexString)
        ?.contentItems as any
    );

    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    appContent.setContentData(lastElementIndexString, items);
    appContent.setTinyMCEChangedTimeSnap();
  }

  const [loading, setLoading] = useState<boolean>(false);
  const store = useFirestore();

  useEffect(() => {
    setLoading(true);
    appContent.setLoading(false);

    store
      .collection('content')
      .where('subjectId', '==', props.subjectId || '')
      .where('lessonId', '==', props.lessonId || '')
      .where('unitId', '==', props.unitId || 'noUnit')
      .get()
      .then((res) => {
        if (res.empty) {
          setLoading(false);

          if (!(appContent.contentData.length >= 1)) {
            iniComps.forEach((v) => {
              if (v.mediaType === CONTENT_MEDIA_TYPE.LINKS) {
                const va = v as {
                  links: Links;
                  mediaType: CONTENT_MEDIA_TYPE;
                  contentItemDroppableId: string;
                  component: any;
                };

                va.links.linkGroups = [
                  { ref: { value: '', error: '' }, url: { value: '', error: '' } },
                ];
              }
            });
            const compsArray = getComponentSettedContentDataArray(iniComps);
            appContent.replaceOrAddContentDataByDroppableId('0', compsArray);
          }
        } else {
          const resData = { ...res.docs[0].data() } as {
            contentData: LocalContentState[];
            subjectId: string;
            lessonId: string;
            unitId: string | undefined;
          };

          if (resData.contentData.length === appContent.contentData.length) {
            iniComps.forEach((v) => {
              if (v.mediaType === CONTENT_MEDIA_TYPE.LINKS) {
                const va = v as {
                  links: Links;
                  mediaType: CONTENT_MEDIA_TYPE;
                  contentItemDroppableId: string;
                  component: any;
                };

                va.links.linkGroups = [
                  { ref: { value: '', error: '' }, url: { value: '', error: '' } },
                ];
              }
            });
            setLoading(false);
            setIndexHandler(iniComps);
          } else {
            setLoading(false);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subjectId, props.lessonId, props.unitId]);

  const setIndexHandler = (arrayInnput: any) => {
    const droppableId = getTheNextDroppableId(appContent.contentData);

    appContent.setInitData(droppableId, getComponentSettedContentDataArray(arrayInnput));
  };

  const getComponentSettedContentDataArray = (arrayInnput: any) => {
    const array: any = [];

    const droppableId = getTheNextDroppableId(appContent.contentData);

    arrayInnput?.forEach((val: any, idx: any) => {
      const contentItemDroppableId = idx + '';

      if (val.mediaType === CONTENT_MEDIA_TYPE.LINKS) {
        array.push({
          links: initLink,
          mediaType: CONTENT_MEDIA_TYPE.LINKS,
          contentItemDroppableId: contentItemDroppableId,
          component: (
            <LinksContent
              contentItemDroppableId={contentItemDroppableId}
              droppableId={droppableId}
            />
          ),
        });
      } else if (val.mediaType === CONTENT_MEDIA_TYPE.MATERIALS) {
        array.push({
          materialUrls: [],
          mediaType: CONTENT_MEDIA_TYPE.MATERIALS,
          contentItemDroppableId: contentItemDroppableId,
          component: (
            <MaterialContentCopy
              contentItemDroppableId={contentItemDroppableId}
              droppableId={droppableId}
            />
          ),
        });
      } else if (val.mediaType === CONTENT_MEDIA_TYPE.TEXT) {
        array.push({
          textContent: { value: '', error: '' },
          mediaType: CONTENT_MEDIA_TYPE.TEXT,
          contentItemDroppableId: contentItemDroppableId,
          component: (
            <TextContext
              contentItemDroppableId={contentItemDroppableId}
              droppableId={droppableId}
            />
          ),
        });
      } else if (val.mediaType === CONTENT_MEDIA_TYPE.VIDEO) {
        array.push({
          videoValue: { value: '', videoName: '', error: '' },
          mediaType: CONTENT_MEDIA_TYPE.VIDEO,
          contentItemDroppableId: contentItemDroppableId,
          component: (
            <VideoContent
              contentItemDroppableId={contentItemDroppableId}
              droppableId={droppableId}
            />
          ),
        });
      } else if (val.mediaType === CONTENT_MEDIA_TYPE.QUIZ) {
      }
    });

    return array;
  };

  const onFinishHander = (val: any) => {
    if (val.data.status === 400) {
      appContent.setLoading(false);
      return setSubmitError(val.data.data);
    }
    appContent.setLoading(false);
    Notification({
      isSuccess: true,
      message: `Content added successfully`,
    });
    history.push(new RoutingPaths(props.subjectId, props.lessonId, props.unitId).baseURL);
  };

  const onProgressHandler = () => {
    appContent.setLoading(true);
  };

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <Fragment>
      <div className="create-content">
        {loading ? <LoadingIndicator /> : <></>}
        <ContentTextBox
          style={{ width: '100%' }}
          className="fn-margin"
          stateName="contentTitle"
          stateValue={appContent.contentData[lastElementIndex]?.contentTitle.value}
          error={appContent.contentData[lastElementIndex]?.contentTitle.error}
          placeHolder="Content Title"
          compulsory={true}
          onChangeHandler={(e: any) => {
            appContent.changeContentTitleHandler(
              appContent.contentData[appContent.contentData.length - 1].droppableId,
              e.target.value
            );
          }}
          resetErrorHandler={() => {
            appContent.resetContentTitleErrorHandler(lastElementIndexString);
          }}
        />

        <ErrorBoundary subjectId={props.subjectId} prevPath={appContent.previousPathToPreview}>
          {!appContent.loading &&
          appContent.contentData[lastElementIndex]?.contentItems.length > 0 ? (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="publishTopicNames">
                {(provided) => (
                  <ul
                    className="characters"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}
                  >
                    {appContent.contentData[lastElementIndex]?.contentItems.map(
                      (listItem: any, index: any) => {
                        return (
                          <Draggable
                            key={listItem.contentItemDroppableId}
                            draggableId={listItem.contentItemDroppableId}
                            index={index}
                          >
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {listItem.component}
                              </li>
                            )}
                          </Draggable>
                        );
                      }
                    )}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <></>
          )}
        </ErrorBoundary>
        <div className="create-content__btns mt-4">
          <button
            className={`create-content__save-btn ${
              appContent.materialContentIsUploading || appContent.videoContentIsUploading
                ? 'create-content__save-btn--disabled'
                : ''
            }`}
            onClick={() => {
              //guard
              if (appContent.materialContentIsUploading || appContent.videoContentIsUploading) {
                return;
              }

              const contentDataClone = [...appContent.contentData];
              const lastItemOfContentData = contentDataClone[contentDataClone.length - 1];

              const submitData = appContent.validateData([lastItemOfContentData!]) as {
                cData: LocalContentState[];
                isDataValid: boolean;
                contentError: string;
              };

              const index = appContent.contentData
                .map((v) => v.droppableId)
                .indexOf(lastItemOfContentData?.droppableId!);

              const cDataToUpdate = [...appContent.contentData];

              cDataToUpdate[index] = submitData.cData[0];

              if (submitData.contentError) {
                return setError(submitData.contentError);
              }

              if (!submitData.isDataValid) {
                return (
                  appContent.setState &&
                  appContent.setState((ps) => {
                    return { ...ps, contentData: cDataToUpdate };
                  })
                );
              }

              for (let i = 0; i < appContent.contentData.length; i++) {
                for (let j = 0; j < appContent.contentData[i].contentItems.length; j++) {
                  appContent.contentData[i].contentItems.forEach((v) => {
                    v.component = null;
                  });
                }
              }

              const data = {
                content: {
                  contentData: appContent.contentData,
                  subjectId: props.subjectId,
                  lessonId: props.lessonId,
                  unitId: props.unitId || 'noUnit',
                },
                id: props.contentId,
              };

              new AdminAndInstructorContentServices(createContentRef).createContent(
                data,
                onProgressHandler,
                onFinishHander
              );
            }}
          >
            Save and Publish
          </button>
          {/* <button
          className="create-content__draft-btn create-content__draft-btn--disabled"
          onClick={() => {}}
        >
          Save Draft
        </button> */}
          <div
            className="create-content__cancel"
            onClick={() => {
              if (isLessonContainsUnits) {
                history.push(
                  `/my-subjects/subject/${props.subjectId}/lesson/${props.lessonId}/unit/${props.unitId}/Content`
                );
              } else {
                history.push(
                  `/my-subjects/subject/${props.subjectId}/lesson/${props.lessonId}/Content`
                );
              }
            }}
          >
            Cancel
          </div>
        </div>
      </div>

      <AlertPopup
        message={error}
        header="Attention"
        isShow={!!error}
        type="NO_BUTTON"
        onClose={() => {
          //Close the popup
          setError('');
        }}
      />
      <AlertPopup
        message={submitError}
        header="Attention"
        isShow={!!submitError}
        onOk={() => {
          setSubmitError('');
        }}
        onClose={() => {
          setSubmitError('');
        }}
      />
    </Fragment>
  );
};

export default AddContentCreateScreen;
