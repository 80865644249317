import { StatusOfPaper } from '../enums';
import { UserRole } from '../interfaces/UserRole';

const SUBJECT_SECONDARY_NAV_MAIN_ITEMS = {
  LESSONS: 'Lessons',
  MODAL_PAPERS: 'Examinations',
  ANNOUCEMENTS: 'Announcements',
  SETTINGS: 'Settings',
};

const TA_SUBJECT_SECONDARY_NAV_MAIN_ITEMS = {
  LESSONS: 'Lessons',
  MODAL_PAPERS: 'Examinations',
  ANNOUCEMENTS: 'Announcements',
};

const STUDENT_SUBJECT_SECONDARY_NAV_MAIN_ITEMS = {
  LESSONS: 'Lessons',
  MODAL_PAPERS: 'Examinations',
  ANNOUCEMENTS: 'Announcements',
};

const LESSON_SECONDARY_NAV_MAIN_ITEMS = {
  MY_UNITS: 'My Units',
  MODAL_PAPERS: 'Examinations',
  CONTENT: 'Content',
};

const UNIT_SECONDARY_NAV_MAIN_ITEMS = {
  CONTENT: 'Content',
  MODAL_PAPERS: 'Examinations',
};

const CONTENT_SECONDARY_NAV_MAIN_ITEMS = {
  CONTENT: 'Content',
};

export {
  SUBJECT_SECONDARY_NAV_MAIN_ITEMS,
  TA_SUBJECT_SECONDARY_NAV_MAIN_ITEMS,
  LESSON_SECONDARY_NAV_MAIN_ITEMS,
  UNIT_SECONDARY_NAV_MAIN_ITEMS,
  CONTENT_SECONDARY_NAV_MAIN_ITEMS,
  STUDENT_SUBJECT_SECONDARY_NAV_MAIN_ITEMS,
};

export const GRADE_TYPES = [
  {
    id: 'Percentage',
    displayValue: 'Percentage',
  },
  {
    id: 'Points',
    displayValue: 'Points',
  },
];

export const PRIMARY_NAV_ITEMS = {
  ACCOUNT: 'Account',
  SUBJECTS: 'Subjects',
  NOTICES: 'Notices',
  FORUM: 'Forum',
};

export const SCREEN = {
  SUBJECTS: 'SUBJECTS',
  SUBJECT: 'SUBJECT',
  LESSON: 'LESSON',
  ACCOUNT: 'ACCOUNT',
  RESOURCE: 'RESOURCE',
  SETTINGS: 'SETTINGS',
  DASHBOARD: 'DASHBOARD',
  SCORE_SCREEN: 'SCORE_SCREEN',
  ANSWER_SCREEN: 'ANSWER_SCREEN',
  EXAM_DETAILS_SCREEN: 'EXAM_DETAILS_SCREEN',
  EXAM_INFO_SCREEN: 'EXAM_INFO_SCREEN',
  STUDENT_DONE_PAPERS: 'STUDENT_DONE_PAPERS',
  NOTICES: 'NOTICES',
  CONTENT_PREVIEW: 'CONTENT_PREVIEW',
};

export const ACCOUNT_TAB = {
  PROFILE_INFO: 'Profile Information',
};

export const SUBJECT_TAB = {
  SUBJECT_HOME: 'Subject Home',
  LESSONS: 'Lessons',
  MODAL_PAPERS: 'Examinations',
  ANNOUNCEMENTS: 'Announcements',
  SETTINGS: 'Settings',
};

export const EXAM_TAB = {
  LIVE_EXAM: 'Live Exams',
  PRACTICE_EXAM: 'Practice Exams',
  EXTRA_EXAM: 'Extra Exams',
};

export const LESSON_TAB = {
  HOME: 'Home',
  MY_UNITS: 'My Units',
  HOMEWORK: 'Homework',
  PAPERS: 'Papers',
  GRADES: 'Grades',
  ANNOUNCEMENTS: 'Announcements',
  STUDENTS: 'Students',
  SETTINGS: 'Settings',
};

export const RESOURCE_TAB = {
  QUESTIONBANK: 'Question Bank',
  PAPERBANK: 'Paper Bank',
};

export const SETTINGS = {
  MANAGE_USERS: 'Manage Users',
  VALIDATE_INSTRUCTORS: 'Validate Instructors',
  VALIDATE_TEACHING_ASSISTANT: 'Validate Teaching Assistant',
  REPORTS: 'Reports',
  // ACCESS_CODES: 'Access Codes',
};

export const RESOURCE_TAB_SUB_QB = {
  MCQ_QUESTIONS: 'MCQ Questions',
  TRUE_FALSE_QUESTIONS: 'True/False Questions',
};

export const RESOURCE_TAB_SUB_PAPERS = {
  MCQ_PAPERS: 'MCQ Papers',
  TRUE_FALSE_PAPERS: 'True/False Papers',
  STRUCTURED_PAPERS: 'Structured Papers',
  ESSAY_PAPERS: 'Essay Papers',
};

export const PAPER_BREADCRUM_STEPS = {
  STEP_ONE: 'STEP_ONE',
  STEP_TWO: 'STEP_TWO',
  STEP_THREE: 'STEP_THREE',
};

interface MenuItem {
  text: string;
  active: boolean;
  subMenuItems: { text: string; active: boolean }[];
}

export const SUBJECT_MENU_ITEMS: MenuItem[] = [
  { text: SUBJECT_TAB.LESSONS, active: false, subMenuItems: [] },
  {
    text: SUBJECT_TAB.MODAL_PAPERS,
    active: false,
    subMenuItems: [
      { text: EXAM_TAB.LIVE_EXAM, active: false },
      { text: EXAM_TAB.PRACTICE_EXAM, active: false },
      { text: EXAM_TAB.EXTRA_EXAM, active: false },
    ],
  },
  { text: SUBJECT_TAB.ANNOUNCEMENTS, active: false, subMenuItems: [] },
];

// export const STUDENT_SUBJECT_MENU_ITEMS: MenuItem[] = [
//   { text: SUBJECT_TAB.SUBJECT_HOME, active: false, subMenuItems: [] },
//   { text: SUBJECT_TAB.LESSONS, active: false, subMenuItems: [] },
//   {
//     text: SUBJECT_TAB.MODAL_PAPERS,
//     active: false,
//     subMenuItems: [
//       { text: 'Live Exams', active: false },
//       { text: 'Practice Exams', active: false },
//       { text: 'Extra Exams', active: false },
//     ],
//   },
//   { text: SUBJECT_TAB.ANNOUNCEMENTS, active: false, subMenuItems: [] },
// ];

export const STUDENT_SUBJECT_MENU_ITEMS: MenuItem[] = [
  { text: SUBJECT_TAB.LESSONS, active: false, subMenuItems: [] },
  {
    text: SUBJECT_TAB.MODAL_PAPERS,
    active: false,
    subMenuItems: [
      { text: 'Live Exams', active: false },
      { text: 'Practice Exams', active: false },
      { text: 'Extra Exams', active: false },
    ],
  },
  { text: SUBJECT_TAB.ANNOUNCEMENTS, active: false, subMenuItems: [] },
];

export const SUBJECT_MENU_ITEMS_ADMIN: MenuItem[] = [
  { text: SUBJECT_TAB.LESSONS, active: false, subMenuItems: [] },
  {
    text: SUBJECT_TAB.MODAL_PAPERS,
    active: false,
    subMenuItems: [],
  },
  { text: SUBJECT_TAB.ANNOUNCEMENTS, active: false, subMenuItems: [] },
  { text: SUBJECT_TAB.SETTINGS, active: false, subMenuItems: [] },
];

export const SUBJECT_MENU_ITEMS_TA: MenuItem[] = [
  { text: SUBJECT_TAB.LESSONS, active: false, subMenuItems: [] },
  {
    text: SUBJECT_TAB.MODAL_PAPERS,
    active: false,
    subMenuItems: [],
  },
  { text: SUBJECT_TAB.ANNOUNCEMENTS, active: false, subMenuItems: [] },
];

export const LESSON_MENU_ITEMS = [
  { text: LESSON_TAB.HOME, active: false, subMenuItems: [] },
  { text: LESSON_TAB.MY_UNITS, active: false, subMenuItems: [] },
  { text: LESSON_TAB.HOMEWORK, active: false, subMenuItems: [] },
  { text: LESSON_TAB.PAPERS, active: false, subMenuItems: [] },
  { text: LESSON_TAB.GRADES, active: false, subMenuItems: [] },
  { text: LESSON_TAB.ANNOUNCEMENTS, active: false, subMenuItems: [] },
  { text: LESSON_TAB.STUDENTS, active: false, subMenuItems: [] },
  { text: LESSON_TAB.SETTINGS, active: false, subMenuItems: [] },
];

export const RESOURCE_MENU_ITEMS = [
  {
    text: RESOURCE_TAB.QUESTIONBANK,
    active: false,
    subMenuItems: [
      { text: 'MCQ Questions', active: false },
      { text: 'True/False Questions', active: false },
    ],
  },
  {
    text: RESOURCE_TAB.PAPERBANK,
    active: false,
    subMenuItems: [
      { text: 'MCQ Papers', active: false },
      { text: 'True/False Papers', active: false },
      { text: 'Structured Papers', active: false },
      { text: 'Essay Papers', active: false },
    ],
  },
];

export const SETTINGS_MENU_ITEMS = [
  {
    text: SETTINGS.MANAGE_USERS,
    active: false,
    subMenuItems: [],
  },
  {
    text: SETTINGS.VALIDATE_INSTRUCTORS,
    active: false,
    subMenuItems: [],
  },
  {
    text: SETTINGS.VALIDATE_TEACHING_ASSISTANT,
    active: false,
    subMenuItems: [],
  },
  {
    text: SETTINGS.REPORTS,
    active: false,
    subMenuItems: [],
  },
  // {
  //   text: SETTINGS.ACCESS_CODES,
  //   active: false,
  //   subMenuItems: [],
  // },
];

export const ACCOUNT_MENU_ITEM = [{ text: 'Profile Information', active: false, subMenuItems: [] }];

export const GRADES: { displayValue: string; id: string }[] = [
  { displayValue: 'Five', id: 'Five' },
  { displayValue: 'Six', id: 'Six' },
  { displayValue: 'Seven', id: 'Seven' },
  { displayValue: 'Eight', id: 'Eight' },
  { displayValue: 'Nine', id: 'Nine' },
  { displayValue: 'O/L', id: 'O/L' },
  { displayValue: 'A/L', id: 'A/L' },
];

export const GRADES_IN_COMMON_ACCESSCODE: { displayValue: string; id: string }[] = [
  { displayValue: 'All', id: 'All' },
  { displayValue: '5', id: 'Five' },
  { displayValue: '6', id: 'Six' },
  { displayValue: '7', id: 'Seven' },
  { displayValue: '8', id: 'Eight' },
  { displayValue: '9', id: 'Nine' },
  { displayValue: 'O/L', id: 'O/L' },
  { displayValue: 'A/L', id: 'A/L' },
];

export const GRADES_NUMERIC_LABELS = [
  { name: 'Five', label: '5' },
  { name: 'Six', label: '6' },
  { name: 'Seven', label: '7' },
  { name: 'Eight', label: '8' },
  { name: 'Nine', label: '9' },
  { name: 'O/L', label: 'O/L' },
  { name: 'A/L', label: 'A/L' },
];

export const YEARS: { displayValue: string; id: string }[] = [
  { displayValue: '2015', id: '2015' },
  { displayValue: '2016', id: '2016' },
  { displayValue: '2017', id: '2017' },
  { displayValue: '2018', id: '2018' },
  { displayValue: '2019', id: '2019' },
  { displayValue: '2020', id: '2020' },
];

export const DISCOUNT_TYPES: { displayValue: string; id: string }[] = [
  { displayValue: 'Percentage', id: 'PERCENTAGE' },
  { displayValue: 'Rupees', id: 'RUPEES' },
];

export const DISCOUNT_TYPES_TEMP: { displayValue: string; id: string }[] = [
  { displayValue: 'Percentage', id: 'PERCENTAGE' },
];

export const SUBJECTS: { displayValue: string; id: string }[] = [
  { displayValue: 'Subject1', id: 'Subject1' },
  { displayValue: 'Subject2', id: 'Subject2' },
  { displayValue: 'Subject3', id: 'Subject3' },
  { displayValue: 'Subject4', id: 'Subject4' },
];

export const ADMIN_PAPERSTATE_FILTERS: { displayValue: string; id: string }[] = [
  { displayValue: 'All', id: '' },
  { displayValue: 'Not Uploaded', id: StatusOfPaper.NOT_UPLOADED },
  { displayValue: 'Released ( Not Assigned to TAs )', id: StatusOfPaper.RELEASED },
  { displayValue: 'Released ( Assigned to TAs )', id: StatusOfPaper.RELEASED_ASSIGNED },
  { displayValue: 'Approved', id: StatusOfPaper.APPROVED },
  { displayValue: 'Rejected', id: StatusOfPaper.REJECTED },
  { displayValue: 'Not Assigned', id: StatusOfPaper.NOT_MARKED },
  { displayValue: 'Pending', id: StatusOfPaper.PENDING },
  // {displayValue: 'Not marked and not assigned', id: StatusOfPaper.NOT_MARKED_NOT_ASSIGNED},
  { displayValue: 'Assigned', id: StatusOfPaper.ASSIGNED },
  { displayValue: 'Marked ( Not Assgned to TAs)', id: StatusOfPaper.MARKED_NOT_ASSIGNED },
  // {displayValue: 'Marked by TA', id: StatusOfPaper.MARKED_BY_TA},
];

export const TA_PAPERSTATE_FILTERS: { displayValue: string; id: string }[] = [
  { displayValue: 'All', id: '' },
  // {displayValue: 'Not Uploaded', id: StatusOfPaper.NOT_UPLOADED},
  { displayValue: 'Approved', id: StatusOfPaper.APPROVED },
  { displayValue: 'Rejected', id: StatusOfPaper.REJECTED },
  { displayValue: 'Not Marked', id: StatusOfPaper.ASSIGNED },
  { displayValue: 'Pending', id: StatusOfPaper.PENDING },
];

export const ROLES_DROPDOWN_OPTIONS: { displayValue: string; id: string }[] = [
  { displayValue: 'Student', id: 'Student' },
  { displayValue: 'Instructor', id: 'Instructor' },
  { displayValue: 'Teaching Assistant', id: 'Teaching Assistant' },
];

export const QUESTION_SOURCES_OPTIONS: { displayValue: string; id: string }[] = [
  { displayValue: 'Past Paper', id: 'Past Paper' },
  { displayValue: 'URL', id: 'URL' },
  { displayValue: 'Book', id: 'Book' },
  { displayValue: 'Web', id: 'Web' },
  { displayValue: 'Original', id: 'Original' },
];

export const STREAMS_OPTIONS: { displayValue: string; id: string }[] = [
  { displayValue: 'Physical science', id: 'Physical science' },
  { displayValue: 'Biological Science', id: 'Biological Science' },
  { displayValue: 'Commerce', id: 'Commerce' },
  { displayValue: 'Art', id: 'Art' },
  { displayValue: 'Technology stream', id: 'Technology stream' },
];

/**
 * The Admin Role.
 */
export const AdminRole: UserRole = {
  isAdmin: true,
  isTeachingAssistant: false,
  isStudent: false,
  isInstructor: false,
};

/**
 * The Instructor Role.
 */
export const InstructorRole: UserRole = {
  isTeachingAssistant: false,
  isAdmin: false,
  isStudent: false,
  isInstructor: true,
};

/**
 * The Teaching Assistant Role.
 */
export const TeachingAssistantRole: UserRole = {
  isTeachingAssistant: true,
  isAdmin: false,
  isStudent: false,
  isInstructor: false,
};

/**
 * The Student Role.
 */
export const StudentRole: UserRole = {
  isStudent: true,
  isAdmin: false,
  isTeachingAssistant: false,
  isInstructor: false,
};

/**
 * The Stream.
 */
export enum Stream {
  PHYSICAL_SCIENCE = 'PHYSICAL SCIENCE',
  COMMERCE = 'COMMERCE',
  ART = 'ART',
  TECHNOLOGY_STREAM = 'TECHNOLOGY STREAM',
  BIOLOGICAL_SCIENCE = 'BIOLOGICAL SCIENCE',
  NONE = 'NONE',
}

/**
 * The Environment.
 */
export enum Environment {
  DEV = 'DEV',
  PROD = 'PROD',
}

/**
 * Grading Type
 */

export enum GradingType {
  PERCENTAGE = 'Percentage',
  POINTS = 'Points',
}

export enum EXAMPREVIEWSCREEN {
  DASH,
  GRADESORPAYMENT,
  SETTINGS,
  TA,
}

export enum EXAMPREVIEWSCREEN_GRADESORPAYMENT {
  MARKS,
  TAS,
}

export enum TA_ASSIGNED_PAPER_STATUS {
  YET_TO_MARK,
  MARKED,
  APPROVED,
}

export enum EXAM_CREATION_STEPS {
  ONE,
  TWO,
  THREE,
}

export enum EXAM_TYPE_TAB {
  LIVE,
  PRACTISE,
  EXTRA,
}

export const Role = {
  STUDENT: 'Student',
  ADMIN: 'Admin',
  TEACHINGASSITANT: 'Teaching Assistant',
  INSTRUCTOR: 'Instructor',
};

export const USER_TYPES: { displayValue: string; id: string }[] = [
  { displayValue: ' Teaching Assistant', id: 'TA' },
  { displayValue: 'Instructor', id: 'instructor' },
];

export const INSTRUCTOR_USER_TYPES: { displayValue: string; id: string }[] = [
  { displayValue: ' Teaching Assistant', id: 'TA' },
];
