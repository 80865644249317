import React, { FC, Fragment } from 'react';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { useAppContent } from '../context/ContentContext';
import './StudentContentHeader.scss';
import BreadCrumbUpdate from '../../../shared/BreadCrumbUpdate';

const StudentContentHeader: FC = (props) => {
  const appContent = useAppContent();

  const title = appContent.contentData.find(
    (val) => val.droppableId === appContent.mainDroppableId
  )?.contentTitle;

  return (
    <Fragment>
      <BreadCrumbUpdate className="pl-4" />
      <header className="student-content-header">
        <h5 className="student-content-header__title">{title?.value || ''}</h5>
        {appContent.loading ? <LoadingIndicator /> : <></>}
      </header>
    </Fragment>
  );
};

export default StudentContentHeader;
